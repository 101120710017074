<template>
  <div style="display: flex;">
    <el-button v-permission="permission.edit" :loading="crud.status.cu === 2" :disabled="disabledEdit" size="mini" type="text" @click.stop="crud.toEdit(data)">编辑</el-button>
    <p>|</p>
    <el-popover v-model="pop" v-permission="permission.del" placement="top" width="180" trigger="manual" @show="onPopoverShow" @hide="onPopoverHide">
      <p>{{ msg }}</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click.stop="doCancel">取消</el-button>
        <el-button :loading="crud.dataStatus[data.id].delete === 2" type="text" size="mini" @click.stop="crud.doDelete(data)">确定</el-button>
      </div>
      <el-button slot="reference" :disabled="disabledDle" type="text" size="mini" @click.stop="toDelete">删除</el-button>
    </el-popover>
    <p>|</p>
    <el-button type="text" size="mini" @click.stop="crud.toSet(data.id)" v-permission="permission.toSet">
      <slot name="btn3"></slot>
    </el-button>
  </div>
</template>
<script>
import CRUD, { crud } from '@crud/crud'
export default {
  mixins: [crud()],
  props: {
    data: {
      type: Object,
      required: true
    },
    permission: {
      type: Object,
      required: true
    },
    disabledEdit: {
      type: Boolean,
      default: false
    },
    disabledDle: {
      type: Boolean,
      default: false
    },
    msg: {
      type: String,
      default: '确定删除本条数据吗？'
    }
  },
  data() {
    return {
      pop: false
    }
  },
  methods: {
    doCancel() {
      this.pop = false
      this.crud.cancelDelete(this.data)
    },
    toDelete() {
      this.pop = true
    },
    [CRUD.HOOK.afterDelete](crud, data) {
      if (data === this.data) {
        this.pop = false
      }
    },
    onPopoverShow() {
      setTimeout(() => {
        document.addEventListener('click', this.handleDocumentClick)
      }, 0)
    },
    onPopoverHide() {
      document.removeEventListener('click', this.handleDocumentClick)
    },
    handleDocumentClick(event) {
      this.pop = false
    }
  }
}
</script>

<style lang="scss" scoped>
p{
  padding: 0 10px;
  color: #dfdfdf;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>></style>