import store from "@/store";

export const tablePropsInDetails = {
  // 开通记录
  6: [
    {
      type: "selection",
      selectable: (row) => !row.isClose,
    },
    {
      prop: "curriculumCode",
      label: "课程编号",
    },
    {
      prop: "curriculumName",
      label: "商品名称",
    },
    {
      prop: "openTime",
      label: "开通时间",
    },
    {
      prop: "curriculumCategory",
      label: "类型",
    },
    {
      prop: "status",
      label: "状态",
    },
    {
      prop: "default",
      label: "操作",
    },
  ],
  // 考试信息
  3: [
    {
      prop: "id",
      label: "考试名称",
    },
    {
      prop: "unid",
      label: "关联课程",
    },
    {
      prop: "name",
      label: "答题时长",
    },
    {
      prop: "name",
      label: "答题数量",
    },
    {
      prop: "name",
      label: "得分",
    },
    {
      prop: "name",
      label: "正确率",
    },
    {
      prop: "name",
      label: "错题数",
    },
    {
      prop: "name",
      label: "考试时间",
    },
  ],
  // 交易信息
  0: [
    {
      prop: "currImg",
      label: "课程封面",
    },
    {
      prop: "orderNo",
      label: "平台订单号",
    },
    {
      prop: "currName",
      label: "课程名称",
    },
    {
      prop: "dealName",
      label: "成交人",
    },
    {
      prop: "dealWechat",
      label: "成交微信",
    },
    {
      prop: "orderType",
      label: "订单类型",
    },
    {
      prop: "itemPrice",
      label: "订单总价",
    },
    {
      prop: "discountPrice",
      label: "优惠金额",
    },
    {
      prop: "needPrice",
      label: "应付金额",
    },
    {
      prop: "surePayPrice",
      label: "实付金额",
    },
    {
      prop: "orderStatus",
      label: "订单状态",
      formatter: (row) =>
        store.state.public.dics.orderStatus.find(
          (item) => item.value == row.orderStatus
        )?.name || row.orderStatus,
    },
    {
      prop: "shopId",
      label: "所属店铺",
    },
    {
      prop: "createDate",
      label: "创建时间",
    },
  ],
  // 学习信息
  1: [
    {
      prop: "currName",
      label: "课程名称",
    },
    {
      prop: "signTime",
      label: "报名时间",
    },
    {
      prop: "totalTime",
      label: "报名总时长",
    },
    {
      prop: "studyNumber",
      label: "学习次数",
    },
    {
      prop: "lastTime",
      label: "最后上课时间",
    },
    {
      prop: "maxTime",
      label: "最长上课时间(秒)",
    },
  ],
  // 用户反馈
  5: [
    {
      prop: "content",
      label: "用户反馈",
    },
    {
      prop: "fileInfoList",
      label: "反馈图片",
    },
    {
      prop: "phone",
      label: "联系方式",
      width: 100,
    },
    {
      prop: "createDate",
      label: "反馈时间",
    },
  ],
  // 信息采集
  2: [
    // {
    //   prop: "s",
    //   label: "采集名称",
    // },
    // {
    //   prop: "s",
    //   label: "姓名",
    // },
    // {
    //   prop: "s",
    //   label: "性别",
    // },
    // {
    //   prop: "s",
    //   label: "地点",
    // },
    // {
    //   prop: "s",
    //   label: "单选题标题",
    // },
    // {
    //   prop: "s",
    //   label: "多选题标题",
    // },
    // {
    //   prop: "s",
    //   label: "创建人",
    // },
    // {
    //   prop: "s",
    //   label: "创建时间",
    // },
  ],
  // 来源记录
  7: [
    {
      prop: "account",
      label: "平台ID",
    },
    {
      prop: "unionId",
      label: "UnionID",
    },
    {
      prop: "openId",
      label: "OpenID",
    },
    {
      prop: "avatarUrl",
      label: "头像",
    },
    {
      prop: "nickname",
      label: "用户名",
    },
    {
      prop: "phone",
      label: "手机号",
      width: 100,
    },
    {
      prop: "platFormTypeStr",
      label: "平台来源",
    },
  ],

  // 诊疗卡
  8: [
    {
      type: "selection",
      selectable: (row) => row.isIssue == 0, //未兑换的可选择
    },
    {
      prop: "id",
      label: "编号",
    },
    {
      prop: "certificateName",
      label: "诊疗卡名称",
    },
    {
      prop: "contactName",
      label: "关联课程",
    },
    {
      prop: "canUseTimes",
      label: "诊疗卡次数",
    },
    {
      prop: "expirationDate",
      label: "有效期截至",
    },
    {
      prop: "receiveTime",
      label: "获得时间",
    },
    {
      prop: "isIssue",
      label: "兑换状态",
    },
    {
      prop: "isExpiration",
      label: "过期状态",
    },
    {
      prop: "default",
      label: "操作",
    },
  ],
};

export const enumSex = {
  1: "男",
  2: "女",
};
export const enumAge = {
  1: "20岁以下",
  2: "21-30岁",
  3: "31-40岁",
  4: "41-50岁",
  5: "51-60岁",
  6: "60岁以上",
};
export const enumSalary = {
  1: "0-2000",
  2: "2000-4000",
  3: "4000-6000",
  4: "6000-8000",
  5: "8000-10000",
  6: "10000以上",
};
export const userSourceList = [
  // 渠道
  {
    label: "H5",
    value: 3,
  },
  {
    label: "公众号",
    value: 0,
  },
  {
    label: "小程序",
    value: 1,
  },
  {
    label: "APP",
    value: 2,
  },
  {
    label: "资讯小程序",
    value: 4,
  },
  {
    label: "中药小程序",
    value: 5,
  },
  {
    label: "舌诊小程序",
    value: 6,
  },
  {
    label: "体质测试小程序",
    value: 7,
  },
  {
    label: "未知",
    value: 99,
  },
];
