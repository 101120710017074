<template>
  <div class="app-container">
    <!--创建方式-->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :before-close="cancel"
      :visible.sync="isShowCreateWay"
      title="创建方式"
      width="710px"
    >
      <div class="way_box">
        <div
          class="item_box"
          v-for="(item, index) in createWayList"
          :key="index"
          :class="index == currentIndex ? 'active' : ''"
          @click="selectWay(index)"
        >
          <h3>{{ item.createWayName }}</h3>
          <p>{{ item.desc }}</p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="submitMethod">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "selectCreateWay",
  data() {
    return {
      currentIndex: 0,
      createWayList: [
        {
          createWayName: "自定义规则标签",
          desc: "可自定义规则设定不同的标签值，程序将根据符合规则标记对应标签值",
          createWay: "CUSTOM",
        },
        {
          createWayName: "计算结果标签",
          desc: "通过计算选择的规则所得出的结果作为标签值",
          createWay: "CALCULATE",
        },
        {
          createWayName: "动态统计标签",
          desc: "通过计算选择的规则所得出的结果作为标签值",
          createWay: "DYNAMICRULE",
        },
        {
          createWayName: "RFM标签",
          desc: "根据RFM模型进行用户价值评估分层，程序将根据RFM规则自动标记用户属于哪一类价值人群",
          createWay: "RFM",
        },
      ],
      isShowCreateWay: false,
    };
  },
  created() {},
  methods: {
    cancel() {
      this.isShowCreateWay = false;
      this.currentIndex = 0;
    },
    submitMethod() {
      if (this.currentIndex == 1) {
        this.$message({
          type: "warning",
          message: "此功能正在开发中",
        });
        return;
      }
      this.$emit("selectData", this.createWayList[this.currentIndex].createWay);
      this.isShowCreateWay = false;
    },
    selectWay(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.way_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .item_box {
    width: 324px;
    height: 110px;
    margin-bottom: 10px;
    padding-left: 16px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border: 1px solid rgba(228, 228, 228, 1);
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgb(215 215 215 / 35%);
    h3 {
      font-size: 18px;
      color: #333;
      font-weight: 400;
    }
    p {
      color: #c9c9c9;
    }
  }
  .active {
    border: 1px solid rgba(64, 169, 255, 1);
  }
}
</style>
