import request from "@/utils/request";

// 标签分组列表(旧)
export function getTagGroup(data) {
  return request({
    url: "cdp-portrait/pc/tagGroup",
    method: "get",
    params: data,
  });
}
// 标签分组列表（新）
export function newGetTagGroup(data) {
  return request({
    url: "cdp-portrait/pc/tagGroup/groupTree",
    method: "get",
    params: data,
  });
}
//新增或修改标签分组
export function saveOrUpdateGroup(data) {
  return request({
    url: "cdp-portrait/pc/tagGroup/saveOrUpdate",
    method: "post",
    data: data,
  });
}
//删除分组
export function deleteGroup(data) {
  return request({
    url: "cdp-portrait/pc/tagGroup/del",
    method: "get",
    params: data,
  });
}
//标签列表
export function getTagList(data) {
  return request({
    // url: "cdp-portrait/pc/tag",
    url: "cdp-portrait/pc/tag_info/list",
    method: "get",
    params: data,
  });
}
//新增标签
export function addTag(data) {
  return request({
    url: "cdp-portrait/pc/tag",
    method: "put",
    data: data,
  });
}
//修改标签
export function edtTag(data) {
  return request({
    url: "cdp-portrait/pc/tag",
    method: "post",
    data: data,
  });
}
//删除标签
export function deleteTag(data) {
  return request({
    url: "cdp-portrait/pc/tag",
    method: "delete",
    data: data,
  });
}
//标签属性详情/新增修改表单
export function getTagDetail(data) {
  return request({
    // url: "cdp-portrait/pc/tag/get",
    url: "cdp-portrait/pc/tag_info/detail",
    method: "get",
    params: data,
  });
}
//禁用/启用标签
export function switchTag(data) {
  return request({
    url: "cdp-portrait/pc/tag/switch",
    method: "post",
    params: data,
  });
}
//全部更新
export function makeSign(data) {
  return request({
    url: "cdp-portrait/pc/tag/allUpdateTask",
    method: "get",
    params: data,
  });
}

//标签详情
export function getDetails(data) {
  return request({
    // url: "cdp-portrait/pc/tag/details",
    url: "cdp-portrait/pc/tag_info/detail",
    method: "get",
    params: data,
  });
}

//侧边栏标签树
export function getGroupTree(data) {
  return request({
    url: "cdp-portrait/pc/tagGroup/groupTree",
    method: "get",
    params: data,
  });
}

//标签排序
export function changeSort(data) {
  return request({
    url: "cdp-portrait/pc/tagGroup/changeSort",
    method: "post",
    data: data,
  });
}

//获取web预配置
export function getPreWebConfig() {
  return request({
    url: "cdp-portrait/pc/tag/rulesConfig",
    method: "get",
  });
}

// 获取课程数据
export function getCurrRecordList(data) {
  return request({
    url: "curriculum/pc/scrm-curr/systCurrClassList",
    method: "get",
    params: data,
  });
}
