<template>
  <div class="tableSelect">
    <el-s-table
      :columns="columns"
      :data="tableData"
      border
      @selection-change="handleSelectionChange"
    ></el-s-table>
    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurriculumTableSelect",
  data() {
    return {
      columns: [
        { type: "selection" },
        { prop: "", label: "编号" },
        { prop: "", label: "小鹅通课程名称" },
        { prop: "", label: "内部课程名称" },
        { prop: "", label: "课程体系" },
        { prop: "", label: "课程级别" },
        { prop: "", label: "课程类型" },
      ],
      tableData: [],
      selectList: [], //选中的数据
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    getTableData() {},
    handleSizeChange(val) {
      this.pageSize = val;
      this.page = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleSelectionChange(val) {
      this.selectList = val;
      // this.$emit("selectData",val)
      // let arr = val.map((v) => {
      //   return v.id;
      // });
      // this.idList = arr.join(",");
    },
  },
};
</script>
