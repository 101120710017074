<!-- 计算、动态标签 -->
<template>
  <div class="main">
    <div class="tab_bar">基础信息</div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="formDataRules"
      label-width="80px"
    >
      <el-row class="base_info">
        <el-form-item label="标签名称" prop="tagName">
          <el-input
            v-model="ruleForm.tagName"
            placeholder="请输入标签名称,30字符以内"
            style="width: 200px"
            maxlength="30"
          />
        </el-form-item>
        <el-form-item label="标签分组" prop="tagGroupId">
          <el-cascader
            v-model="ruleForm.tagGroupId"
            :options="groupList"
            :props="{ emitPath: false, label: 'name', value: 'id' }"
            collapse-tags
            clearable
          ></el-cascader>
        </el-form-item>
      </el-row>

      <div class="tab_bar">使用规则</div>

      <el-form-item label="统计规则">
        <div class="tagContent">
          <div class="tagBox">
            <div class="aggregationsBox">
              <div
                class="divider"
                v-show="dynamicRule.dynamicConditions.length > 1"
              >
                <!-- <span @click="aggregationsConnectorChange">{{
                  dynamicRule.aggregations[0].connector == "AND" ? "且" : "或"
                }}</span> -->
                <span>且</span>
              </div>
              <div class="ruleContent">
                <div class="aggregationsMain">
                  <div class="leftBox">
                    <el-date-picker
                      v-model="dynamicRule.aggregations[0].inputs"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions"
                      style="margin-right: 5px"
                    >
                    </el-date-picker>
                    <span>统计 </span>
                    <el-select
                      style="width: 100px; margin-right: 5px"
                      v-model="selectAggregations1"
                      placeholder="请选择"
                      @change="aggregations1Change"
                    >
                      <el-option
                        v-for="item in aggregationsRuleConfig"
                        :key="item.id"
                        :label="item.name"
                        :value="item.key"
                      >
                      </el-option>
                    </el-select>
                    <!-- <el-select
                  style="width: 100px; margin-right: 5px"
                  v-model="dynamicRule.aggregations[0].keyMapping"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in operatorOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
                  </div>
                  <div class="rightBox">
                    <el-button type="text" @click="addFather"
                      >新增条件</el-button
                    >
                  </div>
                </div>
                <div class="contentRight">
                  <div
                    class="divider"
                    v-show="dynamicRule.dynamicConditions.length > 1"
                  >
                    <span @click="fatherConnectorChange">{{
                      dynamicRule.dynamicConditions[0].connector == "AND"
                        ? "且"
                        : "或"
                    }}</span>
                  </div>
                  <!-- 父级 -->
                  <div style="width: 100%">
                    <div
                      class="fatherBox"
                      v-for="(item, index) in dynamicRule.dynamicConditions"
                      :key="index"
                    >
                      <div class="conditionNumBox">条件{{ index + 1 }}</div>
                      <div style="width: 100%">
                        <div class="fatherContent">
                          <div>
                            <el-date-picker
                              v-model="item.firstRules[0].inputs"
                              type="daterange"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd"
                              :picker-options="pickerOptions"
                              style="margin-right: 5px"
                              @blur="firstRulesInputsChange(index)"
                            >
                            </el-date-picker>
                            <el-select
                              style="width: 100px; margin-right: 5px"
                              v-model="item.firstRules[1].operator"
                              placeholder="请选择"
                              @change="
                                firstRules1OperatorChange(
                                  item,
                                  item.firstRules[1].operator,
                                  index
                                )
                              "
                            >
                              <el-option
                                v-for="item in actionOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                            <el-select
                              v-model="firstRulesInputs[index]"
                              placeholder="请选择"
                              @change="
                                actionTypeChange(index, firstRulesInputs[index])
                              "
                              style="width: 210px"
                            >
                              <el-option
                                v-for="item in actionTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </div>
                          <div class="rightBox">
                            <el-button
                              type="text"
                              v-if="item.firstRules[1].operator != 'NOT_EQUAL'"
                              @click="addChild(item, index)"
                              >新增细分条件</el-button
                            >
                            <el-button
                              type="text"
                              @click="delRules('father', index)"
                              v-if="dynamicRule.dynamicConditions.length > 1"
                              >删除</el-button
                            >
                          </div>
                        </div>
                        <div
                          class="childBox"
                          v-if="
                            item.simpleTagRuleConditionDefinitions &&
                            item.simpleTagRuleConditionDefinitions.length
                          "
                        >
                          <div class="childHint">且满足</div>
                          <div
                            class="divider"
                            :class="{
                              divider_non:
                                item.simpleTagRuleConditionDefinitions &&
                                item.simpleTagRuleConditionDefinitions.length <=
                                  1,
                            }"
                          >
                            <span @click="childSimpleChange(item)">{{
                              item.simpleTagRuleConditionDefinitions[0]
                                .connector == "AND"
                                ? "且"
                                : "或"
                            }}</span>
                          </div>
                          <!-- 子级 -->
                          <!-- 子集的 i 为父级的 index 。子集的数据格式为childRules = { i:[] } -->
                          <div style="width: 100%">
                            <div
                              class="tagBox"
                              v-for="(
                                items, i
                              ) in item.simpleTagRuleConditionDefinitions"
                              :key="i"
                            >
                              <div class="childContentRight">
                                <tagManageVue
                                  :rulesConfig="rulesConfig[index]"
                                  :tagManageData="{
                                    data: items,
                                    childIndex: i,
                                    fatherIndex: index,
                                    type: 'child',
                                    curriculumCodeList: curriculumCodeList,
                                    goodsList: goodsList,
                                    curriculumProjectList: projectList,
                                    dictData: dictData,
                                  }"
                                  :father="false"
                                  :key="tagManageUpload"
                                  :changeDataFuns="changeData"
                                ></tagManageVue>
                                <div class="rightBox">
                                  <el-button
                                    type="text"
                                    @click="delRules('child', index, i)"
                                    >删除</el-button
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="fatherContent"
                          style="justify-content: flex-start"
                          v-if="item.firstRules[1].operator != 'NOT_EQUAL'"
                        >
                          <el-select
                            v-model="
                              item.aggregationTagRuleConditionDefinitions[0]
                                .dataKey
                            "
                            filterable
                            placeholder="请选择"
                            @change="aggDataKeyChange(index)"
                          >
                            <el-option
                              v-for="item in dataKeyOptions[index]"
                              :key="item.value"
                              :label="item.name"
                              :value="item.key"
                            >
                            </el-option>
                          </el-select>
                          <el-select
                            v-model="
                              item.aggregationTagRuleConditionDefinitions[0]
                                .operator
                            "
                            filterable
                            placeholder="请选择"
                            style="width: 100px"
                          >
                            <el-option
                              v-for="item in operatorOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                          <div>
                            <el-input
                              v-model="
                                item.aggregationTagRuleConditionDefinitions[0]
                                  .inputs[0]
                              "
                              style="width: 200px"
                              placeholder="请输入"
                            ></el-input>
                            <span
                              style="margin-right: 10px"
                              v-if="
                                item.aggregationTagRuleConditionDefinitions[0]
                                  .operator == 'BETWEEN'
                              "
                              >--</span
                            >
                            <el-input
                              v-if="
                                item.aggregationTagRuleConditionDefinitions[0]
                                  .operator == 'BETWEEN'
                              "
                              v-model="
                                item.aggregationTagRuleConditionDefinitions[0]
                                  .inputs[1]
                              "
                              style="width: 200px"
                              placeholder="请输入"
                            ></el-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="tagfooter" style="padding-right: 10px">
            <el-button type="text" @click="addFather">新增条件</el-button>
          </div> -->
        </div>
      </el-form-item>
      <el-form-item label="标签值" prop="valueLabels">
        <el-s-table
          :columns="columns"
          :data="ruleForm.valueLabels"
          border
          style="width: 901px"
        >
          <template v-slot:valueName="{ row }">
            <el-input
              v-model="row.valueName"
              placeholder="请输入标签值"
            ></el-input>
          </template>
          <template v-slot:intervalType="{ row, $index }">
            <el-select
              v-model="row.intervalType"
              placeholder="选择区间类型"
              @change="intervalTypeChange(row, $index)"
              @visible-change="visibleChange(row, $index, $event)"
            >
              <el-option
                v-for="item in intervalTypeList"
                :key="item.value"
                :disabled="item.disabled"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <template v-slot:interval="{ row }">
            <el-input
              v-model="row.left"
              placeholder="请输入数值"
              style="width: 100px"
              :disabled="row.intervalType == 'INF_TO_CLOSED_RIGHT'"
              @input="row.left = row.left.replace(valueReg, '')"
              @blur="$refs.ruleFormRef.validateField('valueLabels')"
            ></el-input>
            ，
            <el-input
              v-model="row.right"
              placeholder="请输入数值"
              style="width: 100px"
              :disabled="row.intervalType == 'CLOSED_RIGHT_TO_INF'"
              @input="row.right = row.right.replace(valueReg, '')"
              @blur="$refs.ruleFormRef.validateField('valueLabels')"
            ></el-input>
          </template>
          <template v-slot:default="{ row, $index }">
            <el-button type="text" @click="delTableData(row, $index)"
              >删除</el-button
            >
          </template>
        </el-s-table>
        <el-button type="text" @click="addTableData">新增区间</el-button>
      </el-form-item>
    </el-form>
    <div class="bottom_btn">
      <el-button @click="submit" type="primary">保存</el-button>
      <el-button @click="backRouter">取消</el-button>
    </div>
    <selectCreateWay ref="selectCreateWayRef" @selectData="selectData" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { newGetTagGroup } from "@/api/labelManagement/index";
import { getCurrRecordList } from "@/api/labelManagement/index.js";
import {
  addOrModifyCalculateTag,
  getRulesConfig,
  getFormDetail,
} from "@/api/labelManagement/newIndex.js";
import {
  getCurrRecord,
  getGoodList,
  curriculumProject,
  getMaplist,
} from "../utilsQuery.js";
import selectCreateWay from "./components/selectCreateWay.vue";
import BehaviorRule from "./components/behaviorRule.vue";
import tagManageVue from "@/components/TagManage/index.vue";

let _this = null;
export default {
  name: "labelAdd",
  components: {
    draggable,
    selectCreateWay,
    BehaviorRule,
    tagManageVue,
  },

  data() {
    return {
      //标签值数据
      ruleForm: {
        id: "",
        tagName: "", //标签名称
        tagGroupId: "", //标签分组
        tagType: "",
        // triggerMode: "",
        timingProperty: "",
        timingType: "1", //定时方式(1每天)
        tagBizType: "CALCULATE",
        valueLabels: [],
      },
      formDataRules: {
        tagName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
          { max: 30, message: "长度不超过30个字", trigger: "blur" },
        ],
        tagGroupId: [
          { required: true, message: "请选择标签分组", trigger: "change" },
        ],
        tagType: [
          { required: true, message: "请选择标签类型", trigger: "change" },
        ],
        valueLabels: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (value.length < 1) {
                return callback(new Error("请添加标签值"));
              }
              for (let i in value) {
                if (!value[i].valueName)
                  return callback(
                    new Error(`请输入第${Number(i) + 1}行标签值名称`)
                  );
                if (
                  ["", null, undefined].includes(value[i].left) &&
                  value[i].intervalType != "INF_TO_CLOSED_RIGHT"
                )
                  return callback(
                    new Error(`请输入第${Number(i) + 1}行左侧区间值`)
                  );
                if (
                  ["", null, undefined].includes(value[i].right) &&
                  value[i].intervalType != "CLOSED_RIGHT_TO_INF"
                )
                  return callback(
                    new Error(`请输入第${Number(i) + 1}行右侧区间值`)
                  );
                if (
                  !["INF_TO_CLOSED_RIGHT", "CLOSED_RIGHT_TO_INF"].includes(
                    value[i].intervalType
                  )
                ) {
                  if (Number(value[i].left) >= Number(value[i].right)) {
                    return callback(
                      new Error(
                        `第${Number(i) + 1}行左侧输入的值应小于右侧输入的值`
                      )
                    );
                  }
                }
              }
              callback();
            },
          },
        ],
      },
      groupList: [],
      dynamicConditionsIndex: 0,
      dynamicRule: {
        //dynamicRule自定义标签值集
        // id: "", //标签id
        // sort: "", //排序
        // tagId: "", //标签id
        // tagValueId: "", //标签值id
        // valueName: "", //值名称
        aggregations: [
          {
            connector: "AND", //连接符,可用值:NOT,AND,OR
            dataKey: "eventTime", //数据键
            dataType: "DATE_TIME", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
            inputs: [
              new Date(2021, 5, 1, 23, 59, 59).toISOString().split("T")[0],
              new Date().toISOString().split("T")[0],
            ], //填写参数
            keyMapping: "event_time", //键映射
            keyType: "SIMPLE", //键类型 1单属性 2聚合属性
            operator: "BETWEEN", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
          },
          {
            connector: "NOT",
            dataKey: "",
            dataType: "",
            inputs: [],
            keyMapping: "",
            keyType: "",
            operator: "",
          },
        ],
        dynamicConditions: [
          //条件集
          {
            connector: "OR", //连接符,可用值:NOT,AND,OR
            id: "",
            aggregationTagRuleConditionDefinitions: [
              //聚合规则入参
              {
                connector: "NOT", //连接符,可用值:NOT,AND,OR
                dataKey: "", //数据键
                dataType: "NUMERAL", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
                inputs: [], //填写参数
                keyMapping: "", //键映射
                keyType: "AGG", //键类型 1单属性 2聚合属性
                operator: "LESS_EQUAL", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
              },
            ],
            firstRules: [
              //头规则入参
              {
                connector: "NOT", //连接符,可用值:NOT,AND,OR
                dataKey: "eventTime", //数据键
                dataType: "DATE_TIME", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
                inputs: [
                  new Date(2021, 5, 1, 23, 59, 59).toISOString().split("T")[0],
                  new Date().toISOString().split("T")[0],
                ], //填写参数
                keyMapping: "event_time", //键映射
                keyType: "SIMPLE", //键类型 1单属性 2聚合属性
                operator: "BETWEEN", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
              },
              {
                connector: "NOT", //连接符,可用值:NOT,AND,OR
                dataKey: "eventKey", //数据键
                dataType: "STRING", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
                inputs: [], //填写参数
                keyMapping: "event_key", //键映射
                keyType: "SIMPLE", //键类型 1单属性 2聚合属性
                operator: "EQUAL", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
              },
            ],
            simpleTagRuleConditionDefinitions: [
              //单一规则入参
              // {
              //   connector: "",
              //   dataKey: "",
              //   dataType: "",
              //   inputs: "",
              //   keyMapping: "",
              //   keyType:  'AGG',
              //   operator: "",
              // },
            ],
            tagValueId: "", //标签值id
          },
        ],
        // configJson: { behaviors: undefined },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
              _this.pickerType[`${_this.dynamicConditionsIndex}`] = this.text;
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
              _this.pickerType[`${_this.dynamicConditionsIndex}`] = this.text;
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
              _this.pickerType[`${_this.dynamicConditionsIndex}`] = this.text;
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
              _this.pickerType[`${_this.dynamicConditionsIndex}`] = this.text;
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
              _this.pickerType[`${_this.dynamicConditionsIndex}`] = this.text;
            },
          },
          {
            text: "本月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * end.getDate());
              picker.$emit("pick", [start, end]);
              _this.pickerType[`${_this.dynamicConditionsIndex}`] = this.text;
            },
          },
          {
            text: "本年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              const currentDate = new Date();
              const currentYear = currentDate.getFullYear();
              const startDate = new Date(currentYear, 0, 1); // 今年年初的日期
              start.setTime(
                start.getTime() - (start.getTime() - startDate.getTime())
              );
              picker.$emit("pick", [start, end]);
              _this.pickerType[`${_this.dynamicConditionsIndex}`] = this.text;
            },
          },
          {
            text: "上线以来",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(new Date(2021, 5, 1, 0, 0, 0).getTime());
              picker.$emit("pick", [start, end]);
              _this.pickerType[`${_this.dynamicConditionsIndex}`] = this.text;
            },
          },
        ],
      },
      actionOptions: [
        {
          label: "做过",
          value: "EQUAL",
        },
        {
          label: "未做过",
          value: "NOT_EQUAL",
        },
      ],
      firstRulesInputs: {},
      actionTypeOptions: [
        {
          label: "支付订单",
          value: "order",
        },
        {
          label: "学习课程",
          value: "study_course",
        },
        {
          label: "领取优惠券",
          value: "coupon_receive",
        },
      ],
      rulesConfig: {},
      dataKeyOptions: {},
      operatorOptions: [
        { label: "等于", value: "EQUAL" },
        { label: "大于等于", value: "GREATER_EQUAL" },
        { label: "小于等于", value: "LESS_EQUAL" },
        { label: "区间", value: "BETWEEN" },
        { label: "大于", value: "GREATER" },
        { label: "小于", value: "LESS" },
      ],
      tagManageUpload: "",
      curriculumCodeList: [],
      goodsList: [], //商品列表
      projectList: [], //项目列表
      dictData: {}, //字典数据
      xiaoeCoupons: [], //小鹅通优惠券列表
      curriculumList: [], //小鹅通课程
      aggregationsRuleConfig: [],
      columns: [
        { prop: "valueName", label: "标签值名称", width: "300px" },
        { prop: "intervalType", label: "区间类型", width: "200px" },
        { prop: "interval", label: "区间值", width: "300px" },
        { prop: "default", label: "操作", width: "100px" },
      ],
      pickerType: {}, // pickerOption类型
      selectAggregations1: "",
      intervalTypeList: [
        { label: "无穷-右开", value: "INF_TO_OPEN_RIGHT", disabled: true },
        { label: "无穷-右闭", value: "INF_TO_CLOSED_RIGHT", disabled: true },
        {
          label: "左开-右闭",
          value: "OPEN_LEFT_TO_CLOSED_RIGHT",
          disabled: true,
        },
        {
          label: "左开-右开",
          value: "OPEN_LEFT_TO_OPEN_RIGHT",
          disabled: true,
        },
        {
          label: "左闭-右闭",
          value: "CLOSED_LEFT_TO_CLOSED_RIGHT",
          disabled: true,
        },
        {
          label: "左闭-右开",
          value: "CLOSED_LEFT_TO_OPEN_RIGHT",
          disabled: false,
        },
        { label: "左开-无穷", value: "OPEN_RIGHT_TO_INF", disabled: true },
        { label: "左闭-无穷", value: "CLOSED_RIGHT_TO_INF", disabled: false },
      ],
      valueReg: /[^\d]/g,
    };
  },
  created() {
    _this = this;
    this.getCurrRecord();
    // .then((res) => {
    //   this.curriculumCodeList = res;
    //   console.log("curriculumCodeList:", this.curriculumCodeList);
    // });
    curriculumProject().then((res) => {
      this.projectList = res;
    });
    getMaplist().then((res) => {
      this.dictData = res;
    });
    getGoodList().then((res) => {
      this.goodsList = res;
    });
    this.getTagGroup();
    this.getRulesConfig();
  },
  mounted() {
    if (this.$route.query.id) {
      this.getTagDetail();
    }
  },
  methods: {
    aggregations1Change() {
      const rulesConfigData = this.aggregationsRuleConfig.find((item) => {
        return item.key == this.selectAggregations1;
      });
      this.dynamicRule.aggregations[1] = {
        ...this.dynamicRule.aggregations[1],
        ...rulesConfigData,
      };
      this.dynamicRule.aggregations[1].dataKey = rulesConfigData.key;
      this.dynamicRule.aggregations[1].operator = rulesConfigData.operatorScope;
      if (rulesConfigData.key === "count_addWechat") {
        this.valueReg = /^(0+)|[^\d]+/g;
        if (Number(this.ruleForm.valueLabels[0].left) == 0) {
          this.ruleForm.valueLabels[0].left;
          this.$set(this.ruleForm.valueLabels[0], "left", "");
        }
      } else {
        this.valueReg = /[^\d]/g;
      }
      console.log(this.dynamicRule.aggregations[1]);
    },
    aggregationsConnectorChange() {
      this.dynamicRule.aggregations.forEach((item) => {
        item.connector = item.connector == "AND" ? "OR" : "AND";
      });
    },
    fatherConnectorChange() {
      this.dynamicRule.dynamicConditions.forEach((item) => {
        item.connector = item.connector == "AND" ? "OR" : "AND";
      });
    },
    firstRulesInputsChange(index) {
      this.dynamicConditionsIndex = index;
      this.$nextTick(() => {
        if (
          document.getElementsByClassName("end-date")[0] ||
          (!document.getElementsByClassName("end-date")[0] &&
            _this.pickerType[`${_this.dynamicConditionsIndex}`] != "上线以来")
        ) {
          _this.pickerType[`${_this.dynamicConditionsIndex}`] = null;
        }
      });
    },
    // 做过，未做过变更事件
    firstRules1OperatorChange(data, val, index) {
      this.dynamicConditionsIndex = index;
      if (val === "NOT_EQUAL") {
        delete data.aggregationTagRuleConditionDefinitions;
        delete data.simpleTagRuleConditionDefinitions;
      } else {
        this.$set(data, "aggregationTagRuleConditionDefinitions", [
          {
            connector: "NOT",
            dataKey: "",
            dataType: "NUMERAL",
            inputs: [],
            keyMapping: "",
            keyType: "AGG",
            operator: "LESS_EQUAL",
          },
        ]);
        this.$set(data, "simpleTagRuleConditionDefinitions", []);
      }
    },
    // 执行动作变更事件
    actionTypeChange(index, data, defaultData = null) {
      const activeType = {
        order: 0,
        study_course: 2,
        coupon_receive: 1,
      };
      this.rulesConfig[index] = this.rulesConfigAll?.filter((item) => {
        return (
          item.eventType == activeType[data] &&
          !["AGG", "STATS"].includes(item.keyType)
        );
      });
      this.dataKeyOptions[index] = this.dataKeyOptionsAll?.filter((item) => {
        return item.eventType == activeType[data];
      });
      this.dynamicRule.dynamicConditions[index].firstRules[1].inputs = [
        `${data}`,
      ];
      if (!defaultData) {
        delete this.dynamicRule.dynamicConditions[index]
          .simpleTagRuleConditionDefinitions;
        if (
          this.dynamicRule.dynamicConditions[index]
            .aggregationTagRuleConditionDefinitions
        ) {
          this.dynamicRule.dynamicConditions[
            index
          ].aggregationTagRuleConditionDefinitions[0].dataKey = "";
          this.dynamicRule.dynamicConditions[
            index
          ].aggregationTagRuleConditionDefinitions[0].inputs = [];
        }
      } else {
        this.dynamicRule.dynamicConditions[
          index
        ].aggregationTagRuleConditionDefinitions[0].dataKey =
          defaultData.dataKey;
        this.dynamicRule.dynamicConditions[
          index
        ].aggregationTagRuleConditionDefinitions[0].inputs = defaultData.inputs;
      }
    },
    // 新增子条件
    addChild(data, i) {
      this.fatherIndex = i;
      if (
        data.simpleTagRuleConditionDefinitions &&
        data.simpleTagRuleConditionDefinitions.length
      ) {
        data.simpleTagRuleConditionDefinitions.push({
          connector: data.simpleTagRuleConditionDefinitions[0].connector,
          dataKey: undefined,
          dataType: undefined,
          inputs: [],
          operator: undefined,
          keyType: "SIMPLE",
          keyMapping: undefined,
        });
      } else {
        // this.childRules[i] = [];
        this.$set(data, "simpleTagRuleConditionDefinitions", [
          {
            connector: "OR",
            dataKey: undefined,
            dataType: undefined,
            inputs: [],
            operator: undefined,
            keyType: "SIMPLE",
            keyMapping: undefined,
          },
        ]);
      }
    },
    // 删除条件
    delRules(type, fatherIndex, i = null) {
      if (type === "father") {
        if (this.dynamicRule.dynamicConditions[fatherIndex]) {
          this.dynamicRule.dynamicConditions.splice(fatherIndex, 1);
        }
        delete this.firstRulesInputs[`${fatherIndex}`];
      } else {
        this.dynamicRule.dynamicConditions[
          fatherIndex
        ].simpleTagRuleConditionDefinitions.splice(i, 1);
      }
      this.tagManageUpload = new Date().getTime();
    },
    // 更改内层且或状态
    childSimpleChange(data) {
      data.simpleTagRuleConditionDefinitions.forEach((item) => {
        item.connector == "AND"
          ? (item.connector = "OR")
          : (item.connector = "AND");
      });
    },
    // 获取组件值
    changeData(data) {
      console.log("changeData:", data.newVal);
      const { sourceData, newVal } = data;
      const { type, index, fatherIndex, childIndex } = sourceData;
      const {
        typeValue,
        config,
        value1,
        inputValue,
        logicValue,
        keyType,
        couponTypeVal,
      } = newVal;
      const { dataType, keyMapping } = config;
      const condition =
        type === "father"
          ? this.dynamicRule.dynamicConditions[index].firstRules[1]
          : this.dynamicRule.dynamicConditions[fatherIndex]
              .simpleTagRuleConditionDefinitions[childIndex];
      condition.dataKey = typeValue;
      condition.dataType = dataType;
      condition.keyMapping = keyMapping;
      condition.keyType = keyType;
      condition.operator = logicValue;
      if (type !== "father") {
        const keys = [
          "purchaseProductVal",
          "curriculumLevelVal",
          "curriculumProjectVal",
          "cascaderValue",
          "inputValue",
          "value1",
          "couponVal",
          "courseVal",
          "couponTypeVal",
        ];

        for (let key in keys) {
          if (
            newVal[keys[key]] === null ||
            newVal[keys[key]] === undefined ||
            newVal[keys[key]] === "" ||
            newVal[keys[key]].toString() === ""
          ) {
            condition.inputs = [];
          } else {
            console.log(keys[key], newVal[keys[key]]);
            if (keys[key] == "inputValue") {
              condition.inputs = [newVal[keys[key]]];
            } else {
              condition.inputs = [newVal[keys[key]].toString()];
            }
            console.log("changeData-condition-change:", condition);
            return;
          }
        }
        if (newVal.minMoneyValue != "") {
          condition.inputs[0] = newVal.minMoneyValue;
        }
        if (newVal.maxMoneyValue != "") {
          condition.inputs[1] = newVal.maxMoneyValue;
        }
      } else {
        condition.inputs = typeValue == "event_time" ? value1 : [inputValue];
      }
    },
    // AGGdataKey变更时
    aggDataKeyChange(val) {
      console.log("aggDataKeyChange:", this.dataKeyOptions[[`${val}`]], val);
      this.dataKeyOptions[[`${val}`]].forEach((item) => {
        if (
          item.key ==
          this.dynamicRule.dynamicConditions[val]
            .aggregationTagRuleConditionDefinitions[0].dataKey
        ) {
          this.dynamicRule.dynamicConditions[
            val
          ].aggregationTagRuleConditionDefinitions[0].dataType = item.dataType;
          this.dynamicRule.dynamicConditions[
            val
          ].aggregationTagRuleConditionDefinitions[0].keyMapping =
            item.keyMapping;
        }
      });
      console.log(
        this.dynamicRule.dynamicConditions[val]
          .aggregationTagRuleConditionDefinitions
      );
    },
    // 新增父级条件
    addFather() {
      if (this.dynamicRule.dynamicConditions.length < 2) {
        this.dynamicRule.dynamicConditions[0].connector = "OR";
      }

      this.dynamicRule.dynamicConditions.push({
        connector: this.dynamicRule.dynamicConditions[0].connector,
        id: "",
        aggregationTagRuleConditionDefinitions: [
          {
            connector: "NOT",
            dataKey: "",
            dataType: "NUMERAL",
            inputs: [],
            keyMapping: "",
            keyType: "AGG",
            operator: "LESS_EQUAL",
          },
        ],
        firstRules: [
          //头规则入参
          {
            connector: "NOT", //连接符,可用值:NOT,AND,OR
            dataKey: "eventTime", //数据键
            dataType: "DATE_TIME", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
            inputs: [], //填写参数
            keyMapping: "event_time", //键映射
            keyType: "SIMPLE", //键类型 1单属性 2聚合属性
            operator: "BETWEEN", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
          },
          {
            connector: "NOT", //连接符,可用值:NOT,AND,OR
            dataKey: "eventKey", //数据键
            dataType: "STRING", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
            inputs: [], //填写参数
            keyMapping: "event_key", //键映射
            keyType: "SIMPLE", //键类型 1单属性 2聚合属性
            operator: "EQUAL", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
          },
        ],
        simpleTagRuleConditionDefinitions: [
          // {
          //   connector: "",
          //   dataKey: "",
          //   dataType: "",
          //   inputs: "",
          //   keyMapping: "",
          //   keyType:  'AGG',
          //   operator: "",
          // },
        ],
        tagValueId: "",
      });
      this.actionTypeChange(
        this.dynamicRule.dynamicConditions.length - 1,
        this.firstRulesInputs[
          `${this.dynamicRule.dynamicConditions.length - 1}`
        ]
      );
      this.firstRulesInputs[
        `
        ${this.dynamicRule.dynamicConditions.length - 1}`
      ];
    },
    submit() {
      let validVal = this.validateFun();
      if (!validVal) return;
      this.ruleForm.dynamicRule = this.dynamicRule;
      this.ruleForm.dynamicRule.dynamicConditions.forEach((item, i) => {
        if (this.pickerType[`${i}`]) {
          item.firstRules[0].inputs[1] = "";
        }
      });
      this.$refs["ruleFormRef"].validate((valid) => {
        if (valid) {
          addOrModifyCalculateTag(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.$router.back(-1);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 自定义标签数据验证
    validateFun() {
      if (
        this.dynamicRule.aggregations[0].inputs == [] ||
        this.dynamicRule.aggregations[0].inputs.length == 0
      ) {
        this.$message.error("请填写第一行时间");
        return false;
      }
      if (
        this.dynamicRule.aggregations[1].operator == "" ||
        this.dynamicRule.aggregations[1].operator == null
      ) {
        this.$message.error("请填写第一行统计规则");
        return false;
      }
      for (let customConditions of this.dynamicRule.dynamicConditions) {
        if (customConditions.aggregationTagRuleConditionDefinitions) {
          for (let items of customConditions.aggregationTagRuleConditionDefinitions) {
            for (let key in items) {
              if (
                (items[key] == null ||
                  items[key] == undefined ||
                  items[key].toString() == "") &&
                key != "keyMapping"
              ) {
                this.$message.error("请完整填写标签数据");
                return false;
              }
            }
          }
        }
        for (let items of customConditions.firstRules) {
          for (let key in items) {
            if (
              items[key] == null ||
              items[key] == undefined ||
              items[key].toString() == ""
            ) {
              this.$message.error("请完整填写标签数据");
              return false;
            }
          }
        }
        if (
          customConditions.simpleTagRuleConditionDefinitions &&
          customConditions.simpleTagRuleConditionDefinitions.length
        ) {
          for (let items of customConditions.simpleTagRuleConditionDefinitions) {
            for (let key in items) {
              if (
                items[key] == null ||
                items[key] == undefined ||
                items[key].toString() == ""
              ) {
                this.$message.error("请完整填写二级标签数据");
                return false;
              }
            }
          }
        }
      }
      return true;
    },
    backRouter() {
      this.$router.back(-1);
    },
    selectData(data) {
      this.ruleForm.tagBizType = data;
      // this.$set(this.ruleForm, "triggerMode", data);
    },
    // 获取规则配置
    getRulesConfig() {
      getRulesConfig()
        .then((res) => {
          if (res.code == 200) {
            this.rulesConfigAll = res.data;
            // if (this.ruleForm.tagBizType == "CALCULATE") {
            //   this.actionTypeOptions = res.data;
            // }
            this.rulesConfig[`0`] = res.data.filter((item) => {
              return item.keyType != "AGG";
            });
            this.dataKeyOptionsAll = res.data.filter((item) => {
              return item.keyType == "AGG";
            });
            this.aggregationsRuleConfig = res.data.filter((item) => {
              return item.tagBizType == "CALCULATE";
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    //获取标签分组
    getTagGroup() {
      let params = {
        pageNumber: 1,
        pageSize: 100,
      };
      newGetTagGroup(params).then((res) => {
        if (res.code == 200) {
          this.groupList = res.data;
          this.checkChildren(this.groupList);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    checkChildren(data) {
      data.forEach((item) => {
        if (item.children.length === 0) {
          item.children = null;
        } else {
          this.checkChildren(item.children);
        }
      });
    },
    getCurrRecord() {
      //获取课程数据
      getCurrRecordList({
        pageNumber: 1,
        pageSize: 1000,
      })
        .then((res) => {
          if (res.code == 200) {
            this.curriculumCodeList = res.data;
            // this.curriculumCodeList.forEach((item) => {
            //   if (item.children && item.children.length > 0) {
            //     item.children.forEach((child) => {
            //       child.children = null;
            //     });
            //   }
            // });
          }
        })
        .catch((err) => {});
    },
    addTableData() {
      if (this.ruleForm.valueLabels.length < 1) {
        this.ruleForm.valueLabels.push({
          intervalType: "",
          left: "",
          right: "",
          valueName: "",
        });
        return;
      }
      const valueLabels = this.ruleForm.valueLabels;
      const endLength = valueLabels.length - 1;
      let endData = null;
      let start = false;
      let end = false;
      for (let i in valueLabels) {
        endData =
          valueLabels[i].intervalType == "CLOSED_RIGHT_TO_INF"
            ? valueLabels[i]
            : null;
        // 无穷-右闭
        // if (valueLabels[i].intervalType === "INF_TO_CLOSED_RIGHT") {
        //   start = true;
        // }
        // 左开-无穷
        if (valueLabels[i].intervalType === "CLOSED_RIGHT_TO_INF") {
          end = true;
        }
      }
      if (!start && end) {
        valueLabels.splice(endLength, 1, {
          intervalType: "",
          left: "",
          right: "",
          valueName: "",
        });
        valueLabels.push(endData);
        return;
      }
      // if (start && !end) {
      //   valueLabels.push({
      //     intervalType: "",
      //     left: "",
      //     right: "",
      //     valueName: "",
      //   });
      //   return;
      // }
      // if (!start && end) {
      //   valueLabels.unshift({
      //     intervalType: "",
      //     left: "",
      //     right: "",
      //     valueName: "",
      //   });
      //   return;
      // }
      valueLabels.push({
        intervalType: "",
        left: "",
        right: "",
        valueName: "",
      });
    },
    delTableData(data, index) {
      // this.intervalTypeList.forEach((item) => {
      //   if (item.value == data.intervalType) {
      //     item.disabled = false;
      //   }
      // });
      this.ruleForm.valueLabels.splice(index, 1);
    },
    getTagDetail() {
      // 获取详情
      getFormDetail(this.$route.query.id).then((res) => {
        if (res.code == 200) {
          this.ruleForm = JSON.parse(JSON.stringify(res.data));
          this.selectAggregations1 =
            res.data.dynamicRule.aggregations[1].dataKey;
          this.dynamicRule = res.data.dynamicRule;
          if (this.dynamicRule.aggregations[1].dataKey === "count_addWechat") {
            this.valueReg = /^(0+)|[^\d]+/g;
            if (Number(this.ruleForm.valueLabels[0].left) == 0) {
              this.ruleForm.valueLabels[0].left;
              this.$set(this.ruleForm.valueLabels[0], "left", "");
            }
          } else {
            this.valueReg = /[^\d]/g;
          }
          this.dynamicRule.dynamicConditions.forEach((items, y) => {
            this.$set(this.firstRulesInputs, `${y}`, []);
            this.$set(this.dataKeyOptions, `${y}`, []);
            if (items.firstRules[0].inputs[1] === "") {
              this.$set(
                items.firstRules[0].inputs,
                1,
                new Date().toISOString().split("T")[0]
              );
              this.pickerType[`${y}`] = "上线以来";
            }
          });
          this.dynamicRule.dynamicConditions.forEach((items, towIndex) => {
            this.$set(
              this.firstRulesInputs,
              `${towIndex}`,
              items.firstRules[1].inputs.toString()
            );
            this.actionTypeChange(
              towIndex,
              this.firstRulesInputs[`${towIndex}`],
              items.aggregationTagRuleConditionDefinitions
                ? items.aggregationTagRuleConditionDefinitions[0]
                : null
            );
          });
          // const hasInfToClosed = this.ruleForm.valueLabels.some(
          //   (label) => label.intervalType === "INF_TO_CLOSED_RIGHT"
          // );
          const hasOpenToInf = this.ruleForm.valueLabels.some(
            (label) => label.intervalType === "CLOSED_RIGHT_TO_INF"
          );

          this.intervalTypeList.forEach((item) => {
            // if (hasInfToClosed && item.value === "INF_TO_CLOSED_RIGHT") {
            //   item.disabled = true;
            // } else
            if (hasOpenToInf && item.value === "CLOSED_RIGHT_TO_INF") {
              item.disabled = true;
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    visibleChange(row, index, e) {
      if (e) {
        for (let i in this.intervalTypeList) {
          if (
            this.ruleForm.valueLabels.length > 1 &&
            index == 0 &&
            !(this.intervalTypeList[i].value === "CLOSED_LEFT_TO_OPEN_RIGHT")
          ) {
            // this.intervalTypeList[i].disabled = true;
            this.$set(this.intervalTypeList[i], "disabled", true);
          }
          // if (this.ruleForm.valueLabels.length > 2) {
          if (
            index > 0 &&
            index < this.ruleForm.valueLabels.length - 1 &&
            !(this.intervalTypeList[i].value === "CLOSED_LEFT_TO_OPEN_RIGHT")
          ) {
            this.$set(this.intervalTypeList[i], "disabled", true);
          }
          for (let y in this.ruleForm.valueLabels) {
            if (
              this.ruleForm.valueLabels[y].intervalType ==
                "CLOSED_RIGHT_TO_INF" &&
              this.intervalTypeList[i].value === "CLOSED_RIGHT_TO_INF"
            ) {
              this.$set(this.intervalTypeList[i], "disabled", true);
            }
          }
          if (
            this.ruleForm.valueLabels.length > 1 &&
            index == this.ruleForm.valueLabels.length - 1 &&
            !(this.intervalTypeList[i].value === "CLOSED_RIGHT_TO_INF")
          ) {
            this.$set(this.intervalTypeList[i], "disabled", true);
          }
          // }
        }
      } else {
        this.intervalTypeList = [
          { label: "无穷-右开", value: "INF_TO_OPEN_RIGHT", disabled: true },
          { label: "无穷-右闭", value: "INF_TO_CLOSED_RIGHT", disabled: true },
          {
            label: "左开-右闭",
            value: "OPEN_LEFT_TO_CLOSED_RIGHT",
            disabled: true,
          },
          {
            label: "左开-右开",
            value: "OPEN_LEFT_TO_OPEN_RIGHT",
            disabled: true,
          },
          {
            label: "左闭-右闭",
            value: "CLOSED_LEFT_TO_CLOSED_RIGHT",
            disabled: true,
          },
          {
            label: "左闭-右开",
            value: "CLOSED_LEFT_TO_OPEN_RIGHT",
            disabled: false,
          },
          { label: "左开-无穷", value: "OPEN_RIGHT_TO_INF", disabled: true },
          { label: "左闭-无穷", value: "CLOSED_RIGHT_TO_INF", disabled: false },
        ];
        const length = this.ruleForm.valueLabels.length;
        // const startData = JSON.parse(
        //   JSON.stringify(this.ruleForm.valueLabels[0])
        // );
        const endData = JSON.parse(
          JSON.stringify(this.ruleForm.valueLabels[length - 1])
        );
        // if (
        //   this.ruleForm.valueLabels.length > 1 &&
        //   index > 0 &&
        //   row.intervalType == "INF_TO_CLOSED_RIGHT"
        // ) {
        //   this.$set(this.ruleForm.valueLabels, 0, row);
        //   this.$set(this.ruleForm.valueLabels, index, startData);
        // }
        if (
          this.ruleForm.valueLabels.length > 2 &&
          index > 0 &&
          row.intervalType == "CLOSED_RIGHT_TO_INF"
        ) {
          this.$set(this.ruleForm.valueLabels, length - 1, row);
          this.$set(this.ruleForm.valueLabels, index, endData);
        }
      }
    },
    intervalTypeChange(row, index) {
      if (row.intervalType == "INF_TO_CLOSED_RIGHT") row.left = null;
      if (row.intervalType == "CLOSED_RIGHT_TO_INF") row.right = null;
      // 已经存在无穷-左闭
      // const hasInfToClosed = this.ruleForm.valueLabels.some(
      //   (label) => label.intervalType === "INF_TO_CLOSED_RIGHT"
      // );
      // 已经存在右闭-无穷
      const hasOpenToInf = this.ruleForm.valueLabels.some(
        (label) => label.intervalType === "CLOSED_RIGHT_TO_INF"
      );

      this.intervalTypeList.forEach((item) => {
        // if (hasInfToClosed && item.value === "INF_TO_CLOSED_RIGHT") {
        //   item.disabled = true;
        // } else
        if (hasOpenToInf && item.value === "CLOSED_RIGHT_TO_INF") {
          item.disabled = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  margin-bottom: 20px;
  padding: 20px 30px 0;
  color: #333;

  .tab_bar {
    position: relative;
    font-size: 18px;
    color: #333333;
    margin-bottom: 20px;
    padding-left: 10px;
  }

  .tab_bar::before {
    content: "";
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 3px;
    border-left: 3px solid #007979;
  }

  .base_info {
    padding-left: 10px;
    margin-bottom: 0px;

    p {
      margin: 0;
      // margin-left: 68px;
      color: #c9c9c9;
      line-height: 20px;
    }

    .label_box {
      display: flex;

      .el-input {
        width: 250px;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      ::v-deep .el-input__inner {
        cursor: pointer;
      }
    }
  }

  .tagContent {
    position: relative;
    display: flex;
    padding: 10px;
    border: 1px solid #e7eaec;

    .divider {
      position: relative;
      left: 0;
      top: 0;
      bottom: 0;
      margin-right: 8px;
      margin-bottom: 10px;

      span {
        position: relative;
        display: block;
        top: 50%;
        left: 0;
        right: 0;
        width: auto;
        min-width: 25px;
        height: 25px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: #1890ff;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        cursor: pointer;
        user-select: none;
      }
    }

    .divider::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      overflow: hidden;
      background: #1890ff;
      box-sizing: border-box;
    }

    .divider_non::before {
      content: " ";
      background: #ffffff;
    }

    .divider_non {
      span {
        background: #ffffff;
      }
    }

    .tagBox {
      // display: flex;
      width: 100%;
      .aggregationsBox {
        .ruleContent {
          width: 100%;
        }
        display: flex;
        justify-content: space-between;
        width: 100%;
        .aggregationsMain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
        .contentRight {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .fatherBox {
            display: flex;
            width: 100%;

            .conditionNumBox {
              padding: 5px;
              min-width: 50px;
              color: #c9c9c9;
            }

            .fatherContent {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .el-select,
              .el-cascader,
              .el-input {
                margin-right: 10px;
              }
            }
          }

          .childBox {
            display: flex;
            width: 100%;

            .childHint {
              width: 50px;
              color: #1890ff;
            }

            .childContentRight {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 40px;
            }
          }

          .rightBox {
            display: flex;
            justify-content: flex-end;
            padding: 10px;
            width: 160px;
          }
        }
      }
    }

    .tagfooter {
      position: absolute;
      bottom: 0;
      right: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .bottom_btn {
    // position: fixed;
    // bottom: 0px;
    // right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    // width: calc(100% - 205px);
    background: #ffffff;
    border-top: 1px solid #e7eaec;
  }

  .inputFocus {
    outline: none;
    border: 1px solid #1890ff;
    border-radius: 5px;

    ::v-deep.el-input__inner {
      border: none !important;
    }
  }

  .last-check {
    li[aria-haspopup="true"] {
      .el-checkbox {
        display: none;
      }
    }
  }
}

::v-deep .el-table thead.is-group th {
  background: none;
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type {
  border-bottom: none;
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 120px;
  /*这里需要自己调整，根据td的宽度和高度*/
  top: 0;
  left: 0;
  background-color: #bbb;
  opacity: 0.3;
  display: block;
  transform: rotate(-58deg);
  /*这里需要自己调整，根据线的位置*/
  transform-origin: top;
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 120px;
  /*这里需要自己调整，根据td的宽度和高度*/
  bottom: 0;
  right: 0;
  background-color: #bbb;
  opacity: 0.3;
  display: block;
  transform: rotate(-59.9deg);
  /*这里需要自己调整，根据线的位置*/
  transform-origin: bottom;
}
</style>
