var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择课程",
        visible: _vm.isshowCourse,
        width: "60%",
        top: "8vh",
        "before-close": _vm.closedCourseDialong,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isshowCourse = $event
        },
      },
    },
    [
      _c("div", { staticClass: "dialogMain" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "el-form",
              { staticClass: "demo-form-inline", attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.courseParams[_vm.selectVal],
                          callback: function ($$v) {
                            _vm.$set(_vm.courseParams, _vm.selectVal, $$v)
                          },
                          expression: "courseParams[selectVal]",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "150px" },
                            attrs: { slot: "prepend", placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                _vm.courseParams.resourceId = ""
                                _vm.courseParams.title = ""
                              },
                            },
                            slot: "prepend",
                            model: {
                              value: _vm.selectVal,
                              callback: function ($$v) {
                                _vm.selectVal = $$v
                              },
                              expression: "selectVal",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: "小鹅通课程Id",
                                value: "resourceId",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label: "小鹅通课程名称",
                                value: "title",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        _vm.courseParams.page = 1
                        _vm.getCurriculumList()
                      },
                    },
                  },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-s-table", {
              ref: "courseRefs",
              attrs: {
                columns: _vm.columnsCourseSel,
                data: _vm.courseList,
                "header-row-class-name": !_vm.multiple ? "noneCheck" : "",
                "row-key": "xiaoeId",
                height: "500px",
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pagebox" },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.courseParams.page,
                    "page-sizes": [10, 20, 30, 40],
                    "page-size": _vm.courseParams.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.totalCourse,
                    background: "",
                  },
                  on: {
                    "size-change": _vm.handleSizeChangeCourse,
                    "current-change": _vm.handleCurrentChangeCourse,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            [
              _c("span", [_vm._v("已选择")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.selectData = []
                      _vm.courseTags = []
                      _vm.$refs.courseRefs.clearSelection()
                    },
                  },
                },
                [_vm._v("全部取消")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tagMain" },
            _vm._l(_vm.courseTags, function (item) {
              return _c(
                "el-tag",
                {
                  key: item.xiaoeId,
                  attrs: { closable: "" },
                  on: {
                    close: function ($event) {
                      return _vm.tagClose(
                        _vm.courseTags,
                        item.xiaoeId,
                        "xiaoeId"
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(item.title))]
              )
            }),
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closedCourseDialong } }, [
            _vm._v("取 消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitCourse } },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }