var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tableSelect" },
    [
      _c("el-s-table", {
        attrs: { columns: _vm.columns, data: _vm.tableData, border: "" },
        on: { "selection-change": _vm.handleSelectionChange },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagebox" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }