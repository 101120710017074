<template>
  <div class="main" id="main">
    <iframe :src="urlStr" frameborder="0" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
import { getAccessTicket } from "@/api/biView/index.js";
export default {
  components: {},
  data() {
    return {
      urlStr: "",
    };
  },

  created() {
    getAccessTicket({
      menuId: this.$route.meta.id,
    }).then((res) => {
      if (res.code === 200) {
        this.urlStr = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  },

  mounted() {},

  methods: {
    getUrl() {},
  },
};
</script>
<style lang="scss" scoped></style>
