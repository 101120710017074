// 开通课程
<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="620px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="dialog-box"
      @opened="handleOpen"
      @close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="用户ID">
          {{ huid }}
        </el-form-item>
        <el-form-item label="课程编号" prop="courseList">
          <el-input
            type="textarea"
            v-model="form.courseList"
            :rows="4"
            placeholder="课程编号以逗号（,或，）分隔，形如：C004833,C004834"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { importBatchCourse } from "@/api/curriculum/curriculum.js";
export default {
  name: "OpenCourse",
  components: {},
  props: {
    title: {
      type: String,
      default: "开通课程",
    },
  },
  data() {
    return {
      visible: false,
      isInit: false, // 第一次打开 用于请求初始化数据
      huid: undefined,
      form: {
        courseList: undefined,
      },
      rules: {
        courseList: [
          {
            required: true,
            message: "请输入课程编号",
            trigger: "blur",
          },
          {
            pattern: "^(C[0-9]+[,，])*?C[0-9]+$",
            message: `课程编号以逗号（,或，）分隔，形如：C004833,C004834`,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    show(user) {
      this.visible = true;
      this.huid = user.huid;
    },
    handleOpen() {
      if (!this.isInit) {
        this.isInit = true;
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
    },
    async handleConfirm() {
      this.$refs.form.validate(async (valid, obj) => {
        if (valid) {
          const formData = {
            huid: this.huid,
            courseList: this.form.courseList.split(/[,，]/),
          };
          console.log("待提交表单", formData);
          const { code, data, message } = await importBatchCourse(formData);
          if (code == 200) {
            const failList = [];
            const successList = [];
            for (let key in data) {
              if (data[key] == "success") {
                successList.push(key.replace(/^.+?\:/, ""));
              } else {
                failList.push(key.replace(/^.+?\:/, ""));
              }
            }
            if (!successList.length) {
              this.$message.error(`课程开通失败：${failList.join("，")}`);
              return;
            }
            if (failList.length) {
              this.$message.error(`部分课程开通失败：${failList.join("，")}`);
              return;
            }
            this.visible = false;
            this.$message.success(message);
            return;
          }
          this.$message.error(message || code);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dialog-box {
  .el-dialog__body {
    max-height: 550px;
    overflow-y: auto;
  }
}
</style>
