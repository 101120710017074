var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审批进度",
            visible: _vm.visible,
            width: "1000px",
            "close-on-click-modal": false,
            "close-on-escap-click": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.curProgress,
                "finish-status": "success",
                "process-status": "wait",
              },
            },
            _vm._l(_vm.progress, function (item, index) {
              return _c("el-step", {
                key: item.id,
                attrs: {
                  status: _vm._f("filterStatus")(item.result, index),
                  icon:
                    item.result == "已拒绝" || _vm.curProgress - 1 >= index
                      ? ""
                      : "el-icon-circle-check",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "step" }, [
                            _c("span", [_vm._v(_vm._s(item.approvalName))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "large" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("filterResult")(item.result, index)
                                )
                              ),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "description",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "step" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  item.roleName ? item.roleName.join(",") : ""
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(item.createTime))]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("知道了")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }