<template>
  <div class="rfm">
    <div class="rfm-head">{{ rfmData.title }}</div>
    <div class="rfm-content">
      <div class="rfm-value-type">
        <span>取值方式：</span>
        <el-radio-group v-model="judgeValueType">
          <el-radio :label="0">原值</el-radio>
          <el-radio :label="1">按区间赋值</el-radio>
        </el-radio-group>
      </div>
      <el-table border :data="interval" v-if="judgeValueType == 1">
        <el-table-column type="index" label="序号" />
        <el-table-column label="区间">
          <template slot-scope="scope">
            <span v-if="!scope.$index || scope.$index == interval.length - 1">{{
              rfmData.title.substring(0, 1)
            }}</span>
            <el-input
              v-else
              v-model="scope.row.rangeStart"
              placeholder="默认等于上一级天数"
              style="width: 150px"
              debounce="500"
              :disabled="true"
              @blur="rangeStartInput(scope.row, scope.$index)"
            ></el-input>
            <span>{{
              !scope.$index
                ? "<"
                : scope.$index == interval.length - 1
                ? "≥"
                : " ≤ " + rfmData.title.substring(0, 1) + " < "
            }}</span>
            <el-input
              type="text"
              v-model="scope.row.rangeEnd"
              placeholder="输入框"
              style="width: 150px"
              :disabled="
                (scope.$index != 0 &&
                  !interval[Number(scope.$index) - 1].rangeEnd) ||
                interval.length - 1 == Number(scope.$index)
              "
              @blur="inputValFun(scope.row, Number(scope.$index))"
            ></el-input>
            <span>{{ rfmData.units }}，计</span>
            <el-input
              type="text"
              v-model="scope.row.rangeValue"
              placeholder="输入框"
              style="width: 150px"
              debounce="500"
              @input="
                scope.row.rangeValue = scope.row.rangeValue
                  .toString()
                  .replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
              "
            ></el-input>
            <span>分</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="addRange(scope.$index)"
              :disabled="!scope.row.rangeEnd"
              v-if="scope.$index == 0"
              >新增区间</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deleteRange(Number(scope.$index))"
              v-if="scope.$index != 0 && scope.$index < interval.length - 1"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="rfm-value-type">
        <span>计算规则：</span>
        <div>
          <el-radio-group v-model="resultType" @change="customComparison = ''">
            <el-radio :label="0">平均值</el-radio>
            <el-radio :label="1">自定义</el-radio>
          </el-radio-group>
          <div class="rules">
            <span v-if="resultType == '0'"
              >{{
                `当 ${rfmData.title.substring(0, 1)}  ${
                  rfmData.title.substring(0, 1) == "R" ? "≤" : "≥"
                } 平均值时为`
              }}
              <span class="a1ffad">高</span> 价值客户</span
            >
            <span v-else
              >{{
                `当 ${rfmData.title.substring(0, 1)}  ${
                  rfmData.title.substring(0, 1) == "R" ? "≤" : "≥"
                }`
              }}<el-input
                type="text"
                v-model="customComparison"
                debounce="500"
                @input="customComparisonInput"
                style="display: inline-block; margin: 0 5px; width: 100px"
              />时为 <span class="a1ffad">高</span> 价值客户</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rfm",
  props: {
    rfmData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      inputs: "",
      changeData: {},
      judgeValueType: "0", //取值方式
      resultType: "0", //計算規則
      customComparison: "", //自定義比較值
      interval: [
        {
          rangeStart: "",
          rangeEnd: "",
          rangeValue: "",
        },
        {
          rangeStart: "",
          rangeEnd: "",
          rangeValue: "",
        },
      ], //区间条件 R<5,1|R<30,2|R>50,3 传 5,1 30,2 50,3
    };
  },
  created() {
    this.judgeValueType = this.rfmData.judgeValueType;
    this.resultType = this.rfmData.resultType;
    this.customComparison = this.rfmData.customComparison;
    const intervals = this.rfmData.interval.map((item) => item.split(","));
    intervals.forEach((item, index) => {
      if (item.length > 2) {
        this.$set(this.interval, index, {
          rangeStart: item[0],
          rangeEnd: item[1],
          rangeValue: item[2],
        });
      } else {
        this.$set(this.interval, index, {
          rangeStart: "",
          rangeEnd: item[0],
          rangeValue: item[1],
        });
      }
    });
    // this.interval = this.rfmData.interval.map(item => item.split(','));
  },
  watch: {
    interval: {
      handler: function (newVal, oldVal) {
        this.$emit("intervalFun", {
          ...this.rfmData,
          interval: newVal,
          judgeValueType: this.judgeValueType,
          resultType: this.resultType,
          customComparison: this.customComparison,
        });
      },
      deep: true,
    },
    // interval: function (newVal, oldVal) {
    //   this.$emit("intervalFun", {
    //     ...this.rfmData,
    //     interval: this.interval,
    //     judgeValueType: this.judgeValueType,
    //     resultType: this.resultType,
    //     customComparison: this.customComparison,
    //   });
    // },
    judgeValueType: function (newVal, oldVal) {
      this.$emit("intervalFun", {
        ...this.rfmData,
        interval: this.interval,
        judgeValueType: newVal,
        resultType: this.resultType,
        customComparison: this.customComparison,
      });
    },
    resultType: function (newVal, oldVal) {
      this.$emit("intervalFun", {
        ...this.rfmData,
        interval: this.interval,
        judgeValueType: this.judgeValueType,
        resultType: newVal,
        customComparison: this.customComparison,
      });
    },
    customComparison: function (newVal, oldVal) {
      this.$emit("intervalFun", {
        ...this.rfmData,
        interval: this.interval,
        judgeValueType: this.judgeValueType,
        resultType: this.resultType,
        customComparison: newVal,
      });
    },
  },
  methods: {
    // 添加区间
    addRange(index) {
      // 在指定索引处添加区间
      let length = this.interval.length;
      this.interval.splice(length - 1, 0, {
        rangeStart:
          this.interval[length - 2] && this.interval[length - 2].rangeEnd != ""
            ? Number(this.interval[length - 2].rangeEnd)
            : "",
        rangeEnd: this.interval[length - 1].rangeEnd
          ? Number(this.interval[length - 1].rangeEnd) + 1
          : "",
        rangeValue: "",
      });
      this.$set(this.interval, this.interval.length - 1, {
        rangeStart:
          this.interval[length - 2] && this.interval[length - 2].rangeEnd != ""
            ? Number(this.interval[length - 2].rangeEnd)
            : "",
        rangeEnd:
          this.interval[length - 1].rangeEnd != ""
            ? Number(this.interval[length - 1].rangeEnd)
            : "",
        rangeValue: "",
      });
    },
    // 删除区间
    deleteRange(index) {
      // 在指定索引处删除区间
      this.interval.splice(index, 1);
      const length = this.interval.length - 1;
      // 如果删除的区间不是第一个且前一个区间有结束值，则将当前区间的起始值设为前一个区间的结束值
      if (this.interval.length > 2 && this.interval[index - 1].rangeEnd) {
        this.$set(
          this.interval[index],
          "rangeStart",
          this.interval[index - 1].rangeEnd
        );
      }
      // 如果删除的是最后一个区间，则将当前区间的结束值设为前一个区间的结束值
      if (length == index) {
        this.$set(
          this.interval[length],
          "rangeEnd",
          this.interval[length - 1].rangeEnd
        );
      }
    },
    // 输入值处理
    inputValFun(val, index) {
      if (!/^\d+$/.test(val.rangeEnd)) {
        val.rangeEnd = "";
        return;
      }
      this.ruleInput(val, index);
    },

    // 区间起始值处理
    rangeStartInput(val, index) {
      if (!/^\d+$/.test(val.rangeStart)) {
        val.rangeStart = "";
        return;
      }
    },
    // 自定义比较输入处理
    customComparisonInput() {
      if (!/^\d+$/.test(this.customComparison)) {
        this.customComparison = "";
        return;
      }
    },

    // 规则输入处理
    ruleInput(val, index) {
      const length = this.interval.length - 1;
      // 如果结束值小于等于起始值
      if (val.rangeEnd <= val.rangeStart) {
        val.rangeEnd = "";
        this.$message.error(`请输入大于${val.rangeStart}的值`);
        return;
      }
      if (index + 1 < length) {
        for (let i = index + 1; i < length; i++) {
          this.$set(
            this.interval[i],
            "rangeStart",
            this.interval[i - 1].rangeEnd
          );
          this.$set(
            this.interval[i],
            "rangeEnd",
            Number(this.interval[i].rangeStart) + 1
          );
        }
      }
      // 将当前区间的结束值设为前一个区间的结束值
      this.$set(
        this.interval[length],
        "rangeEnd",
        this.interval[length - 1].rangeEnd
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.rfm {
  margin-bottom: 10px;
  border: 1px solid #e7eaec;
  .rfm-head {
    padding: 10px;
    height: 40px;
    font-size: 16px;
    background: #eee;
  }
  .rfm-content {
    padding: 10px;
    .rfm-value-type {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 10px 0;
      .rules {
        margin-top: 10px;
        .a1ffad {
          color: #02a718;
        }
      }
    }
  }
}
</style>
