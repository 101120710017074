<template>
  <div class="BehaviorRule">
    <el-container>
      <el-aside width="200px">
        <el-tabs v-model="behaviorType" @tab-click="behaviorTypeClick">
          <el-tab-pane label="用户行为" name="first"></el-tab-pane>
          <el-tab-pane label="学习行为" name="second"></el-tab-pane>
        </el-tabs>
        <div class="behavior">
          <el-checkbox-group v-model="checkBehavior" @change="behaviorChange">
            <el-checkbox
              :label="item.id"
              :disabled="editState"
              v-for="(item, index) in behaviorList[0].events"
              :key="index"
              >{{ item.eventName }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-aside>
      <el-container>
        <el-main>
          <div class="condition">
            <el-form
              ref="ruleFormRef"
              label-width="100px"
              label-position="left"
            >
              <el-form-item label="生效规则">
                <el-radio-group v-model="takeEffect" @input="takeEffectInput">
                  <el-radio
                    v-model="rules.fillId"
                    :label="rules.conditions[0].id"
                    :disabled="editState"
                    v-for="(rules, i) in behaviorData[0].events[0].rules"
                    :key="i"
                    >{{ rules.ruleName }}</el-radio
                  >
                </el-radio-group>
                <p style="margin: 0; padding: 0; color: #dcdada">
                  选择满足以下任一条件则只要满足其中条件则会标记标签，选择满足全部条件则需要满足以下所有条件
                </p>
              </el-form-item>
              <el-form-item
                v-for="(item, index) in showCheckBehavior"
                :key="index"
                :label="item.eventName"
              >
                <el-checkbox
                  v-if="item.rules[0].ruleType == 1"
                  v-model="rules.fillId"
                  :true-label="rules.conditions[0].id"
                  :disabled="editState"
                  v-for="(rules, i) in item.rules"
                  :key="i"
                  @change="
                    item.state = !item.state;
                    rules.conditions[0].sign = !rules.conditions[0].sign;
                    $emit('nowCheckBehavior', showCheckBehavior);
                  "
                  >{{ rules.ruleName }}</el-checkbox
                >

                <div
                  v-else
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                  "
                >
                  <span>{{ item.rules[0].ruleName }}</span>
                  <el-select
                    v-model="item.rules[0].fillId"
                    placeholder="请选择"
                    style="margin: 0 10px"
                    :disabled="editState"
                    @change="
                      item.startInput = '';
                      item.endInput = '';
                      item.state = false;
                    "
                  >
                    <el-option
                      v-for="(rules, i) in item.rules[0].conditions"
                      :key="i"
                      :label="rules.conditionName"
                      :value="rules.id"
                    >
                    </el-option>
                  </el-select>
                  <div
                    class="inputBox"
                    v-if="
                      filterFill(
                        item.rules[0].fillId,
                        item.rules[0].conditions
                      )[0]
                    "
                  >
                    <el-input
                      v-model="item.startInput"
                      placeholder="请输入"
                      style="width: 100px"
                      :disabled="editState"
                      @input="
                        item.startInput = item.startInput.replace(/[^\d]/g, '');
                        filterFill(
                          item.rules[0].fillId,
                          item.rules[0].conditions
                        )[0].fillValue = startInputChange(
                          filterFill(
                            item.rules[0].fillId,
                            item.rules[0].conditions
                          )[0].fillValue,
                          item.startInput
                        );
                        item.state =
                          item.startInput &&
                          filterFill(
                            item.rules[0].fillId,
                            item.rules[0].conditions
                          )[0].ruleType != 4
                            ? true
                            : false;
                        $emit('nowCheckBehavior', showCheckBehavior);
                      "
                    ></el-input>
                    <span
                      v-show="
                        filterFill(
                          item.rules[0].fillId,
                          item.rules[0].conditions
                        )[0].ruleType != 4
                      "
                      >{{
                        filterFill(
                          item.rules[0].fillId,
                          item.rules[0].conditions
                        )[0].supplementaryStatement
                      }}</span
                    >
                    <hr
                      style="display: inline-block; width: 20px"
                      v-show="
                        filterFill(
                          item.rules[0].fillId,
                          item.rules[0].conditions
                        )[0].ruleType == 4
                      "
                    />
                    <el-input
                      v-model="item.endInput"
                      placeholder="请输入"
                      style="width: 100px"
                      :disabled="editState"
                      v-show="
                        filterFill(
                          item.rules[0].fillId,
                          item.rules[0].conditions
                        )[0].ruleType == 4
                      "
                      @input="
                        item.endInput = item.endInput.replace(/[^\d]/g, '');
                        filterFill(
                          item.rules[0].fillId,
                          item.rules[0].conditions
                        )[0].fillValue = endInputChange(
                          filterFill(
                            item.rules[0].fillId,
                            item.rules[0].conditions
                          )[0].fillValue,
                          item.endInput
                        );
                        item.state =
                          item.endInput > item.startInput ? true : false;
                        $emit('nowCheckBehavior', showCheckBehavior);
                      "
                      @blur="
                        endBlur(
                          filterFill(
                            item.rules[0].fillId,
                            item.rules[0].conditions
                          )[0].fillValue,
                          item.endInput
                        )
                      "
                    ></el-input>
                    <span
                      v-show="
                        filterFill(
                          item.rules[0].fillId,
                          item.rules[0].conditions
                        )[0].ruleType == 4
                      "
                      >{{
                        filterFill(
                          item.rules[0].fillId,
                          item.rules[0].conditions
                        )[0].supplementaryStatement
                      }}</span
                    >
                  </div>
                </div>
                <!-- <div>
                  <span>指定课程</span>
                  <el-button type="text" @click="visibleDialog = true"
                    >添加课程</el-button
                  >
                  <span
                    >已选择
                    <el-button type="text">{{ curriculumNum }}</el-button>
                    个课程</span
                  >
                </div> -->
              </el-form-item>
            </el-form>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- <el-dialog
      title="选择课程"
      :visible.sync="visibleDialog"
      width="800px"
      top="10vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <table-select-vue ref="tableSelectRef"></table-select-vue>
      </div>
      <div slot="footer">
        <el-button @click="visibleDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import tableSelectVue from "../../../../components/curriculum/tableSelect.vue";
import { uniqueKey } from "@/utils";

export default {
  components: {
    tableSelectVue,
  },
  data() {
    return {
      behaviorType: "first", //行为类型
      behaviorList: [
        {
          events: [
            {
              rules: [],
            },
          ],
        },
      ], //行为列表
      checkBehavior: [], //行为选中
      curriculumNum: 0, //已选课程数量
      // visibleDialog: false,
      takeEffect: "", //生效规则
      state: true,
      showCheckBehavior: [], //选中显示的值
    };
  },
  props: {
    editState: {
      type: Boolean,
      default: false,
    },
    labelChange: {
      //当前编辑的数据下标
      type: Number,
      default: 0,
    },
    behaviorData: {
      type: Array,
      default: [
        {
          events: [
            {
              rules: [],
            },
          ],
        },
      ],
    },
  },

  mounted() {
    this.behaviorType = "first";
    this.behaviorTypeClick();
  },
  watch: {
    labelChange: {
      handler: function (newVal, oldVal) {
        this.behaviorType = "first";
        this.behaviorTypeClick();
      },
      deep: true,
    },
  },
  methods: {
    // confirm() {
    //   this.$refs["tableSelectRef"].selectList; //获取列表选中的数据。
    // },
    behaviorTypeClick() {
      if (this.behaviorType == "first") {
        this.behaviorList = this.behaviorData.filter((item) => {
          return item.behaviorName == "用户行为";
        });
      } else {
        this.behaviorList = this.behaviorData.filter((item) => {
          return item.behaviorName == "学习行为";
        });
      }
      this.showCheckBehavior.forEach((item, index) => {
        if (!this.checkBehavior.includes(item.id)) {
          this.showCheckBehavior.splice(index, 1);
        }
      });
    },
    behaviorChange(val) {
      this.behaviorList[0].events.forEach((item) => {
        if (this.checkBehavior.includes(item.id)) {
          this.showCheckBehavior.push({
            ...item,
            ...{
              startInput: "",
              endInput: "",
              sign: true,
              state: false,
              behaviorName:
                this.behaviorType == "first" ? "用户行为" : "学习行为",
            },
          });
        }
      });
      this.showCheckBehavior = uniqueKey(this.showCheckBehavior, "id");
      for (let i in this.showCheckBehavior) {
        if (!this.checkBehavior.includes(this.showCheckBehavior[i].id)) {
          this.showCheckBehavior.splice(i, 1);
        }
      }
      this.$emit("nowCheckBehavior", this.showCheckBehavior);
    },
    filterFill(id, arr) {
      return arr.filter((item) => {
        if (item.id == id) {
          item.sign = true;
          return item;
        } else {
          item.sign = false;
          item.fillValue = "";
        }
      });
    },
    startInputChange(val, startVal) {
      let a = val.toString();
      if (a.indexOf(",") == -1) {
        a = startVal;
      } else {
        a = a.split(",");
        a[0] = startVal;
      }
      return a.toString();
    },
    endInputChange(val, endVal) {
      let a = val.toString();
      if (a.indexOf(",") == -1) {
        a = a + "," + endVal;
      } else {
        if (endVal) {
          a = a.split(",");
          a[1] = endVal;
        }
      }
      return a.toString();
    },
    takeEffectInput(val) {
      this.$emit("behaviorData0", val);
    },
    endBlur(val, endVal) {
      let a = val.toString();
      if (a.indexOf(",") != -1) {
        a = a.split(",");
        if (a[0] > a[1]) {
          this.$message.error("结束数字应大于开始数字！");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.BehaviorRule {
}
</style>
