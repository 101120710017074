var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-box",
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            width: "620px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            opened: _vm.handleOpen,
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "用户ID" } }, [
                _vm._v("\n        " + _vm._s(_vm.huid) + "\n      "),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "课程编号", prop: "courseList" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder:
                        "课程编号以逗号（,或，）分隔，形如：C004833,C004834",
                    },
                    model: {
                      value: _vm.form.courseList,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "courseList", $$v)
                      },
                      expression: "form.courseList",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }