var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main", attrs: { id: "main" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.formRules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "申请类型", prop: "applyType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.applyType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "applyType", $$v)
                    },
                    expression: "formData.applyType",
                  },
                },
                [_c("el-radio", { attrs: { label: "解绑手机号" } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "申请内容", prop: "content" } },
            [_c("div", [_vm._v(_vm._s(_vm.formData.content))])]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择项目", prop: "projectId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: {
                    placeholder: "请选择项目",
                    filterable: "",
                    clearable: "",
                  },
                  on: { change: _vm.getApplyUser },
                  model: {
                    value: _vm.formData.projectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "projectId", $$v)
                    },
                    expression: "formData.projectId",
                  },
                },
                _vm._l(_vm.dicts.study_project_id, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "相关证据", prop: "evidencelist" } },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    multiple: "",
                    action: _vm.$store.getters.uploadImgUrl,
                    data: { busiName: 5 },
                    "list-type": "picture-card",
                    "on-preview": _vm.handlePictureCardPreview,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.handleAvatarSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                    "on-change": _vm.fileOnChange,
                    "file-list": _vm.evidencelist,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-plus" }),
                  _vm._v(" "),
                  _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                    _vm._v(
                      "\n          请上传相关证据以通过申请，支持JPG\\PNG格式图片，大小5M以内\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: { visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "申请原因", prop: "applyReason" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "1200px" },
                attrs: {
                  type: "textarea",
                  placeholder: "请输入内容",
                  maxlength: "500",
                  rows: 5,
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.formData.applyReason,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "applyReason", $$v)
                  },
                  expression: "formData.applyReason",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "流程", required: "" } }, [
            _c(
              "div",
              { staticClass: "flow_box" },
              _vm._l(_vm.flowPathObj, function (itemList, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "flowPath", class: index },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.flowKeyMap[index]))]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(itemList, function (items, index) {
                        return _c("li", { key: index }, [
                          _c("span", { staticClass: "flow_head" }, [
                            _vm._v(
                              _vm._s(
                                items.conditionInfo
                                  ? items.conditionInfo[0]
                                  : ""
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(items.conditionInfo))]),
                        ])
                      }),
                      0
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sendData } },
                [_vm._v("提交申请")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }