import request from "@/utils/request";

//用户列表
export function findUserList(data) {
  return request({
    url: "/user/pc/userInfo/findUserList",
    method: "get",
    params: data,
  });
}

export function getUserDetails(data) {
  return request({
    url: "/user/pc/userInfo/getUserDetails",
    method: "get",
    params: data,
  });
}

// 面授 预约订单 获取用户数据
export function getUserDetailALLByHuid(data) {
  return request({
    url: "/user/pc/userInfo/getUserDetailALLByHuid",
    method: "get",
    params: data,
  });
}

export const getUserDetailByAddress = (data) => {
  return request({
    url: "/user/pc/userInfo/getUserDetails/address",
    method: "get",
    params: data,
  });
};

export function editUser(data) {
  return request({
    url: "/user/pc/userInfo/editUser",
    method: "post",
    data: data,
  });
}

export function getUserFeed(data) {
  return request({
    url: "/user/pc/userInfo/getUserDetails/feed",
    method: "get",
    params: data,
  });
}

export function getUserOrder(data) {
  return request({
    url: "/user/pc/userInfo/getUserDetails/order",
    method: "get",
    params: data,
  });
}

export function getUserStudy(data) {
  return request({
    url: "/user/pc/userInfo/getUserDetails/study",
    method: "get",
    params: data,
  });
}

export const getSourceLogs = (params) => {
  return request({
    url: "/user/pc/userInfo/queryByUsrUserSource",
    method: "get",
    params,
  });
};

//用户评价
export function getCurriculumEvaluates(data) {
  return request({
    url: "/curriculum/pc/evaluate/getCurriculumEvaluates",
    method: "get",
    params: data,
  });
}

export function editCurriculumEvaluates(data) {
  return request({
    url: "/curriculum/pc/evaluate/editCurriculumEvaluates",
    method: "post",
    data: data,
  });
}

export function editBatchCurriculumEvaluates(data) {
  return request({
    url: "/curriculum/pc/evaluate/editBatchCurriculumEvaluates",
    method: "post",
    data: data,
  });
}

//用户反馈
export function getUserFeeds(data) {
  return request({
    url: "/user/pc/userNew/getUserFeed",
    method: "get",
    params: data,
  });
}

export function getUserFeedDetails(data) {
  return request({
    url: "/user/pc/user/getUserFeedDetails",
    method: "get",
    params: data,
  });
}

//用户消息
export function getNewsList(data) {
  return request({
    url: "/user/pc/userNotice/getNoticeList",
    method: "get",
    params: data,
  });
}

export function editNews(data) {
  return request({
    url: "/user/pc/userNotice/editNews",
    method: "post",
    data: data,
  });
}

export function addNews(data) {
  return request({
    url: "/user/pc/userNotice/addNews",
    method: "post",
    data: data,
  });
}

export function saveAgreement(data) {
  return request({
    url: "/user/pc/userInfo/operationLoginAgreement",
    method: "post",
    data: data,
  });
}

//管理端发送验证码
export function sendCode(data) {
  return request({
    url: "/user/pc/user/sendCode",
    method: "post",
    data: data,
  });
}

/**用户详情信息采集 */
export function collection(data) {
  return request({
    url: "/user/pc/userInfo/getUserDetails/collection",
    method: "get",
    params: data,
  });
}

export function loginAgreement(data) {
  return request({
    url: "/user/userAgreement/loginAgreement",
    method: "get",
    params: data,
  });
}

//添加登录协议
export function websiteSet(data) {
  return request({
    url: "/user/user/loginAgreement",
    method: "post",
    data: data,
  });
}
//同步用户报名信息
export function syncUserEnroll(data) {
  return request({
    url: "/curriculum/pc/curriculum/batchSyncUserCourse",
    method: "post",
    data: data,
  });
}
//院长信箱分配
export function assignUserFeedback(data) {
  return request({
    url: "/user/pc/userNew/setUserFeedbackAllocationHandler",
    method: "post",
    data: data,
  });
}
//院长信箱跟进
export function handleUserFeedback(data) {
  return request({
    url: "/user/pc/userNew/userFeedbackHandle",
    method: "post",
    data: data,
  });
}
//院长信箱记录
export function getUserFeedbackHandleList(data) {
  return request({
    url: "/user/pc/userNew/getUserFeedbackHandleList",
    method: "get",
    params: data,
  });
}
//获取部门员工
export function findUserAllByDeptId(data) {
  return request({
    // url: '/admin-auth/api/users/findUserAllByDeptId',
    url: "/user/pc/userNew/selectUser",
    method: "get",
    params: data,
  });
}
//获取用户详情
export function getUserFeedbackInfo(data) {
  return request({
    url: "/user/pc/userNew/getUserFeedbackInfo",
    method: "get",
    params: data,
  });
}
//获取留言列表
export function getLeaveMessageList(data) {
  return request({
    url: "/curriculum/pc/curriculumContentMessage/getCurriculumContentMessageList",
    method: "get",
    params: data,
  });
}
//新增回复
export function addReply(data) {
  return request({
    url: "/curriculum/pc/curriculumContentMessage/addReply",
    method: "post",
    data: data,
  });
}
//编辑回复
export function editReply(data) {
  return request({
    url: "/curriculum/pc/curriculumContentMessage/editReply",
    method: "post",
    data: data,
  });
}
//批量编辑评论列表
export function editMessageBatch(data) {
  return request({
    url: "/curriculum/pc/curriculumContentMessage/editMessageBatch",
    method: "post",
    data: data,
  });
}
//解绑手机申请
export function unBindPhone(data) {
  return request({
    url: "/user/pc/userApply/applySubmit",
    method: "post",
    data: data,
  });
}
/**
 *
 * @param {
 * projectId 传对应项目的名称
 * } data
 * @returns
 */
//获取解绑对应审批人列表
export function getApplyUser(data) {
  return request({
    url: "/user/pc/userApply/getApplyUser",
    method: "get",
    params: data,
  });
}
//获取申请记录列表
export function getApplyList(data) {
  return request({
    url: "/user/pc/userApply/list",
    method: "get",
    params: data,
  });
}
//获取审批进度
export function getApplyDetail(data) {
  return request({
    url: "/user/pc/userApply/getApplyDetail",
    method: "get",
    params: data,
  });
}
//撤销申请
export function terminateApply(data) {
  return request({
    url: "/user/pc/userApply/terminateApply",
    method: "get",
    params: data,
  });
}
//重新提交
export function reSubmit(data) {
  return request({
    url: "/user/pc/dingApply/reSubmit",
    method: "post",
    data,
  });
}
//提交的申请数据
export function getApplyById(data) {
  return request({
    url: "/user/pc/userApply/getApplyById",
    method: "get",
    params: data,
  });
}
//获取设备型号 微信版本号
export function getWechatVersion(data) {
  return request({
    url: "/user/pc/user/getWechatVersion",
    method: "get",
    params: data,
  });
}

// 用户详情
// 用户基本信息
export const getUserInfoDetail = (params) => {
  return request({
    // url: "/user/pc/userInfo/getUserCollection",
    url: "/user/pc/userInfo/getUserDetailByHuid",
    method: "get",
    params,
  });
};

// 用户采集信息列表
export const getUserInfoDetailsFromGathered = (params) => {
  return request({
    url: "/curriculum/pc/admin/getUserCollectionInfoByUserId",
    method: "get",
    params,
  });
};

//获取用户直播观看模式 红杏林UI 百家云UI
export function getUserLiveMode(data) {
  return request({
    url: "/user/pc/liveModeNew/getMode",
    method: "get",
    params: data,
  });
}
//切换用户直播观看模式 红杏林UI 百家云UI
export function saveUserLiveMode(data) {
  return request({
    url: "/user/pc/liveModeNew/saveMode",
    method: "post",
    data,
  });
}

// 获取指定huid用户的信息
export function getUserInfoByHuId(data) {
  return request({
    url: "/user/pc/userInfo/getUserInfoByHuId",
    method: "get",
    params: data,
  });
}

export function getUserCurriculumListFn(data) {
  return request({
    url: "/curriculum/pc/curriculum/getUserCurriculumList",
    method: "get",
    params: data,
  });
}
// 获取反馈用户 添加的客服微信列表
export function getCustomerServiceByUnionId(data) {
  return request({
    url: "/inner-crm/pc/wechatNew/getCustomerServiceByUnionId",
    method: "get",
    params: data,
  });
}
// 获取商户列表
export function complaintGetList(data) {
  return request({
    url: "/user/pc/complaint/getList",
    method: "get",
    params: data,
  });
}

//
export function saveFeedbackWorkLogMsgState(data) {
  return request({
    url: "/user/pc/userNew/saveFeedbackWorkLogMsgState",
    method: "get",
    params: data,
  });
}

// 保存用户反馈
export function saveFeedBackNew(data) {
  return request({
    url: "/user/pc/userNew/saveFeedBack",
    method: "post",
    data,
  });
}
