var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main", attrs: { id: "main" } },
    [
      _c("div", [_c("user-info", { attrs: { userinfo: _vm.userInfo } })], 1),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.getTabList },
          model: {
            value: _vm.editableTabsValue,
            callback: function ($$v) {
              _vm.editableTabsValue = $$v
            },
            expression: "editableTabsValue",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "开通记录", name: "0" } },
            [
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.openRecordForm } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "课程状态", clearable: "" },
                          model: {
                            value: _vm.openRecordForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.openRecordForm, "status", $$v)
                            },
                            expression: "openRecordForm.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "正常", value: 0 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "已关闭", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "已到期", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入课程名称", clearable: "" },
                        model: {
                          value: _vm.openRecordForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.openRecordForm, "name", $$v)
                          },
                          expression: "openRecordForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getOpenRecord(1)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-s-table", {
                attrs: {
                  columns: _vm.tableSet[6],
                  data: _vm.openRecordData,
                  border: "",
                },
                on: { "selection-change": _vm.handleSelectionChange },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.isClose != 1
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["userDetail:closeClass"],
                                    expression: "['userDetail:closeClass']",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.closeClass(row)
                                  },
                                },
                              },
                              [_vm._v("关闭")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagebox pageboxRecord" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["userDetail:closeClassAll"],
                          expression: "['userDetail:closeClassAll']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.closeBatch },
                    },
                    [_vm._v("批量关闭")]
                  ),
                  _vm._v(" "),
                  _c("el-pagination", {
                    staticClass: "paginationRecord",
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.pageRecord,
                      "page-sizes": [10, 20, 30, 40],
                      total: _vm.totalRecord,
                      "page-size": _vm.pageSizeRecord,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChangeRecord,
                      "size-change": _vm.handleSizeChangeRecord,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "交易信息", name: "1", lazy: "" } },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属店铺" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择", clearable: "" },
                          on: { clear: _vm.getUserOrder },
                          model: {
                            value: _vm.params.shopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "shopId", $$v)
                            },
                            expression: "params.shopId",
                          },
                        },
                        _vm._l(_vm.shopList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.shopName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "全部", clearable: "" },
                          model: {
                            value: _vm.params.orderStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "orderStatus", $$v)
                            },
                            expression: "params.orderStatus",
                          },
                        },
                        _vm._l(_vm.statusList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "全部", clearable: "" },
                          model: {
                            value: _vm.params.orderType,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "orderType", $$v)
                            },
                            expression: "params.orderType",
                          },
                        },
                        _vm._l(_vm.orderTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.params.courseName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "courseName", $$v)
                          },
                          expression: "params.courseName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: {
                            click: function () {
                              _vm.params.page = 1
                              _vm.getUserOrder()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c("filter-column", {
                        attrs: {
                          columns: _vm.tableSet[2],
                          tableKey: _vm.editableTabsValue,
                        },
                        on: {
                          "update:columns": function ($event) {
                            return _vm.$set(_vm.tableSet, 2, $event)
                          },
                          refresh: function ($event) {
                            return _vm.getUserOrder()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-s-table", {
                attrs: {
                  tableKey: 0 + _vm.editableTabsValue,
                  data: _vm.orderList,
                  columns: _vm.tableSet[0],
                  border: "",
                  "fix-window": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "currImg",
                    fn: function (scope) {
                      return [
                        scope.row.currImg
                          ? _c("el-image", {
                              staticClass: "imgItem",
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src:
                                  _vm.$store.state.public.publicImgUrl +
                                  scope.row.currImg,
                                fit: "cover",
                                "preview-src-list": [
                                  _vm.$store.state.public.publicImgUrl +
                                    scope.row.currImg,
                                ],
                              },
                            })
                          : _c("span", [_vm._v("无")]),
                      ]
                    },
                  },
                  {
                    key: "shopId",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.filterShop(scope.row.shopId)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagebox" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.params.page,
                      "page-sizes": [10, 20, 30, 40],
                      total: _vm.total1,
                      "page-size": _vm.params.pageSize,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "信息采集", name: "2", lazy: "" } },
            [
              _vm.gatheredList.length == 0
                ? _c("el-empty", { attrs: { "image-size": 160 } })
                : _c(
                    "div",
                    { staticClass: "space-y-3" },
                    _vm._l(_vm.gatheredList, function (item, index) {
                      return _c(
                        "el-card",
                        { key: index, staticClass: "box-card" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex justify-between",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("span", { staticClass: "text-slate-400" }, [
                                _vm._v("采集名称:\n              "),
                                _c("span", { staticClass: "text-sky-400" }, [
                                  _vm._v(_vm._s(item.collectionName)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-slate-400" }, [
                                _vm._v("创建人: "),
                                _c("span", [_vm._v(_vm._s(item.createUser))]),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-slate-400" }, [
                                _vm._v("创建时间:\n              "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.collectionUserInfoList[0].createDate
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "grid gap-4 grid-cols-3 grid-rows-3",
                            },
                            _vm._l(
                              item.collectionUserInfoList,
                              function (gatheritem, index) {
                                return _c("div", { key: index }, [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      _vm._s(gatheritem.configTitle) + "："
                                    ),
                                  ]),
                                  _c("span", { staticClass: "text-gray-500" }, [
                                    _vm._v(_vm._s(gatheritem.submitLable)),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    }),
                    1
                  ),
              _vm._v(" "),
              _vm.gatheredList.length > 0
                ? _c(
                    "div",
                    { staticClass: "pagebox" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.page,
                          "page-sizes": [10],
                          total: _vm.gatheredTotal,
                          "page-size": _vm.pageSize,
                        },
                        on: {
                          "current-change": _vm.handleGatheredChange,
                          "size-change": _vm.handleGatheredSizeChange,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "诊疗卡", name: "8", lazy: "" } },
            [_c("UserMedicalCard")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "用户反馈", name: "3", lazy: "" } },
            [
              _c("el-s-table", {
                attrs: {
                  tableKey: 5 + _vm.editableTabsValue,
                  data: _vm.feedList,
                  columns: _vm.tableSet[5],
                  border: "",
                  "fix-window": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "fileInfoList",
                    fn: function (scope) {
                      return [
                        scope.row.fileInfoList
                          ? _vm._l(scope.row.fileInfoList, function (item) {
                              return _c("el-image", {
                                key: item,
                                staticClass: "imgItem",
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: {
                                  src:
                                    _vm.$store.state.public.publicImgUrl + item,
                                  fit: "cover",
                                  "preview-src-list": [
                                    _vm.$store.state.public.publicImgUrl + item,
                                  ],
                                },
                              })
                            })
                          : _c("p", [_vm._v("无")]),
                      ]
                    },
                  },
                  {
                    key: "phone",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("DecryptPhone", {
                          key: "DecryptPhone" + row.huid,
                          attrs: {
                            phone: row.phone,
                            encryptPhone: row.encryptionPhone,
                          },
                          on: {
                            "update:phone": function ($event) {
                              return _vm.$set(row, "phone", $event)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagebox" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.page1,
                      "page-sizes": [10, 20, 30, 40],
                      total: _vm.total4,
                      "page-size": _vm.pageSize1,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange2,
                      "size-change": _vm.handleSizeChange2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "申请记录", name: "4", lazy: "" } },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.applicationRecordForm, inline: true } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "日期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          placement: "bottom-start",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.applicationRecordForm.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.applicationRecordForm, "date", $$v)
                          },
                          expression: "applicationRecordForm.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择项目" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { placeholder: "请选择项目", clearable: "" },
                          model: {
                            value: _vm.applicationRecordForm.projectId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.applicationRecordForm,
                                "projectId",
                                $$v
                              )
                            },
                            expression: "applicationRecordForm.projectId",
                          },
                        },
                        _vm._l(_vm.dicts.study_project_id, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "处理状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "请选择处理状态",
                            clearable: "",
                          },
                          model: {
                            value: _vm.applicationRecordForm.applyStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.applicationRecordForm,
                                "applyStatus",
                                $$v
                              )
                            },
                            expression: "applicationRecordForm.applyStatus",
                          },
                        },
                        _vm._l(
                          _vm.dicts.study_approval_status,
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请人" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "申请人" },
                        model: {
                          value: _vm.applicationRecordForm.applicant,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.applicationRecordForm,
                              "applicant",
                              $$v
                            )
                          },
                          expression: "applicationRecordForm.applicant",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.getapplicationRecord },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-s-table", {
                attrs: {
                  tableKey: 6 + _vm.editableTabsValue,
                  data: _vm.applicationRecordTableData,
                  columns: _vm.applicationRecordColumns,
                  border: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "evidencelist",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.evidencelist
                          ? _c(
                              "div",
                              { staticClass: "scope_img_box" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: _vm._f("filterImgFirst")(
                                      _vm._f("filterImg")(
                                        row.evidencelist,
                                        _vm.publicUrl
                                      )
                                    ),
                                    "preview-src-list": _vm._f("filterImg")(
                                      row.evidencelist,
                                      _vm.publicUrl
                                    ),
                                    "z-index": 3063,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("无")]),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        [0, 1, 2, 3].includes(row.applyStatus) &&
                        _vm.dingUserid == row.createDing
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["userDetail:cancelApply"],
                                    expression: "['userDetail:cancelApply']",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.revocation(row)
                                  },
                                },
                              },
                              [_vm._v("撤销申请")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        [4, 6].includes(row.applyStatus)
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["userDetail:reSubApply"],
                                    expression: "['userDetail:reSubApply']",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submit(row)
                                  },
                                },
                              },
                              [_vm._v("重新提交")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["userDetail:applyProgress"],
                                expression: "['userDetail:applyProgress']",
                              },
                            ],
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.approval(row)
                              },
                            },
                          },
                          [_vm._v("审批进度")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "来源记录", name: "5", lazy: "" } },
            [
              _c("el-s-table", {
                attrs: {
                  tableKey: 7 + _vm.editableTabsValue,
                  data: _vm.sourceLogs,
                  columns: _vm.tableSet[7],
                  border: "",
                  "fix-window": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "phone",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("DecryptPhone", {
                          key: "DecryptPhone" + row.huid,
                          attrs: {
                            phone: row.phone,
                            encryptPhone: row.encryptionPhone,
                          },
                          on: {
                            "update:phone": function ($event) {
                              return _vm.$set(row, "phone", $event)
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "avatarUrl",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "flex justify-center" }, [
                          _c("img", {
                            staticClass: "w-16",
                            attrs: { src: scope.row.avatarUrl },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ApplyProgress", { ref: "applyProgress" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }