<template>
  <div>
    <el-form inline>
      <el-form-item>
        <el-select
          v-model="params.isIssue"
          placeholder="请选择兑换状态"
          style="width: 140px"
          clearable
          @clear="serchBtn"
        >
          <el-option
            v-for="item in exchangeStatusList"
            :label="item.label"
            :value="item.value"
            :key="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.isExpiration"
          placeholder="请选择过期状态"
          style="width: 140px"
          clearable
          @clear="serchBtn"
        >
          <el-option
            v-for="item in validStatusList"
            :label="item.label"
            :value="item.value"
            :key="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入内容"
          v-model.trim="params.keyWord"
          clearable
          @clear="serchBtn"
        >
          <el-select
            v-model="params.keyType"
            slot="prepend"
            placeholder="请选择查询条件"
            style="width: 140px"
          >
            <el-option
              v-for="item in keywordTypeArr"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="serchBtn">查询</el-button>
      </el-form-item>
    </el-form>
    <el-s-table
      :tableKey="'UserMedicalCard'"
      :data="list"
      :columns="columns"
      border
      fix-window
      @selection-change="handleSelectionChange"
    >
      <template v-slot:isIssue="{ row }">
        {{ filterStatus(exchangeStatusList, row.isIssue) }}
      </template>
      <template v-slot:isExpiration="{ row }">
        {{ filterStatus(validStatusList, row.isExpiration) }}
      </template>
      <template v-slot:default="{ row }">
        <el-button
          v-if="row.isIssue == 0"
          type="text"
          @click="exChangeBtn(row)"
          v-permission="['UserMedicalCard:exchange']"
          >兑换</el-button
        >
        <el-button
          type="text"
          @click="delBtn(row)"
          v-permission="['UserMedicalCard:del']"
          >删除</el-button
        >
      </template>
    </el-s-table>
    <div class="mt-1" v-permission="['UserMedicalCard:exchange']">
      <el-button
        type="text"
        :disabled="selList.length <= 0"
        @click="batchExChange(selList)"
        >批量兑换</el-button
      >
    </div>
    <div class="pagebox">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="page"
        :page-sizes="pageSizes"
        :total="total"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { tablePropsInDetails } from "../constants";
import {
  getMedicalCardRecord,
  exchangeMedicalCardById,
  deleteMedicalCardByUser,
} from "@/api/certificate";
export default {
  name: "UserMedicalCard",
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      pageSizes: [10, 20, 30, 40],
      params: {},
      list: [],
      columns: tablePropsInDetails[8],
      exchangeStatusList: [
        {
          id: 2,
          label: "未兑换",
          value: 0,
        },
        {
          id: 3,
          label: "已兑换",
          value: 1,
        },
      ],
      validStatusList: [
        {
          id: 2,
          label: "未过期",
          value: 0,
        },
        {
          id: 3,
          label: "已过期",
          value: 1,
        },
      ],
      keywordTypeArr: [
        {
          id: 1,
          label: "诊疗卡名称",
          value: "certificateName",
        },
        {
          id: 2,
          label: "课程名称",
          value: "contactName",
        },
      ],
      selList: [],
    };
  },

  components: {},

  created() {
    this.serchBtn();
  },

  mounted() {},

  methods: {
    filterStatus(list, value) {
      return list.find((item) => item.value == value)?.label || value;
    },
    serchBtn() {
      this.page = 1;
      this.getList();
    },
    getList() {
      const params = {
        huid: this.$route.query.id,
        page: this.page,
        pageSize: this.pageSize,
        credentiaModelTypes: 0,
        isDelete: 0,
        ...this.getParams(),
      };
      getMedicalCardRecord(params).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list;
          this.total = res.data.total;
          return;
        }
        this.$message.error(res.message);
      });
    },
    getParams() {
      const params = { ...this.params };
      if (params.keyType && params.keyWord) {
        params[params.keyType] = params.keyWord;
      }
      delete params.keyType;
      delete params.keyWord;
      console.log(params);
      return params;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.selList = val;
    },
    batchExChange(list) {
      this.confirmExChange(list);
    },
    exChangeBtn(row) {
      this.confirmExChange([row]);
    },
    delBtn(row) {
      this.$confirm("你确定要删除诊疗卡？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          this.deleteMedicalCardByUser({
            credentiaModelTypes: 0,
            certificateId: row.certificateId,
            puids: [row.userId],
          });
        })
        .catch(() => {});
    },
    confirmExChange(list) {
      this.$confirm("你确定要兑换诊疗卡？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          this.exchangeMedicalCardById({ puids: list.map((item) => item.id) });
        })
        .catch(() => {});
    },
    exchangeMedicalCardById(data) {
      exchangeMedicalCardById(data).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.getList();
          return;
        }
        this.$message.error(res.message);
      });
    },
    deleteMedicalCardByUser(data) {
      deleteMedicalCardByUser(data).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.getList();
          return;
        }
        this.$message.error(res.message);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
