var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 9, sm: 6, md: 4, lg: 4, xl: 4 } },
            [
              _c(
                "div",
                { staticClass: "head-container" },
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "输入部门名称搜索",
                      "prefix-icon": "el-icon-search",
                    },
                    on: { input: _vm.getDeptDatas },
                    model: {
                      value: _vm.deptName,
                      callback: function ($$v) {
                        _vm.deptName = $$v
                      },
                      expression: "deptName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-tree", {
                attrs: {
                  data: _vm.deptDatas,
                  props: _vm.defaultProps,
                  "expand-on-click-node": false,
                  "default-expand-all": false,
                  accordion: "",
                },
                on: { "node-click": _vm.handleNodeClick },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 15, sm: 18, md: 20, lg: 20, xl: 20 } },
            [
              _c("div", { staticClass: "head-container" }, [
                _vm.crud.props.searchToggle
                  ? _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          staticClass: "date-item",
                          attrs: {
                            "default-time": ["00:00:00", "23:59:59"],
                            type: "daterange",
                            "range-separator": ":",
                            size: "small",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "start-placeholder": "创建日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.query.createTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.query, "createTime", $$v)
                            },
                            expression: "query.createTime",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "请选择公司",
                              clearable: "",
                              size: "small",
                            },
                            on: { change: _vm.changeCompany },
                            model: {
                              value: _vm.currentCompany,
                              callback: function ($$v) {
                                _vm.currentCompany = $$v
                              },
                              expression: "currentCompany",
                            },
                          },
                          _vm._l(_vm.companyList, function (item) {
                            return _c("el-option", {
                              key: item.corpId,
                              attrs: {
                                label: item.orgName,
                                value: item.corpId,
                              },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: {
                            clearable: "",
                            size: "small",
                            placeholder: "输入名称或者邮箱搜索",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.crud.toQuery($event)
                            },
                          },
                          model: {
                            value: _vm.query.blurry,
                            callback: function ($$v) {
                              _vm.$set(_vm.query, "blurry", $$v)
                            },
                            expression: "query.blurry",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          staticClass: "date-item",
                          attrs: {
                            "default-time": ["00:00:00", "23:59:59"],
                            type: "daterange",
                            "range-separator": ":",
                            size: "small",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "start-placeholder": "创建日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.query.createTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.query, "createTime", $$v)
                            },
                            expression: "query.createTime",
                          },
                        }),
                        _vm._v(" "),
                        _c("rrOperation", { attrs: { crud: _vm.crud } }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                  },
                  [
                    _c("crudOperation", {
                      attrs: { show: "", permission: _vm.permission },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "append-to-body": "",
                    "close-on-click-modal": false,
                    "before-close": _vm.crud.cancelCU,
                    visible: _vm.crud.status.cu > 0,
                    title: _vm.crud.status.title,
                    width: "570px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.crud.status, "cu > 0", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        inline: true,
                        model: _vm.form,
                        rules: _vm.rules,
                        size: "small",
                        "label-width": "66px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户名", prop: "username" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "username", $$v)
                              },
                              expression: "form.username",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "电话", prop: "phone" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phone", _vm._n($$v))
                              },
                              expression: "form.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "昵称", prop: "nickName" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.nickName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nickName", $$v)
                              },
                              expression: "form.nickName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮箱", prop: "email" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "部门", prop: "dept.id" } },
                        [
                          _c("treeselect", {
                            staticStyle: { width: "178px" },
                            attrs: {
                              options: _vm.depts,
                              placeholder: "选择部门",
                            },
                            on: { select: _vm.selectFun },
                            model: {
                              value: _vm.form.dept.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.dept, "id", $$v)
                              },
                              expression: "form.dept.id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "岗位", prop: "job.id" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.job.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.job, "id", $$v)
                              },
                              expression: "form.job.id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { width: "178px" },
                              model: {
                                value: _vm.form.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sex", $$v)
                                },
                                expression: "form.sex",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "男" } }, [
                                _vm._v("男"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "女" } }, [
                                _vm._v("女"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.form.id === _vm.user.id },
                              model: {
                                value: _vm.form.enabled,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "enabled", $$v)
                                },
                                expression: "form.enabled",
                              },
                            },
                            _vm._l(_vm.dict.user_status, function (item) {
                              return _c(
                                "el-radio",
                                { key: item.id, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { label: "角色", prop: "roles" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "437px" },
                              attrs: { multiple: "", placeholder: "请选择" },
                              on: {
                                "remove-tag": _vm.deleteTag,
                                change: _vm.changeRole,
                              },
                              model: {
                                value: _vm.form.roles,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "roles", $$v)
                                },
                                expression: "form.roles",
                              },
                            },
                            _vm._l(_vm.roles, function (item) {
                              return _c("el-option", {
                                key: item.name,
                                attrs: {
                                  disabled:
                                    _vm.level !== 1 && item.level <= _vm.level,
                                  label: item.name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.crud.cancelCU },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.crud.status.cu === 2,
                            type: "primary",
                          },
                          on: { click: _vm.crud.submitCU },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.crud.loading,
                      expression: "crud.loading",
                    },
                  ],
                  ref: "table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.crud.data },
                  on: { "selection-change": _vm.crud.selectionChangeHandler },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      selectable: _vm.checkboxT,
                      type: "selection",
                      width: "55",
                    },
                  }),
                  _vm._v(" "),
                  _vm.columns.visible("username")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "username",
                          label: "用户名",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("companyName")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "companyName",
                          label: "公司",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("nickName")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "nickName",
                          label: "昵称",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("avatar")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "avatar",
                          label: "头像",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("sex")
                    ? _c("el-table-column", {
                        attrs: { prop: "sex", label: "性别" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("phone")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "phone",
                          width: "100",
                          label: "电话",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("email")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          width: "125",
                          prop: "email",
                          label: "邮箱",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("roles")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          width: "100",
                          label: "角色",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  scope.row.roles,
                                  function (items) {
                                    return _c("span", [
                                      _vm._v(" " + _vm._s(items.name) + " "),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          3145175518
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("title")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          width: "125",
                          prop: "title",
                          label: "职位信息",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("jobNumber")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          width: "125",
                          prop: "jobNumber",
                          label: "工号",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("hiredDate")
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          width: "125",
                          prop: "hiredDate",
                          label: "入职时间",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.columns.visible("createTime")
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          width: "140",
                          label: "创建日期",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setRoleUser(scope.row)
                                  },
                                },
                              },
                              [_vm._v("角色")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { margin: "20px", "text-align": "center" } },
                [_c("pagination")],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-edit" },
                  on: { click: _vm.allAddRole },
                },
                [_vm._v("批量分配角色")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.allRolesDialog,
            title: "分配角色",
            width: "570px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.allRolesDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: true,
                rules: _vm.rules,
                size: "small",
                "label-width": "66px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "角色" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "437px" },
                      attrs: {
                        multiple: "",
                        placeholder: "请选择",
                        filterable: "",
                      },
                      on: { change: _vm.changeRoleToUser },
                      model: {
                        value: _vm.allRoles,
                        callback: function ($$v) {
                          _vm.allRoles = $$v
                        },
                        expression: "allRoles",
                      },
                    },
                    _vm._l(_vm.roles, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.allRolesDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveRolesToUser },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }