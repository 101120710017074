var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "head-container" },
        [
          _vm.crud.props.searchToggle
            ? _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeProjeck },
                      model: {
                        value: _vm.currentProjeck,
                        callback: function ($$v) {
                          _vm.currentProjeck = $$v
                        },
                        expression: "currentProjeck",
                      },
                    },
                    _vm._l(_vm.projectList, function (item) {
                      return _c("el-option", {
                        key: item.projectCode,
                        attrs: {
                          label: item.projectName,
                          value: item.projectCode,
                        },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "模糊搜索--菜单名称",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.toQuery($event)
                      },
                    },
                    model: {
                      value: _vm.query.blurry,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "blurry", $$v)
                      },
                      expression: "query.blurry",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticClass: "date-item",
                    attrs: {
                      "default-time": ["00:00:00", "23:59:59"],
                      type: "daterange",
                      "range-separator": ":",
                      size: "small",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.query.createTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "createTime", $$v)
                      },
                      expression: "query.createTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("rrOperation", { attrs: { crud: _vm.crud } }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("crudOperation", { attrs: { permission: _vm.permission } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.crud.cancelCU,
            visible: _vm.crud.status.cu > 0,
            title: _vm.crud.status.title,
            width: "580px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.crud.status, "cu > 0", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: true,
                model: _vm.form,
                rules: _vm.rules,
                size: "small",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "菜单类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { width: "178px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("目录"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("菜单"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("按钮"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type.toString() !== "2",
                      expression: "form.type.toString() !== '2'",
                    },
                  ],
                  attrs: { label: "菜单图标", prop: "icon" },
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom-start",
                        width: "450",
                        trigger: "click",
                      },
                      on: {
                        show: function ($event) {
                          return _vm.$refs["iconSelect"].reset()
                        },
                      },
                    },
                    [
                      _c("IconSelect", {
                        ref: "iconSelect",
                        on: { selected: _vm.selected },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "450px" },
                          attrs: {
                            slot: "reference",
                            placeholder: "点击选择图标",
                            readonly: "",
                          },
                          slot: "reference",
                          model: {
                            value: _vm.form.icon,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "icon", $$v)
                            },
                            expression: "form.icon",
                          },
                        },
                        [
                          _vm.form.icon
                            ? _c("svg-icon", {
                                staticClass: "el-input__icon",
                                staticStyle: { height: "32px", width: "16px" },
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": _vm.form.icon,
                                },
                                slot: "prefix",
                              })
                            : _c("i", {
                                staticClass: "el-icon-search el-input__icon",
                                attrs: { slot: "prefix" },
                                slot: "prefix",
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type.toString() !== "2",
                      expression: "form.type.toString() !== '2'",
                    },
                  ],
                  attrs: { label: "外链菜单", prop: "iframe" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form.iframe,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "iframe", $$v)
                        },
                        expression: "form.iframe",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "true" } }, [
                        _vm._v("是"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "false" } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type.toString() === "1",
                      expression: "form.type.toString() === '1'",
                    },
                  ],
                  attrs: { label: "菜单缓存", prop: "cache" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form.cache,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cache", $$v)
                        },
                        expression: "form.cache",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "true" } }, [
                        _vm._v("是"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "false" } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type.toString() !== "2",
                      expression: "form.type.toString() !== '2'",
                    },
                  ],
                  attrs: { label: "菜单可见", prop: "hidden" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form.hidden,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "hidden", $$v)
                        },
                        expression: "form.hidden",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "false" } }, [
                        _vm._v("是"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "true" } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type.toString() !== "2",
                      expression: "form.type.toString() !== '2'",
                    },
                  ],
                  attrs: { label: "菜单标题", prop: "name" },
                },
                [
                  _c("el-input", {
                    style:
                      _vm.form.type.toString() === "0"
                        ? "width: 450px"
                        : "width: 178px",
                    attrs: { placeholder: "菜单标题" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type.toString() === "2",
                      expression: "form.type.toString() === '2'",
                    },
                  ],
                  attrs: { label: "按钮名称", prop: "name" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "178px" },
                    attrs: { placeholder: "按钮名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type.toString() !== "0",
                      expression: "form.type.toString() !== '0'",
                    },
                  ],
                  attrs: { label: "权限标识", prop: "permission" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "178px" },
                    attrs: {
                      disabled: _vm.form.iframe,
                      placeholder: "权限标识",
                    },
                    model: {
                      value: _vm.form.permission,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "permission", $$v)
                      },
                      expression: "form.permission",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.type.toString() !== "2"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "路由地址", prop: "path" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "178px" },
                        attrs: { placeholder: "路由地址" },
                        model: {
                          value: _vm.form.path,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "path", $$v)
                          },
                          expression: "form.path",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "菜单排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "178px" },
                    attrs: { min: 0, max: 999, "controls-position": "right" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", _vm._n($$v))
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.form.iframe && _vm.form.type.toString() === "1",
                      expression:
                        "!form.iframe && form.type.toString() === '1'",
                    },
                  ],
                  attrs: { label: "组件名称", prop: "componentName" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "178px" },
                    attrs: { placeholder: "匹配组件内Name字段" },
                    model: {
                      value: _vm.form.componentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "componentName", $$v)
                      },
                      expression: "form.componentName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.form.iframe && _vm.form.type.toString() === "1",
                      expression:
                        "!form.iframe && form.type.toString() === '1'",
                    },
                  ],
                  attrs: { label: "组件路径", prop: "component" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "178px" },
                    attrs: { placeholder: "组件路径" },
                    model: {
                      value: _vm.form.component,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "component", $$v)
                      },
                      expression: "form.component",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上级类目", prop: "pid" } },
                [
                  _c("treeselect", {
                    staticStyle: { width: "450px" },
                    attrs: { options: _vm.menus, placeholder: "选择上级类目" },
                    model: {
                      value: _vm.form.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pid", $$v)
                      },
                      expression: "form.pid",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.crud.cancelCU } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.crud.status.cu === 2, type: "primary" },
                  on: { click: _vm.crud.submitCU },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.crud.loading,
              expression: "crud.loading",
            },
          ],
          ref: "table",
          attrs: {
            data: _vm.crud.data,
            "tree-props": { children: "children", hasChildren: "hasChildren" },
            "row-key": "id",
          },
          on: {
            select: _vm.crud.selectChange,
            "select-all": _vm.crud.selectAllChange,
            "selection-change": _vm.crud.selectionChangeHandler,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _vm.columns.visible("name")
            ? _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": true,
                  label: "菜单名称",
                  width: "125px",
                  prop: "name",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.columns.visible("icon")
            ? _c("el-table-column", {
                attrs: {
                  prop: "icon",
                  label: "图标",
                  align: "center",
                  width: "60px",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class": scope.row.icon
                                ? scope.row.icon
                                : "",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2050958368
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.columns.visible("sort")
            ? _c("el-table-column", {
                attrs: { prop: "sort", align: "center", label: "排序" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n        " + _vm._s(scope.row.sort) + "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2735326215
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.columns.visible("path")
            ? _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": true,
                  prop: "path",
                  label: "路由地址",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.columns.visible("permission")
            ? _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": true,
                  prop: "permission",
                  label: "权限标识",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.columns.visible("component")
            ? _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": true,
                  prop: "component",
                  label: "组件路径",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.columns.visible("iframe")
            ? _c("el-table-column", {
                attrs: { prop: "iframe", label: "外链", width: "75px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.iframe
                            ? _c("span", [_vm._v("是")])
                            : _c("span", [_vm._v("否")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  293408353
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.columns.visible("cache")
            ? _c("el-table-column", {
                attrs: { prop: "cache", label: "缓存", width: "75px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.cache
                            ? _c("span", [_vm._v("是")])
                            : _c("span", [_vm._v("否")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  542152025
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.columns.visible("hidden")
            ? _c("el-table-column", {
                attrs: { prop: "hidden", label: "可见", width: "75px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.hidden
                            ? _c("span", [_vm._v("否")])
                            : _c("span", [_vm._v("是")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2325335551
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.columns.visible("createTime")
            ? _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建日期",
                  width: "135px",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.parseTime(scope.row.createTime))),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3078210614
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: ["admin", "menu:edit", "menu:del"],
                expression: "['admin', 'menu:edit', 'menu:del']",
              },
            ],
            attrs: {
              label: "操作",
              width: "130px",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("udOperation", {
                      attrs: {
                        data: scope.row,
                        permission: _vm.permission,
                        msg: "确定删除吗,如果存在下级节点则一并删除，此操作不能撤销！",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }