<template>
  <div class="main" id="main">
    <div>
      <user-info :userinfo="userInfo" />
    </div>

    <el-tabs
      type="border-card"
      @tab-click="getTabList"
      v-model="editableTabsValue"
    >
      <el-tab-pane label="开通记录" name="0">
        <el-form :inline="true" :model="openRecordForm">
          <el-form-item>
            <el-select
              v-model="openRecordForm.status"
              placeholder="课程状态"
              clearable
            >
              <el-option label="正常" :value="0"></el-option>
              <el-option label="已关闭" :value="1"></el-option>
              <el-option label="已到期" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="openRecordForm.name"
              placeholder="请输入课程名称"
              clearable
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getOpenRecord(1)">查询</el-button>
          </el-form-item>
        </el-form>
        <el-s-table
          :columns="tableSet[6]"
          :data="openRecordData"
          @selection-change="handleSelectionChange"
          border
        >
          <template v-slot:default="{ row }">
            <el-button
              type="text"
              @click="closeClass(row)"
              v-permission="['userDetail:closeClass']"
              v-if="row.isClose != 1"
              >关闭</el-button
            >
          </template>
        </el-s-table>
        <div class="pagebox pageboxRecord">
          <el-button
            type="primary"
            v-permission="['userDetail:closeClassAll']"
            @click="closeBatch"
            >批量关闭</el-button
          >
          <el-pagination
            class="paginationRecord"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageRecord"
            :page-sizes="[10, 20, 30, 40]"
            :total="totalRecord"
            :page-size="pageSizeRecord"
            @current-change="handleCurrentChangeRecord"
            @size-change="handleSizeChangeRecord"
          />
        </div>
      </el-tab-pane>

      <el-tab-pane label="交易信息" name="1" lazy>
        <el-form :inline="true">
          <el-form-item label="所属店铺">
            <el-select
              v-model="params.shopId"
              placeholder="请选择"
              style="width: 200px"
              clearable
              @clear="getUserOrder"
            >
              <el-option
                v-for="(item, index) in shopList"
                :label="item.shopName"
                :value="item.id"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select
              v-model="params.orderStatus"
              placeholder="全部"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select
              v-model="params.orderType"
              placeholder="全部"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in orderTypeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="params.courseName"
              placeholder="请输入"
              style="width: 200px"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                () => {
                  params.page = 1;
                  getUserOrder();
                }
              "
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <filter-column
              :columns.sync="tableSet[2]"
              :tableKey="editableTabsValue"
              @refresh="getUserOrder()"
            />
          </el-form-item>
        </el-form>
        <el-s-table
          :tableKey="0 + editableTabsValue"
          :data="orderList"
          :columns="tableSet[0]"
          border
          fix-window
        >
          <template v-slot:currImg="scope">
            <el-image
              v-if="scope.row.currImg"
              style="width: 100px; height: 100px"
              :src="$store.state.public.publicImgUrl + scope.row.currImg"
              fit="cover"
              class="imgItem"
              :preview-src-list="[
                $store.state.public.publicImgUrl + scope.row.currImg,
              ]"
            ></el-image>
            <span v-else>无</span>
          </template>
          <template v-slot:shopId="scope">
            {{ filterShop(scope.row.shopId) }}
          </template>
        </el-s-table>
        <div class="pagebox">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="params.page"
            :page-sizes="[10, 20, 30, 40]"
            :total="total1"
            :page-size="params.pageSize"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="学习信息">
        <el-form :model="studyForm">
          <el-form-item label="课程名称">
            <el-input v-model="studyForm.currName"
                      placeholder="课程名称"
                      style="width: 200px" />
            <el-button type="primary"
                       icon="el-icon-search"
                       @click="getUserStudy(1)">查 询</el-button>
          </el-form-item>
        </el-form>

        <el-s-table :tableKey="1 + editableTabsValue"
                    :data="studyList"
                    :columns="tableSet[1]"
                    border
                    fix-window>
        </el-s-table>

        <div class="pagebox">
          <el-pagination background
                         layout="total, sizes, prev, pager, next, jumper"
                         :current-page="page"
                         :page-sizes="[10, 20, 30, 40]"
                         :total="total2"
                         :page-size="pageSize"
                         @current-change="handleCurrentChange1"
                         @size-change="handleSizeChange1" />
        </div>
      </el-tab-pane> -->
      <el-tab-pane label="信息采集" name="2" lazy>
        <!-- <el-s-table :tableKey="2 + editableTabsValue"
                    :data="gatheredList"
                    :columns="tableSet[2]"
                    border
                    fix-window>
        </el-s-table> -->
        <el-empty v-if="gatheredList.length == 0" :image-size="160"></el-empty>
        <div v-else class="space-y-3">
          <el-card
            class="box-card"
            v-for="(item, index) in gatheredList"
            :key="index"
          >
            <div slot="header" class="flex justify-between">
              <span class="text-slate-400"
                >采集名称:
                <span class="text-sky-400">{{
                  item.collectionName
                }}</span></span
              >
              <span class="text-slate-400"
                >创建人: <span>{{ item.createUser }}</span></span
              >
              <span class="text-slate-400"
                >创建时间:
                <span>{{
                  item.collectionUserInfoList[0].createDate
                }}</span></span
              >
            </div>
            <div class="grid gap-4 grid-cols-3 grid-rows-3">
              <div
                :key="index"
                v-for="(gatheritem, index) in item.collectionUserInfoList"
              >
                <span class="mr-2">{{ gatheritem.configTitle }}：</span
                ><span class="text-gray-500">{{ gatheritem.submitLable }}</span>
              </div>
            </div>
          </el-card>
        </div>
        <div class="pagebox" v-if="gatheredList.length > 0">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page"
            :page-sizes="[10]"
            :total="gatheredTotal"
            :page-size="pageSize"
            @current-change="handleGatheredChange"
            @size-change="handleGatheredSizeChange"
          />
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="考试信息">
        <el-s-table :tableKey="3 + editableTabsValue"
                    :data="testList"
                    :columns="tableSet[3]"
                    border
                    fix-window />
      </el-tab-pane> -->

      <el-tab-pane label="诊疗卡" name="8" lazy>
        <UserMedicalCard />
      </el-tab-pane>
      <el-tab-pane label="用户反馈" name="3" lazy>
        <el-s-table
          :tableKey="5 + editableTabsValue"
          :data="feedList"
          :columns="tableSet[5]"
          border
          fix-window
        >
          <template v-slot:fileInfoList="scope">
            <template v-if="scope.row.fileInfoList">
              <el-image
                style="width: 100px; height: 100px"
                :src="$store.state.public.publicImgUrl + item"
                fit="cover"
                v-for="item in scope.row.fileInfoList"
                :key="item"
                class="imgItem"
                :preview-src-list="[$store.state.public.publicImgUrl + item]"
              ></el-image>
            </template>
            <p v-else>无</p>
          </template>
          <template v-slot:phone="{ row }">
            <DecryptPhone
              :key="'DecryptPhone' + row.huid"
              :phone.sync="row.phone"
              :encryptPhone="row.encryptionPhone"
            />
          </template>
        </el-s-table>
        <div class="pagebox">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page1"
            :page-sizes="[10, 20, 30, 40]"
            :total="total4"
            :page-size="pageSize1"
            @current-change="handleCurrentChange2"
            @size-change="handleSizeChange2"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="申请记录" name="4" lazy>
        <el-form :model="applicationRecordForm" :inline="true">
          <el-form-item label="日期">
            <el-date-picker
              v-model="applicationRecordForm.date"
              type="datetimerange"
              placement="bottom-start"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="选择项目">
            <el-select
              v-model="applicationRecordForm.projectId"
              placeholder="请选择项目"
              style="width: 150px"
              clearable
            >
              <el-option
                v-for="item in dicts.study_project_id"
                :label="item.label"
                :value="item.label"
                :key="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="处理状态">
            <el-select
              v-model="applicationRecordForm.applyStatus"
              placeholder="请选择处理状态"
              style="width: 150px"
              clearable
            >
              <el-option
                v-for="(item, index) in dicts.study_approval_status"
                :label="item.label"
                :value="item.value"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="申请人">
            <el-input
              v-model="applicationRecordForm.applicant"
              placeholder="申请人"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getapplicationRecord"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-s-table
          :tableKey="6 + editableTabsValue"
          :data="applicationRecordTableData"
          :columns="applicationRecordColumns"
          border
        >
          <template v-slot:evidencelist="{ row }">
            <div class="scope_img_box" v-if="row.evidencelist">
              <el-image
                :src="row.evidencelist | filterImg(publicUrl) | filterImgFirst"
                :preview-src-list="row.evidencelist | filterImg(publicUrl)"
                :z-index="3063"
              >
              </el-image>
            </div>
            <span v-else>无</span>
          </template>
          <template v-slot:default="{ row }">
            <el-button
              type="text"
              v-if="
                [0, 1, 2, 3].includes(row.applyStatus) &&
                dingUserid == row.createDing
              "
              v-permission="['userDetail:cancelApply']"
              @click="revocation(row)"
              >撤销申请</el-button
            >
            <el-button
              type="text"
              v-if="[4, 6].includes(row.applyStatus)"
              v-permission="['userDetail:reSubApply']"
              @click="submit(row)"
              >重新提交</el-button
            >
            <el-button
              type="text"
              v-permission="['userDetail:applyProgress']"
              @click="approval(row)"
              >审批进度</el-button
            >
          </template>
        </el-s-table>
      </el-tab-pane>
      <el-tab-pane label="来源记录" name="5" lazy>
        <el-s-table
          :tableKey="7 + editableTabsValue"
          :data="sourceLogs"
          :columns="tableSet[7]"
          border
          fix-window
        >
          <template v-slot:phone="{ row }">
            <DecryptPhone
              :key="'DecryptPhone' + row.huid"
              :phone.sync="row.phone"
              :encryptPhone="row.encryptionPhone"
            />
          </template>
          <template v-slot:avatarUrl="scope">
            <div class="flex justify-center">
              <img :src="scope.row.avatarUrl" class="w-16" />
            </div>
          </template>
        </el-s-table>
      </el-tab-pane>
    </el-tabs>
    <ApplyProgress ref="applyProgress" />
    <!-- <el-dialog
      title="审批进度"
      :visible.sync="visibleDialog"
      width="800px"
      top="10vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div></div>
      <div slot="footer">
        <el-button @click="visibleDialog = false">取 消</el-button>
        <el-button type="primary" @click="visibleDialog = false"
          >确 定</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
// import { getShopList } from "@/api/shop/index";
import { queryDicDetailType } from "@/api/upload/upload";
import {
  // getUserDetails,
  // getUserDetailByAddress,
  getUserOrder,
  getUserStudy,
  getUserFeed,
  getSourceLogs,
  // collection,
  getApplyList,
  // getApplyDetail,
  terminateApply,
  // reSubmit,
  // getWechatVersion,
  getUserInfoDetail,
  getUserInfoDetailsFromGathered,
  getUserCurriculumListFn,
} from "@/api/user/index";
import { closeUserCurr } from "@/api/curriculum/curriculum.js";
import { tablePropsInDetails } from "./constants.js";
import ApplyProgress from "./components/ApplyProgress.vue";
import UserInfo from "./components/UserInfo.vue";
import UserMedicalCard from "./components/UserMedicalCard.vue"; // 诊疗卡
export default {
  components: {
    ApplyProgress,
    UserInfo,
    UserMedicalCard,
  },
  data() {
    return {
      totalRecord: 0,
      pageRecord: 0,
      pageSizeRecord: 10,
      multipleSelection: [],

      dingUserid: this.$store.state.user.user.dingUserid,
      publicUrl: this.$store.state.public.publicImgUrl,
      dicts: {
        study_approval_status: [],
      }, // 字典表
      shopList: [],
      id: "",
      userInfo: {},
      deviceInfo: {}, // 手机型号 微信版本
      sourceList: this.$store.getters.sourceList,
      statusList: this.$store.state.public.dics.orderStatus, // 订单状态 下拉列表
      orderTypeList: this.$store.getters.orderTypeList, //订单类型 下拉
      orderList: [],
      studyList: [],
      gatheredList: [],
      testList: [],
      feedList: [],
      /**默认表格分页总条数为0 */
      total1: 0,
      total2: 0,
      total3: 0,
      total4: 0,
      gatheredTotal: 0,
      sourceLogsTotal: 0,
      page: 1,
      page1: 1,
      pageSize: 10,
      pageSize1: 10,
      payTypeList: this.$store.getters.payTypeList,
      currImg: "",
      collectionList: [],
      /**用户信息采集 */
      editableTabsValue: "",
      infoType: "1",
      /**选中tab name值 */
      openRecordData: [],
      params: {
        orderStatus: "",
        orderType: "", //订单类型
        courseName: "",
        page: 1,
        pageSize: 10,
      },
      tableSet: tablePropsInDetails,
      basicInfo: [], //基本信息
      certification: [], //认证信息
      lifeStyle: [], //生活信息
      TCMAssessment: [], //中医信息
      customInfo: [], //自定义信息
      getAge: (birthday) => {
        //出生时间 毫秒
        let birthDayTime = new Date(birthday).getTime();
        //当前时间 毫秒
        let nowTime = new Date().getTime();
        //一年毫秒数(365 * 86400000 = 31536000000)
        return Math.ceil((nowTime - birthDayTime) / 31536000000);
      },
      //申请记录
      applicationRecordForm: {},
      applicationRecordTableData: [],
      applicationRecordColumns: [
        { prop: "applyType", label: "申请类型" },
        { prop: "content", label: "申请内容" },
        { prop: "projectId", label: "所属项目" },
        { prop: "evidencelist", label: "相关证据" },
        { prop: "applyReason", label: "申请原因" },
        {
          prop: "applyStatus",
          label: "处理状态",
          formatter: (row) => {
            const obj =
              this.dicts.study_approval_status.find(
                (item) => item.value == row.applyStatus
              ) || {};
            return obj.label || row.applyStatus;
          },
        },
        { prop: "rejectReason", label: "驳回原因" },
        { prop: "createUser", label: "申请人" },
        { prop: "createTime", label: "申请时间" },
        {
          prop: "default",
          label: "操作",
          "show-overflow-tooltip": false,
          "min-width": "200",
          fixed: "right",
        },
      ],
      // visibleDialog: false,
      projectList: [], // 申请解绑关联的项目
      studyForm: {}, // 学习记录
      openRecordForm: {},
      sourceLogs: [], // 用户来源记录
    };
  },
  filters: {
    filterImgFirst: (imgPaths) => (imgPaths.length > 0 ? imgPaths[0] : ""),
    filterImg: (imgPaths, publicUrl) => {
      imgPaths = imgPaths
        ? Array.isArray(imgPaths)
          ? imgPaths
          : imgPaths.split(",")
        : [];
      imgPaths = imgPaths.map((item) => {
        if (item.indexOf("http") < 0) {
          item = `${publicUrl}${item}`;
        }
        return item;
      });
      return imgPaths.length > 0 ? imgPaths : [];
    },
  },
  created() {
    const { id, pid } = this.$route.query;
    this.id = id;
    this.pid = Number(pid);
    // const Odata = {
    //   page: 1,
    //   pageSize: 0,
    // };
    // getShopList(Odata).then((res) => {
    //   if (res.code === 200) {
    //     this.shopList = res.data.data;
    //   }
    // });
    this.$store.dispatch("GetShopList").then((list) => {
      this.shopList = list;
    });
    //this.getUserDetailsInfo();
    //this.getUserOrder();
    //this.getUserStudy();
    //this.getUserFeed();
  },
  mounted() {
    // this.getUserDetailsInfo();
    // // this.getWechatVersion();
    // this.getCollection();
    this.init();
    // this.getUserDetailsInfo();
    // this.getCollection();
    this.getTabList();
  },
  methods: {
    filterShop(id) {
      // 筛选店铺
      return this.shopList.find((item) => item.id == id)?.shopName || id;
    },
    getOpenRecord(page = 1) {
      let data = {
        currName: this.openRecordForm.name,
        pageNumber: page,
        pageSize: this.pageSize,
        huid: this.id,
        status: this.openRecordForm.status,
      };
      getUserCurriculumListFn(data).then((res) => {
        if (res.code == 200) {
          this.openRecordData = res.data.data;
          this.totalRecord = res.data.total;
          this.pageRecord = page;
          return;
        }
        this.$message.error(res.message);
      });
    },
    // 批量关闭
    closeBatch() {
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请勾选需要操作的课程");
        return;
      }
      // let tempArr = [];
      // for (let i = 0; i < this.multipleSelection.length; i++) {
      //   tempArr.push({
      //     currCode: this.multipleSelection[i].curriculumCode,
      //     puId: this.$route.query.pid * 1,
      //   });
      // }
      // console.log(tempArr)
      this.closeClassToos(
        this.multipleSelection.map((item) => item.curriculumCode)
      );
    },
    closeClassToos(currCodes) {
      const formData = {
        huid: this.id,
        puid: this.pid,
        currCodes,
      };
      this.$confirm("确定关闭该学员的听课权限？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        closeUserCurr(formData).then((res) => {
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.getTabList();
            return;
          }
          this.$message.error(res.message);
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 关闭单个
    closeClass(row) {
      // let tempArr = [
      //   {
      //     currCode: row.curriculumCode,
      //     puId: this.$route.query.pid * 1,
      //   },
      // ];
      this.closeClassToos([row.curriculumCode]);
      // console.log(tempArr)
    },
    async init() {
      const { id } = this.$route.query;
      // 用户详情基本信息
      const { data: userDetail } = await getUserInfoDetail({ huid: id });
      const { huid } = userDetail;
      this.userInfo = userDetail;
      this.id = huid;
      // this.getUserOrder();
      // 信息采集列表
      // await getUserInfoDetailsFromGathered()
    },
    getTabList(e) {
      // console.log(e, this.editableTabsValue);
      // /** 点击切换时加载数据*/
      // // 用户反馈
      // if (this.editableTabsValue == 4) {
      //   this.getUserFeed();
      // }
      // // 学习信息
      // else if (this.editableTabsValue == 1) {
      //   this.getUserStudy();
      // }
      // // 交易信息
      // else if (this.editableTabsValue == 0) {
      //   this.getUserOrder();
      // }
      // // 考试信息
      // else if (this.editableTabsValue == 3) {
      //   this.getUserOrder();
      // }
      // // 信息采集
      // else if (this.editableTabsValue == 2) {
      //   this.getUserGathered();
      // }
      // // 申请记录
      // else if (this.editableTabsValue == 5) {
      //   if (
      //     !this.dicts.study_approval_status ||
      //     this.dicts.study_approval_status.length <= 0
      //   ) {
      //     this.queryDicDetailType();
      //   }
      //   this.getapplicationRecord();
      // }

      // 开通记录 22-11-25 新增
      if (this.editableTabsValue == 0) {
        this.getOpenRecord();
      }
      /** 点击切换时加载数据*/
      // 用户反馈
      if (this.editableTabsValue == 3) {
        this.getUserFeed();
      }
      // 学习信息
      // else if (this.editableTabsValue == 1) {
      //   this.getUserStudy();
      // }
      // 交易信息
      else if (this.editableTabsValue == 1) {
        this.getUserOrder();
      }
      // 考试信息
      // else if (this.editableTabsValue == 3) {
      //   this.getUserOrder();
      // }
      // 信息采集
      else if (this.editableTabsValue == 2) {
        this.getUserGathered();
      }
      // 申请记录
      else if (this.editableTabsValue == 4) {
        if (
          !this.dicts.study_approval_status ||
          this.dicts.study_approval_status.length <= 0
        ) {
          this.queryDicDetailType();
        }
        this.getapplicationRecord();
      }
      // 来源记录
      else if (this.editableTabsValue == 5) {
        this.getSourceLogs();
      }
    },
    // getCollection() {
    //   /**获取当前用户信息采集 */
    //   let params = {
    //     userId: this.id,
    //   };
    //   collection(params).then((res) => {
    //     if (res.code == 200) {
    //       if (res.data) {
    //         res.data.forEach((el) => {
    //           if (el.type == 1) {
    //             if (el.configTitle == "生日" && el.submitLable) {
    //               el.submitLable =
    //                 el.submitLable +
    //                 "，" +
    //                 this.ageConversion(el.submitLable) +
    //                 "岁";
    //             }
    //             this.basicInfo.push(el);
    //           } else if (el.type == 2) {
    //             this.certification.push(el);
    //           } else if (el.type == 3) {
    //             this.lifeStyle.push(el);
    //           } else if (el.type == 4) {
    //             this.TCMAssessment.push(el);
    //           } else {
    //             if (el.showType == 14) {
    //               el.submitLable = el.submitLable.replace(/\+/, " ");
    //             } else if (el.showType == 15) {
    //               el.submitLable = el.submitValue.split(",").map((item) => {
    //                 return {
    //                   lable: item.replace(/\+.*/, ""),
    //                   value: item.replace(/.*?\+/, "") * 1,
    //                 };
    //               });
    //             }
    //             this.customInfo.push(el);
    //           }
    //         });
    //       }
    //     }
    //   });
    // },
    // getUserDetailsInfoById() {
    //   const params = { userId: this.id };
    //   getUserDetailByAddress(params).then(({ data }) => {
    //     const { address, area, city, province, phone } = data;
    //     this.userInfo.address = address;
    //     this.userInfo.userPhone = phone;
    //     const locationArr = [];
    //     if (province) {
    //       locationArr.push(province);
    //     }
    //     if (city) {
    //       locationArr.push(city);
    //     }
    //     if (area) {
    //       locationArr.push(area);
    //     }
    //     this.userInfo.location = locationArr.join("-");
    //     console.log(this.userInfo);
    //     console.log(data);
    //     // console.log(data);
    //     // this.$nextTick(() => {
    //     //   this.userInfo.address = address;
    //     //   this.userInfo.userPhone = phone;
    //     //   this.userInfo.location = `${province}-${area}-${city}`;
    //     //   console.log(this.userInfo);
    //     // });
    //   });
    // },
    // getUserDetailsInfo() {
    //   getUserDetails({
    //     userId: this.id,
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       // console.log(res);
    //       this.userInfo = Object.assign({}, res.data, {
    //         address: "",
    //         userPhone: "",
    //         location: "",
    //       });
    //       this.getUserDetailsInfoById();
    //     }
    //   });
    // },
    // // 设备型号 微信版本
    // getWechatVersion() {
    //   getWechatVersion({
    //     userId: this.id,
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       // console.log(res);
    //       this.deviceInfo = res.data || {};
    //       return;
    //     }
    //     this.$message.error(res.message);
    //   });
    // },
    getUserGathered() {
      const { page, pageSize } = this;
      const data = {
        huId: this.id,
        puId: this.pid,
        userId: this.pid,
        // userId: "6736406",
        page,
        pageSize,
      };
      getUserInfoDetailsFromGathered(data).then((res) => {
        if (res.code == 200) {
          this.gatheredList = res.data.data;
          this.gatheredTotal = res.data.total;
        }
      });
    },
    getUserOrder() {
      let Odata = {
        puId: this.pid,
        ...this.params,
      };
      getUserOrder(Odata).then((res) => {
        if (res.code == 200) {
          this.orderList = res.data.data;
          this.total1 = res.data.total;
        }
      });
    },
    // 学习记录
    getUserStudy(page = 1) {
      let Odata = {
        page: page,
        pageSize: this.pageSize,
        puId: this.pid,
        ...this.studyForm,
      };
      getUserStudy(Odata).then((res) => {
        if (res.code == 200) {
          this.studyList = res.data.data;
          this.total2 = res.data.total;
        }
      });
    },
    // 用户反馈
    getUserFeed(page = 1) {
      let Odata = {
        page: page,
        pageSize: this.pageSize,
        puId: this.pid,
      };
      getUserFeed(Odata).then((res) => {
        if (res.code == 200) {
          this.feedList = res.data.data;
          this.total4 = res.data.total;
        }
      });
    },
    // 来源记录
    getSourceLogs(page = 1) {
      // let Odata = {
      //   page: page,
      //   pageSize: this.pageSize,
      //   puId: this.pid,
      // };
      const params = { huid: this.$route.query.id };
      getSourceLogs(params).then((res) => {
        if (res.code == 200) {
          this.sourceLogs = res.data;
          // this.sourceLogsTotal = res.data.total;
        }
      });
    },
    // 获取字典表
    queryDicDetailType() {
      const dictName = "study_approval_status,study_project_id"; // 申请记录 选择项目 处理状态 字典表
      queryDicDetailType({ dictName }).then((res) => {
        if (res.code === 200) {
          this.dicts = res.data;
        }
      });
    },
    handleCurrentChange(e) {
      /** 分页切换 */
      this.params.page = e;
      this.getUserOrder();
    },
    //pageSize发生改变
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.params.page = 1;
      this.getUserOrder();
    },
    handleGatheredChange(val) {
      this.page = val;
      this.getUserGathered(val);
    },
    handleGatheredSizeChange(val) {
      this.pageSize = val;
      this.getUserGathered();
    },
    handleCurrentChange1(val) {
      this.page = val;
      this.getUserStudy(val);
    },
    handleSizeChange1(val) {
      this.pageSize = val;
      this.getUserStudy();
    },
    handleSizeChangeRecord(val) {
      this.pageSize = val;
      this.getOpenRecord();
    },
    handleCurrentChangeRecord(val) {
      // this.page = val;
      this.getOpenRecord(val);
    },
    handleCurrentChange2(val) {
      this.page1 = val;
      this.getUserFeed(val);
    },
    handleSizeChange2(val) {
      this.pageSize1 = val;
      this.getUserFeed();
    },
    ageConversion(date) {
      var age = "";
      var str = date.replace(/年|月/g, "-").replace(/日/g, "");
      var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
      if (r == null) return false;
      var d = new Date(r[1], r[3] - 1, r[4]);
      if (
        d.getFullYear() == r[1] &&
        d.getMonth() + 1 == r[3] &&
        d.getDate() == r[4]
      ) {
        var Y = new Date().getFullYear();
        age = Y - r[1];
        return age;
      } else {
        return "时间格式错误";
      }
    },
    //获取申请记录
    getapplicationRecord() {
      const [startDate, endDate] = this.applicationRecordForm.date || [];
      const params = {
        startDate,
        endDate,
        huId: this.id,
        ...this.applicationRecordForm,
      };
      delete this.applicationRecordForm.date;
      return new Promise((resolve, reject) => {
        getApplyList(params).then((res) => {
          if (res.code == 200) {
            this.applicationRecordTableData = res.data;
            resolve(res.data);
            return;
          }
          this.$message.error(res.message);
          reject(res);
        });
      });
    },
    //撤销申请
    revocation(row) {
      this.$confirm(`你确定要撤销该申请`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          terminateApply({ userId: this.pid }).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              // 查询钉钉撤销申请状态流转
              setTimeout(() => {
                this.getapplicationRecord().then((list) => {
                  // 对应数据状态未流转到撤销 在查一次
                  const data = list.find((item) => item.id == row.id);
                  if (data.applyStatus != 6) {
                    setTimeout(() => {
                      this.getapplicationRecord().then((list) => {
                        const data = list.find((item) => item.id == row.id);
                        if (data.applyStatus != 6) {
                          this.$message.error("钉钉撤销申请中，请稍候查询结果");
                          return;
                        }
                      });
                    }, 1000);
                    return;
                  }
                });
              }, 500);
              return;
            }
            this.$message.error(res.message);
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //审批进度
    approval(row) {
      this.$refs.applyProgress.show(row);
    },
    //重新提交
    submit(row) {
      this.openPage("Unbind", { id: row.id, userId: this.pid });
      // this.$confirm(`你确定要重新提交该申请`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   const data = { ...row }
      //   data.evidencelist = data.evidencelist.split(',')
      //   reSubmit(data).then(res => {
      //     if (res.code == 200) {
      //       this.$message.success(res.message)
      //       this.getapplicationRecord()
      //       return
      //     }
      //     this.$message.error(res.message)
      //   })
      // }).catch(res => {
      //   console.log(res)
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  margin: 0 auto;
  /* border: 1px solid #ebebeb; */
  padding: 20px 50px;
  font-size: 12px;
  border-radius: 3px;
}
.pageboxRecord {
  display: flex;
}
.paginationRecord {
  flex: 1;
}

// .pagebox {
//   text-align: right;
//   margin-top: 25px;
// }

// .textColor {
//   color: red;
// }

// .payInfo {
//   clear: both;
//   margin: 20px 0;
//   color: #888;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-start;
//   align-items: center;
// }

// .payInfo p {
//   display: inline-block;
//   font-size: 13px;
//   // width: 150px;
//   // max-width: 200px;
//   margin: 10px 130px 10px 0;
//   // margin-right: 130px;
// }

// .bookimg {
//   width: 80px;
//   height: 80px;
//   border-radius: 5px;
// }

// .userTags {
//   margin: 20px 0;
// }

// .userTags span {
//   margin-right: 10px;
// }

// .userAvtar {
//   border: 1px solid #eee;
//   width: 100px;
//   height: 100px;
//   border-radius: 50%;
//   text-align: center;
//   padding: 4px;
//   box-shadow: 1px 1px 5px #eee;
//   margin: 20px auto;
// }

// .userAvtar img {
//   width: 90px;
//   height: 90px;
//   border-radius: 50%;
// }

// .title {
//   font-size: 18px;
//   font-weight: bold;
//   margin: 20px 0;
// }

// .imgItem {
//   margin: 5px;
// }

.basicInfo,
.realNameInfo,
.lifestyleInfo,
.evaluateInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  div {
    padding: 20px;
    // width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.customInfo {
  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
  }

  .imgBox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .selectImg {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    .el-image {
      display: block;
      width: 300px;
      height: 168px;
    }
  }

  .matrixBox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .selectMatrix {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 10px;
      padding: 0;
    }
  }
}

.scope_img_box {
  position: relative;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  .el-image {
    height: 100%;
  }
}
</style>
