<template>
  <div class="details-box">
    <div class="flex flex-col items-center mb-10">
      <div class="mb-5">
        <img class="w-24 h-24 rounded-full" :src="userInfo.userImg" />
      </div>
      <div class="text-lg text-sky-400">{{ userInfo.userNickName }}</div>
    </div>
    <el-descriptions
      :column="4"
      :colon="false"
      size="medium"
      labelClassName="item-label"
      contentClassName="item-content"
    >
      <!-- <el-descriptions-item label="头像"><img class="w-10 h-10 rounded-full"
             :src="userInfo.userImg" /></el-descriptions-item>
      <el-descriptions-item label="微信名"><span class="text-sky-400">{{userInfo.userNickName  }}</span></el-descriptions-item> -->
      <el-descriptions-item label="微信号">{{
        userInfo.userWechatId
      }}</el-descriptions-item>
      <el-descriptions-item label="姓名">{{
        userInfo.userName
      }}</el-descriptions-item>
      <el-descriptions-item label="性别">{{
        enumSex[userInfo.userSex]
      }}</el-descriptions-item>
      <el-descriptions-item label="年龄">{{
        enumAge[userInfo.userAge]
      }}</el-descriptions-item>
      <el-descriptions-item label="生日">{{
        userInfo.userBrithday
      }}</el-descriptions-item>
      <el-descriptions-item label="绑定手机号">
        <DecryptPhone
          :key="'DecryptPhone userPhone' + userInfo.id"
          :phone.sync="userInfo.userPhone"
          :encryptPhone="userInfo.encryptionUserPhone"
        />
      </el-descriptions-item>
      <el-descriptions-item label="采集手机号">
        <DecryptPhone
          :key="'DecryptPhone userCollectPhone' + userInfo.id"
          :phone.sync="userInfo.userCollectPhone"
          :encryptPhone="userInfo.encryptionUserCollectPhone"
        />
        <span v-if="userInfo.userCollectPhone" class="item-tip"
          >最新</span
        ></el-descriptions-item
      >
      <el-descriptions-item label="行业">{{
        userInfo.userTrade
      }}</el-descriptions-item>
      <el-descriptions-item label="职业">{{
        userInfo.userProfession
      }}</el-descriptions-item>
      <el-descriptions-item label="月收入">{{
        enumSalary[userInfo.userMonthlyIncome]
      }}</el-descriptions-item>
      <el-descriptions-item label="身份证">{{
        userInfo.userIdNumber
      }}</el-descriptions-item>
      <el-descriptions-item label="健康状态">{{
        userInfo.healthStatus
      }}</el-descriptions-item>
      <el-descriptions-item label="家庭成员">{{
        userInfo.familyMembers
      }}</el-descriptions-item>
      <el-descriptions-item label="民族">{{
        userInfo.familialClan
      }}</el-descriptions-item>
      <el-descriptions-item label="病症">{{
        userInfo.userDisease
      }}</el-descriptions-item>
      <el-descriptions-item label="收货人手机号">
        <DecryptPhone
          :key="'DecryptPhone consigneePhone' + userInfo.id"
          :phone.sync="userInfo.consigneePhone"
          :encryptPhone="userInfo.encryptionConsigneePhone"
        />
      </el-descriptions-item>
      <el-descriptions-item label="地区">{{
        userInfo.userArea
      }}</el-descriptions-item>
      <el-descriptions-item label="收货地址"
        >{{ userInfo.userAddress }}
      </el-descriptions-item>
      <el-descriptions-item label="手机型号"
        >{{ userInfo.userPhoneVerison }}
      </el-descriptions-item>
      <el-descriptions-item label="微信版本"
        >{{ userInfo.userWechatVersion }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { enumSex, enumAge, enumSalary } from "../constants";
export default {
  props: {
    userinfo: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      enumSex: enumSex,
      enumAge: enumAge,
      enumSalary: enumSalary,
    };
  },
  computed: {
    userInfo() {
      return this.userinfo || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.details-box {
  margin-bottom: 35px;

  ::v-deep .item-label {
    color: #979797;
    align-items: center;
    // font-size: 15px;
  }

  ::v-deep .item-content {
    color: #686868;
    // font-size: 15px;
    // margin-bottom: 10px;
  }

  .item-tip {
    color: #f37126;
    margin-left: 5px;
  }
}
</style>
