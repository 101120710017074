var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main", attrs: { id: "main" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            { attrs: { width: "300px" } },
            [
              _c(
                "div",
                { staticClass: "backRouter", on: { click: _vm.backRouter } },
                [
                  _c("i", { staticClass: "el-icon-arrow-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v("返回")]),
                ]
              ),
              _vm._v(" "),
              _c("el-tree", {
                attrs: {
                  data: _vm.treeData,
                  props: _vm.defaultProps,
                  "node-key": "id",
                  "default-expanded-keys": _vm.defaultExpandedKeys,
                  "default-checked-keys": _vm.defaultCheckedKeys,
                  "highlight-current": true,
                  accordion: "",
                },
                on: { "node-click": _vm.handleNodeClick },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-container",
            [
              _c("el-header", [
                _c("div", { staticClass: "groupTags" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.labelDetail.tagName))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        { staticClass: "form_box", attrs: { inline: true } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "标签状态：" } },
                            [
                              _c("div", { staticClass: "status" }, [
                                _c("span", {
                                  class:
                                    _vm.labelDetail.status == 1 ? "waring" : "",
                                }),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      !_vm.labelDetail.status ? "启用" : "禁用"
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "标签值个数：" } },
                            [
                              _c(
                                "span",
                                { staticStyle: { color: "#5b8ff9d9" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.labelDetail.valueYLayout
                                        ? _vm.labelDetail.valueYLayout.length
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "标签覆盖人数：" } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.labelDetail.coverNum)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建时间：" } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.labelDetail.createDate)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-main", [
                _c(
                  "div",
                  { staticClass: "labelRules" },
                  [
                    _c("h4", [_vm._v("RFM人数分布")]),
                    _vm._v(" "),
                    _c("el-s-table", {
                      attrs: { data: _vm.rData, columns: _vm.rColumns },
                    }),
                    _vm._v(" "),
                    _c("el-s-table", {
                      attrs: { data: _vm.fData, columns: _vm.fColumns },
                    }),
                    _vm._v(" "),
                    _c("el-s-table", {
                      attrs: { data: _vm.mData, columns: _vm.mColumns },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-footer", [
                _c("div", { staticClass: "echartBox" }, [
                  _c("h4", [_vm._v("标签值覆盖分布")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "crosswiseBar" }, [
                    _c("p", [_vm._v("标签值覆盖分布（总人数）")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "barVueBox" },
                      [
                        _c("bar-vue", {
                          attrs: {
                            nowOptions: _vm.crosswiseBarOptions,
                            barId: "crosswiseBarId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "stackBar" }, [
                    _c("p", [_vm._v("标签历史详情")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "barVueBox" },
                      [
                        _c("bar-vue", {
                          attrs: {
                            nowOptions: _vm.stackBarOptions,
                            barId: "stackBarId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }