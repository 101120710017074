var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-pagination", {
    staticStyle: { "margin-top": "8px" },
    attrs: {
      "page-size": _vm.page.size,
      total: _vm.page.total,
      "current-page": _vm.page.page,
      layout: "total, prev, pager, next, sizes",
    },
    on: {
      "update:pageSize": function ($event) {
        return _vm.$set(_vm.page, "size", $event)
      },
      "update:page-size": function ($event) {
        return _vm.$set(_vm.page, "size", $event)
      },
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.page, "page", $event)
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.page, "page", $event)
      },
      "size-change": function ($event) {
        return _vm.crud.sizeChangeHandler($event)
      },
      "current-change": _vm.crud.pageChangeHandler,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }