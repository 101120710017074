<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--侧边部门数据-->
      <el-col :xs="9" :sm="6" :md="4" :lg="4" :xl="4">
        <div class="head-container">
          <el-input
            v-model="deptName"
            clearable
            size="small"
            placeholder="输入部门名称搜索"
            prefix-icon="el-icon-search"
            class="filter-item"
            @input="getDeptDatas"
          />

          <!--选择所属公司-->
        </div>
        <el-tree
          :data="deptDatas"
          :props="defaultProps"
          :expand-on-click-node="false"
          :default-expand-all="false"
          accordion
          @node-click="handleNodeClick"
        />
      </el-col>
      <!--用户数据-->
      <el-col :xs="15" :sm="18" :md="20" :lg="20" :xl="20">
        <!--工具栏-->
        <div class="head-container">
          <div v-if="crud.props.searchToggle">
            <el-date-picker
              v-model="query.createTime"
              :default-time="['00:00:00', '23:59:59']"
              type="daterange"
              range-separator=":"
              size="small"
              class="date-item"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="创建日期"
              end-placeholder="结束日期"
            />
            <!-- 搜索 -->
            <el-select
              v-model="currentCompany"
              placeholder="请选择公司"
              clearable
              @change="changeCompany"
              style="width: 220px"
              class="filter-item"
              size="small"
            >
              <el-option
                v-for="item in companyList"
                :key="item.corpId"
                :label="item.orgName"
                :value="item.corpId"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="query.blurry"
              clearable
              size="small"
              placeholder="输入名称或者邮箱搜索"
              style="width: 200px"
              class="filter-item"
              @keyup.enter.native="crud.toQuery"
            />
            <!-- <el-date-picker
              v-model="query.createTime"
              type="daterange"
              range-separator=":"
              size="small"
              class="date-item"
              value-format="yyyy-MM-dd"
              placeholder="创建时间"
            /> -->

            <el-date-picker
              v-model="query.createTime"
              :default-time="['00:00:00', '23:59:59']"
              type="daterange"
              range-separator=":"
              size="small"
              class="date-item"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="创建日期"
              end-placeholder="结束日期"
            />
            <!-- <el-select
              v-model="query.enabled"
              clearable
              size="small"
              placeholder="状态"
              class="filter-item"
              style="width: 90px"
              @change="crud.toQuery"
            >
              <el-option
                v-for="item in enabledTypeOptions"
                :key="item.key"
                :label="item.display_name"
                :value="item.key"
              />
            </el-select> -->
            <rrOperation :crud="crud" />
          </div>
          <div v-show="false">
            <crudOperation show="" :permission="permission" />
          </div>
        </div>
        <!--表单渲染-->
        <el-dialog
          append-to-body
          :close-on-click-modal="false"
          :before-close="crud.cancelCU"
          :visible.sync="crud.status.cu > 0"
          :title="crud.status.title"
          width="570px"
        >
          <el-form
            ref="form"
            :inline="true"
            :model="form"
            :rules="rules"
            size="small"
            label-width="66px"
          >
            <el-form-item label="用户名" prop="username">
              <el-input v-model="form.username" />
            </el-form-item>
            <el-form-item label="电话" prop="phone">
              <el-input v-model.number="form.phone" />
            </el-form-item>
            <el-form-item label="昵称" prop="nickName">
              <el-input v-model="form.nickName" />
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="form.email" />
            </el-form-item>
            <el-form-item label="部门" prop="dept.id">
              <treeselect
                v-model="form.dept.id"
                :options="depts"
                style="width: 178px"
                placeholder="选择部门"
                @select="selectFun"
              />
            </el-form-item>
            <el-form-item label="岗位" prop="job.id">
              <!-- <el-select
                v-model="form.job.id"
                style="width: 178px"
                placeholder="请先选择部门"
              >
                <el-option
                  v-for="(item, index) in jobs"
                  :key="item.name + index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select> -->
              <el-input v-model="form.job.id" />
            </el-form-item>
            <el-form-item label="性别">
              <el-radio-group v-model="form.sex" style="width: 178px">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="状态">
              <el-radio-group
                v-model="form.enabled"
                :disabled="form.id === user.id"
              >
                <el-radio
                  v-for="item in dict.user_status"
                  :key="item.id"
                  :label="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item style="margin-bottom: 0" label="角色" prop="roles">
              <el-select
                v-model="form.roles"
                style="width: 437px"
                multiple
                placeholder="请选择"
                @remove-tag="deleteTag"
                @change="changeRole"
              >
                <el-option
                  v-for="item in roles"
                  :key="item.name"
                  :disabled="level !== 1 && item.level <= level"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="crud.cancelCU">取消</el-button>
            <el-button
              :loading="crud.status.cu === 2"
              type="primary"
              @click="crud.submitCU"
              >确认</el-button
            >
          </div>
        </el-dialog>
        <!--表格渲染-->
        <el-table
          ref="table"
          v-loading="crud.loading"
          :data="crud.data"
          style="width: 100%"
          @selection-change="crud.selectionChangeHandler"
        >
          <el-table-column
            :selectable="checkboxT"
            type="selection"
            width="55"
          />
          <el-table-column
            v-if="columns.visible('username')"
            :show-overflow-tooltip="true"
            prop="username"
            label="用户名"
          />
          <el-table-column
            v-if="columns.visible('companyName')"
            :show-overflow-tooltip="true"
            prop="companyName"
            label="公司"
          />
          <el-table-column
            v-if="columns.visible('nickName')"
            :show-overflow-tooltip="true"
            prop="nickName"
            label="昵称"
          />
          <el-table-column
            v-if="columns.visible('avatar')"
            :show-overflow-tooltip="true"
            prop="avatar"
            label="头像"
          >
            <!-- <template slot="scope">
            <img :src="scope.row.avatar">
          </template> -->
          </el-table-column>
          <el-table-column
            v-if="columns.visible('sex')"
            prop="sex"
            label="性别"
          />
          <el-table-column
            v-if="columns.visible('phone')"
            :show-overflow-tooltip="true"
            prop="phone"
            width="100"
            label="电话"
          />
          <el-table-column
            v-if="columns.visible('email')"
            :show-overflow-tooltip="true"
            width="125"
            prop="email"
            label="邮箱"
          />
          <el-table-column
            v-if="columns.visible('roles')"
            :show-overflow-tooltip="true"
            width="100"
            label="角色"
          >
            <template slot-scope="scope">
              <span v-for="items in scope.row.roles"> {{ items.name }} </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columns.visible('title')"
            :show-overflow-tooltip="true"
            width="125"
            prop="title"
            label="职位信息"
          />

          <el-table-column
            v-if="columns.visible('jobNumber')"
            :show-overflow-tooltip="true"
            width="125"
            prop="jobNumber"
            label="工号"
          />
          <el-table-column
            v-if="columns.visible('hiredDate')"
            :show-overflow-tooltip="true"
            width="125"
            prop="hiredDate"
            label="入职时间"
          />

          <!-- <el-table-column v-if="columns.visible('dept')" :show-overflow-tooltip="true" width="110" prop="dept" label="部门 / 岗位">
            <template slot-scope="scope">
              <div>{{ scope.row.dept.name }} / {{ scope.row.job.name }}</div>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            v-if="columns.visible('enabled')"
            label="状态"
            align="center"
            prop="enabled"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.enabled"
                :disabled="user.id === scope.row.id"
                active-color="#3370FF"
                inactive-color="#F56C6C"
                @change="changeEnabled(scope.row, scope.row.enabled)"
              />
            </template>
          </el-table-column> -->
          <el-table-column
            v-if="columns.visible('createTime')"
            prop="createTime"
            width="140"
            label="创建日期"
          >
            <!-- <template slot-scope="scope">
              <span>{{ parseTime(scope.row.createTime) }}</span>
            </template> -->
          </el-table-column>
          <!-- <el-table-column
            v-permission="['admin', 'user:edit', 'user:del']"
            label="操作"
            width="125"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <udOperation
                :data="scope.row"
                :permission="permission"
                :disabled-dle="scope.row.id === user.id"
              />
            </template>
          </el-table-column> -->

          <el-table-column>
            <template slot-scope="scope">
              <el-button
                type="text"
                icon="el-icon-edit"
                @click="setRoleUser(scope.row)"
                >角色</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页组件-->
        <div style="margin: 20px; text-align: center">
          <pagination />
        </div>

        <el-button type="primary" @click="allAddRole" icon="el-icon-edit"
          >批量分配角色</el-button
        >
      </el-col>
    </el-row>
    <!--表单渲染-->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="allRolesDialog"
      title="分配角色"
      width="570px"
    >
      <el-form
        ref="form"
        :inline="true"
        :rules="rules"
        size="small"
        label-width="66px"
      >
        <el-form-item style="margin-bottom: 0" label="角色">
          <el-select
            v-model="allRoles"
            style="width: 437px"
            multiple
            placeholder="请选择"
            @change="changeRoleToUser"
            filterable
          >
            <!-- <el-option
              v-for="item in roles"
              :key="item.name"
              :disabled="level !== 1 && item.level <= level"
              :label="item.name"
              :value="item.id"
            /> -->
            <el-option
              v-for="item in roles"
              :key="item.name"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="allRolesDialog = false">取消</el-button>
        <el-button type="primary" @click="saveRolesToUser">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { crudUser, grant } from "@/api/system/user";
import { isvalidPhone } from "@/utils/validate";
import { getDepts } from "@/api/system/dept";
import { getAll, getLevel } from "@/api/system/role";
import { getAllJob } from "@/api/system/job";
import CRUD, { presenter, header, form, crud } from "@crud/crud";
import rrOperation from "@crud/RR.operation";
import crudOperation from "@crud/CRUD.operation";
import udOperation from "@crud/UD.operation";
import pagination from "@crud/Pagination";
import Treeselect from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { projeckOrg } from "@/api/system/user.js";
import service from "@/utils/request.js";

let userRoles = [];
// crud交由presenter持有

const defaultCrud = CRUD({
  title: "用户",
  url: "api/users",
  crudMethod: { ...crudUser },
});
const defaultForm = {
  id: null,
  username: null,
  nickName: null,
  sex: "男",
  email: null,
  enabled: "false",
  roles: [],
  job: { id: null },
  dept: { id: null },
  phone: null,
};
export default {
  name: "User",
  components: {
    Treeselect,
    crudOperation,
    rrOperation,
    udOperation,
    pagination,
  },
  mixins: [presenter(defaultCrud), header(), form(defaultForm), crud()],
  // 数据字典
  dicts: ["user_status"],
  data() {
    // 自定义验证
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else if (!isvalidPhone(value)) {
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
    };
    return {
      height: document.documentElement.clientHeight - 180 + "px;",
      deptName: "",
      depts: [],
      deptDatas: [],
      jobs: [],
      level: 3,
      roles: [],
      defaultProps: { children: "children", label: "name" },
      permission: {
        add: ["admin", "user:add"],
        edit: ["admin", "user:edit"],
        del: ["admin", "user:del"],
      },
      enabledTypeOptions: [
        { key: "true", display_name: "激活" },
        { key: "false", display_name: "锁定" },
      ],
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        nickName: [
          { required: true, message: "请输入用户昵称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        phone: [{ required: true, trigger: "blur", validator: validPhone }],
      },
      companyList: [],
      currentCompany: "",
      allRoles: [],
      allRolesDialog: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    // 获取钉钉部门推荐

    this.$nextTick(() => {
      projeckOrg().then((res) => {
        this.companyList = res.configs;
        //service.defaults.headers["corpId"] = e;
        this.currentCompany = res.configs[0].corpId;
      });
      this.getDeptDatas();
      this.crud.toQuery();
      this.crud.msg.add = "新增成功，默认密码：123456";
    });
  },
  mounted: function () {
    const that = this;
    window.onresize = function temp() {
      that.height = document.documentElement.clientHeight - 180 + "px;";
    };
  },
  methods: {
    changeRole(value) {
      userRoles = [];
      value.forEach(function (data, index) {
        const role = { id: data };
        userRoles.push(role);
      });
    },
    [CRUD.HOOK.afterAddError](crud) {
      this.afterErrorMethod(crud);
    },
    [CRUD.HOOK.afterEditError](crud) {
      this.afterErrorMethod(crud);
    },
    afterErrorMethod(crud) {
      // 恢复select
      const initRoles = [];
      userRoles.forEach(function (role, index) {
        initRoles.push(role.id);
      });
      crud.form.roles = initRoles;
    },
    deleteTag(value) {
      userRoles.forEach(function (data, index) {
        if (data.id === value) {
          userRoles.splice(index, value);
        }
      });
    },
    // 新增与编辑前做的操作
    [CRUD.HOOK.afterToCU](crud, form) {
      this.getDepts();
      this.getRoles();
      this.getRoleLevel();
      form.enabled = form.enabled.toString();
    },
    // 打开编辑弹窗前做的操作
    [CRUD.HOOK.beforeToEdit](crud, form) {
      this.getJobs(this.form.dept.id);
      userRoles = [];
      const roles = [];
      form.roles.forEach(function (role, index) {
        roles.push(role.id);
        // 初始化编辑时候的角色
        const rol = { id: role.id };
        userRoles.push(rol);
      });
      form.roles = roles;
    },
    // 提交前做的操作
    [CRUD.HOOK.afterValidateCU](crud) {
      if (!crud.form.dept.id) {
        this.$message({
          message: "部门不能为空",
          type: "warning",
        });
        return false;
      } else if (!crud.form.job.id) {
        this.$message({
          message: "岗位不能为空",
          type: "warning",
        });
        return false;
      } else if (this.roles.length === 0) {
        this.$message({
          message: "角色不能为空",
          type: "warning",
        });
        return false;
      }
      crud.form.roles = userRoles;
      return true;
    },
    // 获取左侧部门数据
    getDeptDatas() {
      const sort = "id,desc";
      const params = {
        sort: sort,
      };
      if (this.deptName) {
        params["name"] = this.deptName;
      }
      getDepts(params).then((res) => {
        this.deptDatas = res.data;
      });
    },
    // 获取弹窗内部门数据
    getDepts() {
      getDepts({
        enabled: true,
      }).then((res) => {
        this.depts = res.data;
      });
    },
    // 切换部门
    handleNodeClick(data) {
      if (data.pid === 0) {
        this.query.deptId = null;
      } else {
        this.query.deptId = data.id;
      }
      this.crud.toQuery();
    },
    // 改变状态
    changeEnabled(data, val) {
      this.$confirm(
        '此操作将 "' +
          this.dict.label.user_status[val] +
          '" ' +
          data.username +
          ", 是否继续？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          crudUser
            .edit(data)
            .then((res) => {
              this.crud.notify(
                this.dict.label.user_status[val] + "成功",
                CRUD.NOTIFICATION_TYPE.SUCCESS
              );
            })
            .catch(() => {
              data.enabled = !data.enabled;
            });
        })
        .catch(() => {
          data.enabled = !data.enabled;
        });
    },
    // 获取弹窗内角色数据
    getRoles() {
      getAll()
        .then((res) => {
          this.roles = res;
        })
        .catch(() => {});
    },
    // 获取弹窗内岗位数据
    getJobs(id) {
      getAllJob(id)
        .then((res) => {
          this.jobs = res.data;
        })
        .catch(() => {});
    },
    // 点击部门搜索对应的岗位
    selectFun(node, instanceId) {
      this.getJobs(node.id);
      this.form.job.id = null;
    },
    // 获取权限级别
    getRoleLevel() {
      getLevel()
        .then((res) => {
          this.level = res.level;
        })
        .catch(() => {});
    },
    checkboxT(row, rowIndex) {
      return row.id !== this.user.id;
    },
    changeCompany(e) {
      //**切换公司查看 */
      service.defaults.headers["corpId"] = e;
      this.getDeptDatas();
      this.crud.toQuery();
    },
    allAddRole() {
      this.allRoles = [];
      //获取所有角色
      if (this.crud.selections.length > 0) {
        //this.getRoles();
        this.getDepts();
        this.getRoles();
        this.getRoleLevel();
        this.allRolesDialog = true;
      }
    },
    setRoleUser(e) {
      // this.getDepts();
      // this.getRoles();
      // this.getRoleLevel();
      // console.log(e)
      this.allRoles = [];
      this.crud.selections = [e];
      e.roles.forEach((element) => {
        this.allRoles.push(element.id);
      });
      //this.allRoles=e.roles

      this.allRolesDialog = true;
      if (this.crud.selections.length > 0) {
        //this.getRoles();
        this.getDepts();
        this.getRoles();
        this.getRoleLevel();
        this.allRolesDialog = true;
      }
    },
    changeRoleToUser() {
      console.log(this.allRoles);
    },
    saveRolesToUser() {
      //**批量保存分配角色 */

      let obj = {
        users: [],
        roles: [],
      };
      this.allRoles.forEach((element) => {
        obj.roles.push(element);
      });
      this.crud.selections.forEach((element) => {
        obj.users.push(element.id);
      });

      grant(obj).then((res) => {
        if (res.code == 200) {
          this.crud.notify("角色设置成功", CRUD.NOTIFICATION_TYPE.SUCCESS);
          //刷新页面数据
          this.crud.toQuery();
          this.allRolesDialog = false;
        } else {
          this.crud.notify(res.data.message, CRUD.NOTIFICATION_TYPE.SUCCESS);
        }
      });
      // console.log(obj);
    },
  },
};
</script>

<style scoped></style>
