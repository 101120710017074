<!-- 标签预览 -->
<template>
  <div class="labelView main">
    <el-form :model="formData" :inline="true">
      <el-form-item>
        <el-date-picker
          v-model="formData.nowRulesDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入内容"
          v-model="formData[select]"
          class="input-with-select"
        >
          <el-select
            v-model="select"
            slot="prepend"
            placeholder="请选择"
            style="width: 150px"
            @change="
              formData.userName = '';
              formData.phone = '';
            "
          >
            <el-option label="用户名称" value="userName"></el-option>
            <el-option label="手机号" value="phone"></el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <!-- <el-button type="text" @click="drawer = true">更多筛选</el-button> -->
      </el-form-item>
    </el-form>
    <div style="margin-bottom: 10px">
      <el-link
        :href="scrmUrl + '/memberManagement/addCustomerGroup?title=新建群发'"
      >
        <el-button type="primary">企微群发</el-button>
      </el-link>
    </div>
    <el-s-table :data="tableData" :columns="columns" border>
      <template v-slot:avatarUrl="{ row }">
        <el-avatar
          shape="square"
          size="medium"
          :src="row.avatarUrl"
        ></el-avatar>
      </template>
      <template v-slot:tagList="{ row }">
        <el-tag
          v-for="(item, index) in row.tagList"
          :key="index"
          type="danger"
          effect="dark"
        >
          {{ item }}
        </el-tag>
      </template>
    </el-s-table>
    <div class="pagebox">
      <el-pagination
        @size-change="formHandleSizeChange"
        @current-change="formHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
        background
      >
      </el-pagination>
    </div>
    <!-- <el-drawer title="更多筛选" :visible.sync="drawer" :with-header="true">
      <div class="demo-drawer__content">
        <el-form :model="formData">
          <el-form-item label="添加时间：">
            <el-date-picker
              v-model="formData.nowRulesDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字查询：">
            <el-input
              placeholder="请输入内容"
              v-model="formData[select]"
              class="input-with-select"
            >
              <el-select
                v-model="select"
                slot="prepend"
                placeholder="请选择"
                style="width: 150px"
                @change="
                  formData.userName = '';
                  formData.phone = '';
                "
              >
                <el-option label="用户名称" value="userName"></el-option>
                <el-option label="手机号" value="phone"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="标签：">
            <el-button type="text" @click="visibleDialog = true"
              >选择标签</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button @click="drawer = false">取消</el-button>
        <el-button type="primary" @click="handleSearch">确定</el-button>
      </div>
    </el-drawer> -->
    <el-dialog
      title=""
      :visible.sync="visibleDialog"
      top="10vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="order">
        <div class="btn_top">
          <el-form :inline="true">
            <el-form-item>
              <el-date-picker
                v-model="date"
                type="daterange"
                range-separator="至"
                start-placeholder="创建开始时间"
                end-placeholder="创建结束时间"
                unlink-panels
                value-format="yyyy-MM-dd"
              />
            </el-form-item>

            <el-form-item>
              <el-select
                v-model="ruleForm.createWay"
                placeholder="请选择创建方式"
                clearable
                style="width: 200px"
              >
                <el-option label="自定义模型标签" value="0" />
                <el-option label="手动标签" value="1" />
                <el-option label="信息采集标签" value="2" />
                <el-option label="病症标签" value="3" />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-select
                v-model="ruleForm.status"
                placeholder="请选择标签状态"
                clearable
                style="width: 200px"
              >
                <el-option label="启用" :value="0" />
                <el-option label="禁用" :value="1" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchTag">查询</el-button>
            </el-form-item>

            <!-- <el-form-item>
              <filter-column :columns.sync="tagColumns" @refresh="getList" />
            </el-form-item> -->
          </el-form>
        </div>

        <div class="content_box">
          <div class="group_box">
            <div class="list_box">
              <el-tree
                :data="groupList"
                node-key="id"
                default-expand-all
                @node-drag-end="handleDragEnd"
                @node-drop="handleDrop"
                @node-click="treeNodeClick"
                draggable
                :allow-drop="allowDrop"
                :allow-drag="allowDrag"
                :props="{
                  label: 'name',
                }"
              >
              </el-tree>
            </div>
          </div>
          <div class="table_box">
            <el-s-table
              :columns="tagColumns"
              :data="tagTableData"
              border
              @selection-change="handleSelectionChange"
            >
              <template v-slot:status="{ row }">
                <div class="status">
                  <span :class="row.status == 1 ? 'waring' : ''"></span>
                  {{ !row.status ? "启用" : "禁用" }}
                </div>
              </template>
            </el-s-table>
            <div class="pagebox">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="ruleForm.page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="ruleForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                background
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button
          @click="
            visibleDialog = false;
            multipleSelection = [];
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="visibleDialog = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  newGetTagGroup,
  getTagList,
  changeSort,
} from "@/api/labelManagement/index";
import { getTagInfoPreview } from "@/api/labelManagement/newIndex";
export default {
  name: "LabelView",
  components: {
    draggable,
  },
  data() {
    return {
      formData: {
        userName: "",
        phone: "",
        pageSize: 10,
        page: 1,
        total: 0,
      },
      columns: [
        { type: "selection" },
        { prop: "userName", label: "用户名称" },
        { prop: "avatarUrl", label: "微信头像" },
        { prop: "wechatNickName", label: "微信昵称" },
        { prop: "tagList", label: "标签" },
        { prop: "createTime", label: "创建时间" },
      ],
      tableData: [],
      select: "userName",
      // 标签规则数据
      groupList: [],
      ruleForm: {
        groupId: "",
        createWay: "",
        page: 1,
        pageSize: 10,
        status: "",
      },
      date: [],
      tagColumns: [
        {
          type: "selection",
        },
        {
          prop: "tagName",
          label: "标签名称",
        },
        {
          prop: "createWayStr",
          label: "创建方式",
        },
        {
          prop: "tagTypeStr",
          label: "标签类型",
        },
        {
          prop: "status",
          label: "标签状态",
        },
        {
          prop: "coverNum",
          label: "标签覆盖人数",
        },
        {
          prop: "createDate",
          label: "创建时间",
        },
        {
          prop: "createUser",
          label: "创建人",
        },

        {
          prop: "default",
          label: "操作",
          "min-width": "140",
          fixed: "right",
        },
      ],
      tagTableData: [],
      total: 0,
      visibleDialog: false,
      drawer: false,
      multipleSelection: [],
      scrmUrl: process.env.VUE_APP_SCRM_URL,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSearch() {
      this.formData.page = 1;
      this.formData.beginDate = this.date && this.date[0] ? this.date[0] : "";
      this.formData.endDate = this.date && this.date[1] ? this.date[1] : "";
      this.getList();
      this.getTagGroup();
    },
    getList() {
      getTagInfoPreview(this.formData).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.data;
          this.formData.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    formHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.formData.page = 1;
      this.getList();
    },
    formHandleCurrentChange(val) {
      this.formData.page = val;
      this.getList();
    },
    treeNodeClick(data) {
      this.ruleForm.groupId = data.id;
      this.getList();
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      this.ruleForm.groupName = draggingNode.data.name;
      this.ruleForm.id = draggingNode.data.id;
      // this.submitGroup();
      let arr = [];
      dropNode.parent.data.children.forEach((item) => {
        arr.push(item.id);
      });
      changeSort(arr)
        .then((res) => {
          if (res.code != 200) {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log("tree drop: ", draggingNode, dropNode, dropType);
    },
    allowDrop(draggingNode, dropNode, type) {
      if (type !== "inner") {
        if (draggingNode.parent.data.id == dropNode.parent.data.id) {
          return true;
        }
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.level === 3;
      // return true
    },
    handleSizeChange(val) {
      this.ruleForm.pageSize = val;
      this.ruleForm.page = 1;
      this.getTagList();
    },
    handleCurrentChange(val) {
      this.ruleForm.page = val;
      this.getTagList();
    },
    //获取标签分组
    getTagGroup() {
      let params = {
        pageNumber: 1,
        pageSize: 100,
        associatedData: false,
      };
      newGetTagGroup(params).then((res) => {
        if (res.code == 200) {
          this.groupList = [
            ...[{ id: "", isPreset: 0, name: "全部分组" }],
            ...res.data,
          ];
        } else {
          this.$message.error(res.message);
        }
      });
    },
    searchTag(item, index) {
      this.currentIndex = index;
      this.ruleForm.page = 1;
      this.ruleForm.groupId = item ? item.id : "";
    },
    getTagList() {
      getTagList(this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.tagTableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 选择标签数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding: 0px 20px;
}
.input-with-select {
  width: 350px;
}
</style>
