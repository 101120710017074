<template>
  <div>
    <el-dialog
      title="审批进度"
      :visible.sync="visible"
      width="1000px"
      :close-on-click-modal="false"
      :close-on-escap-click="false"
    >
      <el-steps
        :active="curProgress"
        finish-status="success"
        process-status="wait"
      >
        <el-step
          v-for="(item, index) in progress"
          :key="item.id"
          :status="item.result | filterStatus(index)"
          :icon="
            item.result == '已拒绝' || curProgress - 1 >= index
              ? ''
              : 'el-icon-circle-check'
          "
        >
          <template v-slot:title>
            <div class="step">
              <span>{{ item.approvalName }}</span>
              <span class="large">{{ item.result | filterResult(index) }}</span>
            </div>
          </template>
          <template v-slot:description>
            <div class="step">
              <div>{{ item.roleName ? item.roleName.join(",") : "" }}</div>
              <div>{{ item.createTime }}</div>
            </div>
          </template>
        </el-step>
      </el-steps>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="visible = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const resultMap = {
  已同意: "success",
  已拒绝: "error",
  审核中: "process",
  暂未审核: "wait",
  已撤销: "wait",
};
import { getApplyDetail, getApplyUser } from "@/api/user/index";
export default {
  name: "ApplyProgress",
  data() {
    return {
      visible: false,
      progress: [],
    };
  },
  // 	wait / process / finish / error / success
  computed: {
    curProgress: function () {
      const index = this.progress.findIndex(
        (item) => item.result == "暂未审核"
      );
      return index >= 0 ? index : this.progress.length;
    },
  },
  filters: {
    filterStatus: (val, index) => {
      return index == 0 ? "success" : resultMap[val] || "await";
    },
    filterResult: (val, index) => {
      if (index == 0) {
        return "发起申请";
      }
      return val;
    },
  },
  methods: {
    show(row) {
      Promise.all([this.getProgress(row), this.getApplyUser(row)]).then(
        ([r1, r2]) => {
          let resultArr; // 展示的审批流程
          console.log("getProgress=", r1, "getApplyUser=", r2);
          let wholeProgress = [r2.pl, r2.pm, r2.digital]
            .filter((item) => !!item)
            .map((arr) => {
              return {
                approvalName: arr.map((item) => item.conditionInfo).join("/"),
                result: "暂未审核",
              };
            });
          const curProgressLen = r1.length;
          if (curProgressLen >= wholeProgress.length + 1) {
            resultArr = r1;
          } else if (curProgressLen == 0) {
            resultArr = wholeProgress;
          } else if (r1[curProgressLen - 1].result == "已撤销") {
            resultArr = r1;
          } else {
            resultArr = r1.concat(wholeProgress.splice(curProgressLen - 1));
          }
          this.progress = resultArr;
          this.visible = true;
        }
      );
    },
    // 获取 当前已处理进度
    getProgress(row) {
      return new Promise((resolve, reject) => {
        getApplyDetail({
          projectId: row.projectId,
          userId: row.userId,
          processId: row.processId,
        }).then((res) => {
          if (res.code == 200) {
            // this.progress = res.data
            // this.visible = true
            resolve(res.data);
            return;
          }
          this.$message.error(res.message);
          reject(res);
        });
      });
    },
    // 获取 相应审批人列表
    getApplyUser(row) {
      return new Promise((resolve, reject) => {
        getApplyUser({ projectId: row.projectId }).then((res) => {
          if (res.code == 200) {
            // console.log(res.data)
            // this.flowPathObj = res.data
            resolve(res.data);
            return;
          }
          this.$message.error(res.message);
          reject(res);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.step {
  color: #333;
  font-size: 12px;
  .large {
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
  }
}
.is-wait .step {
  color: #c0c4cc;
}
:deep(.el-step__icon.is-icon) {
  width: initial;
}
</style>
