var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rfm" }, [
    _c("div", { staticClass: "rfm-head" }, [_vm._v(_vm._s(_vm.rfmData.title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "rfm-content" },
      [
        _c(
          "div",
          { staticClass: "rfm-value-type" },
          [
            _c("span", [_vm._v("取值方式：")]),
            _vm._v(" "),
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.judgeValueType,
                  callback: function ($$v) {
                    _vm.judgeValueType = $$v
                  },
                  expression: "judgeValueType",
                },
              },
              [
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("原值")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("按区间赋值")]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.judgeValueType == 1
          ? _c(
              "el-table",
              { attrs: { border: "", data: _vm.interval } },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "区间" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.$index ||
                            scope.$index == _vm.interval.length - 1
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.rfmData.title.substring(0, 1))
                                  ),
                                ])
                              : _c("el-input", {
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    placeholder: "默认等于上一级天数",
                                    debounce: "500",
                                    disabled: true,
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.rangeStartInput(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.rangeStart,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "rangeStart", $$v)
                                    },
                                    expression: "scope.row.rangeStart",
                                  },
                                }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  !scope.$index
                                    ? "<"
                                    : scope.$index == _vm.interval.length - 1
                                    ? "≥"
                                    : " ≤ " +
                                      _vm.rfmData.title.substring(0, 1) +
                                      " < "
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "150px" },
                              attrs: {
                                type: "text",
                                placeholder: "输入框",
                                disabled:
                                  (scope.$index != 0 &&
                                    !_vm.interval[Number(scope.$index) - 1]
                                      .rangeEnd) ||
                                  _vm.interval.length - 1 ==
                                    Number(scope.$index),
                              },
                              on: {
                                blur: function ($event) {
                                  _vm.inputValFun(
                                    scope.row,
                                    Number(scope.$index)
                                  )
                                },
                              },
                              model: {
                                value: scope.row.rangeEnd,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "rangeEnd", $$v)
                                },
                                expression: "scope.row.rangeEnd",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.rfmData.units) + "，计"),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "150px" },
                              attrs: {
                                type: "text",
                                placeholder: "输入框",
                                debounce: "500",
                              },
                              on: {
                                input: function ($event) {
                                  scope.row.rangeValue = scope.row.rangeValue
                                    .toString()
                                    .replace(
                                      /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                                      "$1"
                                    )
                                },
                              },
                              model: {
                                value: scope.row.rangeValue,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "rangeValue", $$v)
                                },
                                expression: "scope.row.rangeValue",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("分")]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    794520753
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.$index == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      size: "small",
                                      disabled: !scope.row.rangeEnd,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addRange(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("新增区间")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.$index != 0 &&
                            scope.$index < _vm.interval.length - 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        _vm.deleteRange(Number(scope.$index))
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2339263676
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "rfm-value-type" }, [
          _c("span", [_vm._v("计算规则：")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function ($event) {
                      _vm.customComparison = ""
                    },
                  },
                  model: {
                    value: _vm.resultType,
                    callback: function ($$v) {
                      _vm.resultType = $$v
                    },
                    expression: "resultType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("平均值")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("自定义")]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "rules" }, [
                _vm.resultType == "0"
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          "当 " +
                            _vm.rfmData.title.substring(0, 1) +
                            "  " +
                            (_vm.rfmData.title.substring(0, 1) == "R"
                              ? "≤"
                              : "≥") +
                            " 平均值时为"
                        ) + "\n            "
                      ),
                      _c("span", { staticClass: "a1ffad" }, [_vm._v("高")]),
                      _vm._v(" 价值客户"),
                    ])
                  : _c(
                      "span",
                      [
                        _vm._v(
                          _vm._s(
                            "当 " +
                              _vm.rfmData.title.substring(0, 1) +
                              "  " +
                              (_vm.rfmData.title.substring(0, 1) == "R"
                                ? "≤"
                                : "≥")
                          )
                        ),
                        _c("el-input", {
                          staticStyle: {
                            display: "inline-block",
                            margin: "0 5px",
                            width: "100px",
                          },
                          attrs: { type: "text", debounce: "500" },
                          on: { input: _vm.customComparisonInput },
                          model: {
                            value: _vm.customComparison,
                            callback: function ($$v) {
                              _vm.customComparison = $$v
                            },
                            expression: "customComparison",
                          },
                        }),
                        _vm._v("时为 "),
                        _c("span", { staticClass: "a1ffad" }, [_vm._v("高")]),
                        _vm._v(" 价值客户"),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }