import request from "@/utils/request";
// console.log(request)
export function getCurriculums(data) {
  //获取课程列表、查询列表
  return request({
    url: "/curriculum/pc/curriculum/getCurriculums",
    method: "get",
    params: data,
  });
}
export function getCurriculumsForCoupon(data) {
  //获取付费课程列表
  return request({
    url: "/curriculum/pc/curriculum/getCurriculumsForCoupon",
    method: "get",
    params: data,
  });
}
export function getCurriculumDetail(data) {
  //获取课程详情
  return request({
    //url: '/curriculum/pc/curriculum/getCurriculumDetail',
    url: "/curriculum/pc/curriculum/getCurriculumEditDetail",
    method: "get",
    params: data,
  });
}
export function removeCurriculum(data) {
  //删除课程
  return request({
    url: "/curriculum/pc/curriculum/removeCurriculum",
    method: "get",
    params: data,
  });
}
export function editCurriculum(data) {
  //编辑课程
  return request({
    url: "/curriculum/pc/curriculum/editCurriculum",
    method: "post",
    data,
  });
}
export function updateCurriculum(data) {
  //批量修改课程
  return request({
    url: "/curriculum/pc/curriculum/updateCurriculum",
    method: "get",
    params: data,
  });
}
export function addCurriculum(data) {
  //添加课程
  return request({
    url: "/curriculum/pc/curriculum/addCurriculum",
    method: "post",
    data,
  });
}
export function getCurriculumTypes(data) {
  //获取平台分类
  return request({
    url: "/curriculum/pc/curriculum/getCurriculumTypes",
    method: "get",
    params: data,
  });
}
export function getShopCurriculumType(data) {
  //获取课程分类列表
  return request({
    url: "/curriculum/pc/shopCurriculumType/getShopCurriculumType",
    method: "get",
    params: data,
  });
}
export function getCurriculumContents(data) {
  //获取内容分类
  return request({
    url: "/curriculum/pc/content/getCurriculumContents",
    method: "get",
    params: data,
  });
}
export function contetOperation(data) {
  //获取内容
  return request({
    url: "/curriculum/pc/curriculum/contetOperation",
    method: "post",
    data,
  });
}
export function getCurriculumSystemList(data) {
  //获取课程体系
  return request({
    url: "/curriculum/pc/admin/getCurriculumSystemList",
    method: "get",
    params: data,
  });
}
// export function getTeacherList(data) { //获取老师列表
//   return request({
//     url: '/curriculum/teacher/getTeacherList',
//     method: 'get',
//     params:data
//   })
// }
export function getTeacherList(data) {
  //获取老师列表
  return request({
    url: "/curriculum/pc/teacher/getTeacher",
    method: "get",
    params: data,
  });
}

export function addShopCurriculumType(data) {
  //添加店铺课程分类
  return request({
    url: "/curriculum/pc/shopCurriculumType/addShopCurriculumType",
    method: "post",
    data,
  });
}
export function removeShopCurriculumType(data) {
  //删除店铺课程分类
  return request({
    url: "/curriculum/pc/shopCurriculumType/removeShopCurriculumType",
    method: "get",
    params: data,
  });
}
export function editShopCurriculumType(data) {
  //编辑店铺课程分类
  return request({
    url: "/curriculum/pc/shopCurriculumType/editShopCurriculumType",
    method: "post",
    data,
  });
}
export function getLabelList(data) {
  //获取标签列表
  return request({
    url: "/curriculum/pc/label/getLabelList",
    method: "get",
    params: data,
  });
}
export function editLabelJoin(data) {
  //设置标签
  return request({
    url: "/curriculum/pc/label/editLabelJoin",
    method: "post",
    data,
  });
}
export function addLabelJoin(data) {
  //设置标签
  return request({
    url: "/curriculum/pc/label/addLabelJoin",
    method: "post",
    data,
  });
}
export function curriculumShelf(data) {
  //课程上下架统一接口
  return request({
    url: "/curriculum/pc/curriculum/curriculumShelf",
    method: "post",
    data,
  });
}

export function getContentJoin(data) {
  //课程下所关联的内容
  return request({
    url: "/curriculum/pc/curriculum/getContentJoin",
    method: "get",
    params: data,
  });
}

export function getCurriculumDetailJoinContent(data) {
  //获取课程详情
  return request({
    url: "/curriculum/pc/curriculum/getCurriculumDetail",
    //url:'/curriculum/pc/curriculum/getCurriculumEditDetail',
    method: "get",
    params: data,
  });
}
export function setContents(data) {
  //设置内容
  return request({
    url: "/curriculum/pc/curriculum/setContents",
    //url:'/curriculum/pc/curriculum/getCurriculumEditDetail',
    method: "get",
    params: data,
  });
}
export function getCurriculmQrcode(data) {
  //获取分享二维码
  return request({
    url: "/curriculum/pc/curriculum/getCurriculmQrcode",
    method: "get",
    params: data,
  });
}

export function getSystemCurriculums(data) {
  //系统学习课下关联的课程
  return request({
    url: "/curriculum/pc/curriculum/getSystemCurriculums",
    method: "get",
    params: data,
  });
}
export function currDetailsUserList(data) {
  //系统学习课下关联的课程
  return request({
    url: "/curriculum/pc/curriculum/currDetailsUserList",
    method: "get",
    params: data,
  });
}
export function removeSystemCurriculum(data) {
  //移除系统学习课下关联的课程
  return request({
    url: "/curriculum/pc/curriculum/removeSystemCurriculum",
    method: "get",
    params: data,
  });
}
export function systemCurriculumsSort(data) {
  //移除系统学习课下关联的课程
  return request({
    url: "/curriculum/pc/curriculum/systemCurriculumsSort",
    method: "get",
    params: data,
  });
}
export function getCurrLevel(data) {
  //移除系统学习课下关联的课程
  return request({
    url: "/curriculum/pc/admin/getCurrLevel",
    method: "get",
    params: data,
  });
}

//专栏详情设置解锁时间
export function setUnlock(data) {
  //移除系统学习课下关联的课程
  return request({
    url: "/curriculum/pc/curriculum/setUnlock",
    method: "post",
    data,
  });
}
export function exportTemplete(data) {
  //移除系统学习课下关联的课程
  return request({
    url: "/curriculum/pc/curriculum/exportTemplete",
    method: "get",
    params: data,
  });
}

export function exportUser(data) {
  //移除系统学习课下关联的课程
  return request({
    url: "/curriculum/pc/curriculum/getImportUserList",
    method: "get",
    params: data,
  });
}
export function getImportUserRecord(data) {
  //查询通过用户ID导入课程用户的导入记录
  return request({
    url: "/curriculum/pc/importUser/getImportRecord",
    method: "get",
    params: data,
  });
}
export function delImportCurriculumUser(data) {
  //移除系统学习课下关联的课程
  return request({
    url: "/curriculum/pc/curriculum/delImportCurriculumUser",
    method: "get",
    params: data,
  });
}
export function downCurrDetailsUserList(data) {
  //导出课程详情下的用户
  return request({
    url: "/curriculum/pc/curriculum/downCurrDetailsUserList",
    method: "get",
    params: data,
  });
}
export function delExportUser(data) {
  //导出课程详情下的用户
  return request({
    url: "/curriculum/pc/curriculum/batchDelImportCurriculumUser",
    method: "post",
    data,
  });
}
export function getCurriculumTotal(data) {
  //获取课程数据统计
  return request({
    url: "/curriculum/pc/curriculum/getCurriculumTotal",
    method: "get",
    params: data,
  });
}

export function classHoursStatistics(data) {
  //获取课程数据统计
  return request({
    url: "/curriculum/pc/curriculum/classHoursStatistics",
    method: "get",
    params: data,
  });
}
// export function importSigleUserByCode(data) {
//   //单个导入学员名单
//   return request({
//     url: "/curriculum/pc/curriculum/importSigleUserByCode",
//     method: "post",
//     data,
//   });
// }

// export function importSignByUserId(data) {
//   //单个导入学员名单 根据用户ID
//   return request({
//     url: "/curriculum/pc/importUser/importSignByUserId",
//     method: "post",
//     data,
//   });
// }

// 课程详情导入会员
export const importUserSingle = (data) => {
  return request({
    url: "/curriculum/pc/importUser/importSingle",
    method: "post",
    data,
  });
};

// 会员批量导入课程
export const importBatchCourse = (data) => {
  return request({
    url: "/curriculum/pc/importUser/importBatchCourse",
    method: "post",
    data,
  });
};

// 获取课程短链接
export const getShortCurriculumLink = (data) => {
  return request({
    url: "/common-server/pc/sortServer/getSortLink",
    method: "post",
    data,
  });
};

// 添加课程卡片
export const addCourseCard = (data) => {
  return request({
    url: "/curriculum/pc/courseCard/addCard",
    method: "post",
    data,
  });
};

// 获取老师和助教姓名
export const getCourseCardSenders = (params) => {
  return request({
    url: "/curriculum/pc/courseCard/getTeacherList",
    method: "get",
    params,
  });
};

// 发送课程发片
export const sendCourseCardToLive = (data) => {
  return request({
    url: "/curriculum/pc/courseCard/sendCardToLive",
    method: "post",
    data,
  });
};

// // 直播二维码生成   ​/pc​/content​/generateLiveImg
// export function generateLiveImg(data) { //获取内容分类
//   return request({
//     url: '/curriculum/pc/content/generateLiveImg',
//     method: 'get',
//     params:data
//   })
// }

// 查询课程内容是否有学习记录 课程编辑内容移除时判断 有则不能移除
export const getIsDeleteCurrContent = (data) => {
  return request({
    url: "/curriculum/pc/content/getIsDeleteCurrContent",
    method: "get",
    params: data,
  });
};

// 同步订单
export function syncUserByCourseCode(data) {
  return request({
    url: "/curriculum/pc/curriculum/syncUserByCourseCode",
    method: "get",
    params: data,
  });
}

// 获取手机号的国家区号
export function getMobileAreaCode(params) {
  return request({
    url: "/user/pc/country/getCode",
    method: "get",
    params,
  });
}

//课程管理——课程详情—— 内容（显示、隐藏）
export function getIsShowCurrContent(params) {
  return request({
    url: "/curriculum/pc/content/getIsShowCurrContent",
    method: "get",
    params,
  });
}

//设置内容有效时长
export function addvalidDateContentCode(params) {
  return request({
    url: "/curriculum/pc/content/addvalidDateContentCode",
    method: "get",
    params,
  });
}

// 新建直播-选择直播-小鹅通直播列表
export const getXETLivList = (params) => {
  return request({
    url: "/curriculum/pc/xe/getXiaoELiveList",
    method: "get",
    params,
  });
};

// 获取小鹅通课程列表
export const getXETCourseList = (params) => {
  return request({
    url: "/curriculum/pc/xe/get-xe-goods",
    method: "get",
    params,
  });
};

// 获取小鹅通已同步课程
export const getXETSynchronizedCourse = (params) => {
  return request({
    url: "/curriculum/pc/xe/get-sync-record",
    method: "get",
    params,
  });
};

// 同步报名提交
export const postXETCourseSync = (params) => {
  return request({
    url: "/curriculum/pc/xe/sync-delivery",
    method: "get",
    params,
  });
};

// 通知后台同步小鹅通数据
export const notifySyncXET = (params) => {
  return request({
    url: "/curriculum/pc/xe/sync-xe-live-user-record",
    method: "get",
    params,
  });
};
//打开关闭课程延期
export function updateIsDelay(params) {
  return request({
    url: "/curriculum/pc/curriculum/updateIsDelay",
    method: "get",
    params,
  });
}

// 关闭听课权限
export function closeCurr(data) {
  return request({
    url: "/curriculum/pc/curriculum/close-curr",
    method: "post",
    data,
  });
}

// 关闭指定用户下的课程听课权限
export function closeUserCurr(data) {
  return request({
    url: "/curriculum/pc/curriculum/close-user-curr",
    method: "post",
    data,
  });
}

// 批量延期 curriculumUserIds
export function batchUpdateIsDelay(data) {
  return request({
    url: "/curriculum/pc/curriculum/batchUpdateIsDelay",
    method: "post",
    data,
  });
}
// 作废会员导入记录 isDeleteImportRecord
export function isDeleteImportRecord(params) {
  return request({
    url: "/curriculum/pc/importUser/isDeleteImportRecord",
    method: "get",
    params,
  });
}
// 设置内容排序
export function setContentSort(params) {
  return request({
    url: "/curriculum/pc/curriculum/setContentSort",
    method: "get",
    params,
  });
}

// 一键关闭课程
export function allClose(params) {
  return request({
    url: "/curriculum/pc/curriculum/allClose",
    method: "get",
    params,
  });
}
