import request from "@/utils/request";

// 获取accessTicket
export function getAccessTicket(data) {
  return request({
    url: "/cdp/pc/bi",
    method: "get",
    params: data,
  });
}

// 删除accessTicket
export function delAccessTicket(data) {
  return request({
    url: "/cdp/pc/bi",
    method: "delete",
    params: data,
  });
}

// 添加
export function biAdd(data) {
  return request({
    url: "/cdp/pc/bi/add",
    method: "post",
    data,
  });
}

// 编辑
export function biUpdate(data) {
  return request({
    url: "/cdp/pc/bi/update",
    method: "post",
    data,
  });
}

// 列表
export function biList(data) {
  return request({
    url: "/cdp/pc/bi/list",
    method: "get",
    params: data,
  });
}
