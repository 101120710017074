var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "head-container" },
        [
          _vm.crud.props.searchToggle
            ? _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "输入名称或者描述搜索",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.crud.toQuery($event)
                      },
                    },
                    model: {
                      value: _vm.query.blurry,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "blurry", $$v)
                      },
                      expression: "query.blurry",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticClass: "date-item",
                    attrs: {
                      "default-time": ["00:00:00", "23:59:59"],
                      type: "daterange",
                      "range-separator": ":",
                      size: "small",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.query.createTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "createTime", $$v)
                      },
                      expression: "query.createTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("rrOperation", { attrs: { crud: _vm.crud } }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("crudOperation", { attrs: { permission: _vm.permission } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.crud.cancelCU,
            visible: _vm.crud.status.cu > 0,
            title: _vm.crud.status.title,
            width: "520px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.crud.status, "cu > 0", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: true,
                model: _vm.form,
                rules: _vm.rules,
                size: "small",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "145px" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角色权限", prop: "permission" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "145px" },
                    model: {
                      value: _vm.form.permission,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "permission", $$v)
                      },
                      expression: "form.permission",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "数据范围", prop: "dataScope" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "145px" },
                      attrs: { placeholder: "请选择数据范围" },
                      on: { change: _vm.changeScope },
                      model: {
                        value: _vm.form.dataScope,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dataScope", $$v)
                        },
                        expression: "form.dataScope",
                      },
                    },
                    _vm._l(_vm.dateScopes, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角色级别", prop: "level" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "145px" },
                    attrs: { min: 1, "controls-position": "right" },
                    model: {
                      value: _vm.form.level,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "level", _vm._n($$v))
                      },
                      expression: "form.level",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.dataScope === "自定义"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "数据权限", prop: "depts" } },
                    [
                      _c("treeselect", {
                        staticStyle: { width: "380px" },
                        attrs: {
                          options: _vm.depts,
                          multiple: "",
                          placeholder: "请选择",
                        },
                        model: {
                          value: _vm.form.depts,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "depts", $$v)
                          },
                          expression: "form.depts",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "描述信息", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "380px" },
                    attrs: { rows: "5", type: "textarea" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.crud.cancelCU } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.crud.status.cu === 2, type: "primary" },
                  on: { click: _vm.crud.submitCU },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { xs: 24, sm: 24, md: 16, lg: 16, xl: 17 },
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "never" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", { staticClass: "role-span" }, [
                        _vm._v("角色列表"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.crud.loading,
                          expression: "crud.loading",
                        },
                      ],
                      ref: "table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "highlight-current-row": "",
                        data: _vm.crud.data,
                      },
                      on: {
                        "selection-change": _vm.crud.selectionChangeHandler,
                        "current-change": _vm.handleCurrentChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          selectable: _vm.checkboxT,
                          type: "selection",
                          width: "55",
                        },
                      }),
                      _vm._v(" "),
                      _vm.columns.visible("name")
                        ? _c("el-table-column", {
                            attrs: { prop: "name", label: "名称" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columns.visible("dataScope")
                        ? _c("el-table-column", {
                            attrs: { prop: "dataScope", label: "数据权限" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columns.visible("permission")
                        ? _c("el-table-column", {
                            attrs: { prop: "permission", label: "角色权限" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columns.visible("level")
                        ? _c("el-table-column", {
                            attrs: { prop: "level", label: "角色级别" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columns.visible("remark")
                        ? _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": true,
                              prop: "remark",
                              label: "描述",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columns.visible("createTime")
                        ? _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": true,
                              width: "135px",
                              prop: "createTime",
                              label: "创建日期",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.parseTime(scope.row.createTime)
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3078210614
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "roles:edit", "roles:del"],
                            expression: "['admin', 'roles:edit', 'roles:del']",
                          },
                        ],
                        attrs: {
                          label: "操作",
                          width: "130px",
                          align: "center",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.level >= _vm.level
                                  ? _c("udOperation", {
                                      attrs: {
                                        data: scope.row,
                                        permission: _vm.permission,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination"),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 8, lg: 8, xl: 7 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "never" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "选择指定角色分配菜单",
                            placement: "top",
                          },
                        },
                        [
                          _c("span", { staticClass: "role-span" }, [
                            _vm._v("菜单分配"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "roles:edit"],
                              expression: "['admin', 'roles:edit']",
                            },
                          ],
                          staticStyle: { float: "right", padding: "6px 9px" },
                          attrs: {
                            disabled: !_vm.showButton,
                            loading: _vm.menuLoading,
                            icon: "el-icon-check",
                            size: "mini",
                            type: "primary",
                          },
                          on: { click: _vm.saveMenu },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-tree", {
                    ref: "menu",
                    attrs: {
                      data: _vm.menus,
                      "default-checked-keys": _vm.menuIds,
                      props: _vm.defaultProps,
                      "show-checkbox": "",
                      "node-key": "id",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }