var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c(
        "el-button",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: _vm.permission.edit,
              expression: "permission.edit",
            },
          ],
          attrs: {
            loading: _vm.crud.status.cu === 2,
            disabled: _vm.disabledEdit,
            size: "mini",
            type: "text",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.crud.toEdit(_vm.data)
            },
          },
        },
        [_vm._v("编辑")]
      ),
      _vm._v(" "),
      _c("p", [_vm._v("|")]),
      _vm._v(" "),
      _c(
        "el-popover",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: _vm.permission.del,
              expression: "permission.del",
            },
          ],
          attrs: { placement: "top", width: "180", trigger: "manual" },
          on: { show: _vm.onPopoverShow, hide: _vm.onPopoverHide },
          model: {
            value: _vm.pop,
            callback: function ($$v) {
              _vm.pop = $$v
            },
            expression: "pop",
          },
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.msg))]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right", margin: "0" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "text" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.doCancel($event)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.crud.dataStatus[_vm.data.id].delete === 2,
                    type: "text",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.crud.doDelete(_vm.data)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                slot: "reference",
                disabled: _vm.disabledDle,
                type: "text",
                size: "mini",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toDelete($event)
                },
              },
              slot: "reference",
            },
            [_vm._v("删除")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [_vm._v("|")]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: _vm.permission.toSet,
              expression: "permission.toSet",
            },
          ],
          attrs: { type: "text", size: "mini" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.crud.toSet(_vm.data.id)
            },
          },
        },
        [_vm._t("btn3")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }