var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details-box" },
    [
      _c("div", { staticClass: "flex flex-col items-center mb-10" }, [
        _c("div", { staticClass: "mb-5" }, [
          _c("img", {
            staticClass: "w-24 h-24 rounded-full",
            attrs: { src: _vm.userInfo.userImg },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-lg text-sky-400" }, [
          _vm._v(_vm._s(_vm.userInfo.userNickName)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-descriptions",
        {
          attrs: {
            column: 4,
            colon: false,
            size: "medium",
            labelClassName: "item-label",
            contentClassName: "item-content",
          },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "微信号" } }, [
            _vm._v(_vm._s(_vm.userInfo.userWechatId)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "姓名" } }, [
            _vm._v(_vm._s(_vm.userInfo.userName)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "性别" } }, [
            _vm._v(_vm._s(_vm.enumSex[_vm.userInfo.userSex])),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
            _vm._v(_vm._s(_vm.enumAge[_vm.userInfo.userAge])),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "生日" } }, [
            _vm._v(_vm._s(_vm.userInfo.userBrithday)),
          ]),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            { attrs: { label: "绑定手机号" } },
            [
              _c("DecryptPhone", {
                key: "DecryptPhone userPhone" + _vm.userInfo.id,
                attrs: {
                  phone: _vm.userInfo.userPhone,
                  encryptPhone: _vm.userInfo.encryptionUserPhone,
                },
                on: {
                  "update:phone": function ($event) {
                    return _vm.$set(_vm.userInfo, "userPhone", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            { attrs: { label: "采集手机号" } },
            [
              _c("DecryptPhone", {
                key: "DecryptPhone userCollectPhone" + _vm.userInfo.id,
                attrs: {
                  phone: _vm.userInfo.userCollectPhone,
                  encryptPhone: _vm.userInfo.encryptionUserCollectPhone,
                },
                on: {
                  "update:phone": function ($event) {
                    return _vm.$set(_vm.userInfo, "userCollectPhone", $event)
                  },
                },
              }),
              _vm._v(" "),
              _vm.userInfo.userCollectPhone
                ? _c("span", { staticClass: "item-tip" }, [_vm._v("最新")])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "行业" } }, [
            _vm._v(_vm._s(_vm.userInfo.userTrade)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "职业" } }, [
            _vm._v(_vm._s(_vm.userInfo.userProfession)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "月收入" } }, [
            _vm._v(_vm._s(_vm.enumSalary[_vm.userInfo.userMonthlyIncome])),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "身份证" } }, [
            _vm._v(_vm._s(_vm.userInfo.userIdNumber)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "健康状态" } }, [
            _vm._v(_vm._s(_vm.userInfo.healthStatus)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "家庭成员" } }, [
            _vm._v(_vm._s(_vm.userInfo.familyMembers)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "民族" } }, [
            _vm._v(_vm._s(_vm.userInfo.familialClan)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "病症" } }, [
            _vm._v(_vm._s(_vm.userInfo.userDisease)),
          ]),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            { attrs: { label: "收货人手机号" } },
            [
              _c("DecryptPhone", {
                key: "DecryptPhone consigneePhone" + _vm.userInfo.id,
                attrs: {
                  phone: _vm.userInfo.consigneePhone,
                  encryptPhone: _vm.userInfo.encryptionConsigneePhone,
                },
                on: {
                  "update:phone": function ($event) {
                    return _vm.$set(_vm.userInfo, "consigneePhone", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "地区" } }, [
            _vm._v(_vm._s(_vm.userInfo.userArea)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "收货地址" } }, [
            _vm._v(_vm._s(_vm.userInfo.userAddress) + "\n    "),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "手机型号" } }, [
            _vm._v(_vm._s(_vm.userInfo.userPhoneVerison) + "\n    "),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "微信版本" } }, [
            _vm._v(_vm._s(_vm.userInfo.userWechatVersion) + "\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }