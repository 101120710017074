<template>
  <div class="main" id="main">
    <el-container>
      <el-aside width="300px">
        <div class="backRouter" @click="backRouter">
          <i class="el-icon-arrow-left"></i>
          <span>返回</span>
        </div>
        <el-tree
          :data="treeData"
          :props="defaultProps"
          node-key="id"
          :default-expanded-keys="defaultExpandedKeys"
          :default-checked-keys="defaultCheckedKeys"
          :highlight-current="true"
          accordion
          @node-click="handleNodeClick"
        >
          <!-- <span class="span-ellipsis" slot-scope="{ node, data }">
            <i v-if="data.$treeNodeId == 1" class="el-icon-folder"></i>
            <i v-else class="el-icon-discount"></i>
            <span :title="node.label">{{ node.label }}</span>
          </span> -->
        </el-tree>
      </el-aside>
      <el-container>
        <el-header>
          <div class="groupTags">
            <h4>{{ labelDetail.tagName }}</h4>
            <div>
              <el-form :inline="true" class="form_box">
                <el-form-item label="标签状态：">
                  <div class="status">
                    <span
                      :class="labelDetail.status == 1 ? 'waring' : ''"
                    ></span>
                    {{ !labelDetail.status ? "启用" : "禁用" }}
                  </div>
                </el-form-item>
                <el-form-item label="标签值个数："
                  ><span style="color: #5b8ff9d9">{{
                    labelDetail.valueYLayout
                      ? labelDetail.valueYLayout.length
                      : ""
                  }}</span></el-form-item
                >
                <el-form-item label="标签覆盖人数："
                  ><span>{{ labelDetail.coverNum }}</span></el-form-item
                >
                <!-- <el-form-item label="创建方式："
                  ><span>{{
                    // labelDetail.createWay == 1 ? "手动标签" : labelDetail.createWay == 2 ? "自定义规则标签" : "病症标签"
                    createWayStr[labelDetail.createWay]
                  }}</span></el-form-item
                > -->
                <el-form-item label="创建时间："
                  ><span>{{ labelDetail.createDate }}</span></el-form-item
                >
              </el-form>
            </div>
          </div>
        </el-header>
        <el-main>
          <div class="labelRules">
            <h4>RFM人数分布</h4>
            <el-s-table :data="rData" :columns="rColumns"></el-s-table>
            <el-s-table :data="fData" :columns="fColumns"></el-s-table>
            <el-s-table :data="mData" :columns="mColumns"></el-s-table>
          </div>
        </el-main>
        <el-footer>
          <div class="echartBox">
            <h4>标签值覆盖分布</h4>
            <div class="crosswiseBar">
              <p>标签值覆盖分布（总人数）</p>
              <div class="barVueBox">
                <bar-vue
                  :nowOptions="crosswiseBarOptions"
                  :barId="'crosswiseBarId'"
                ></bar-vue>
              </div>
            </div>
            <div class="stackBar">
              <p>标签历史详情</p>
              <div class="barVueBox">
                <bar-vue
                  :nowOptions="stackBarOptions"
                  :barId="'stackBarId'"
                ></bar-vue>
              </div>
            </div>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import barVue from "@/components/echartsModel/bar.vue";
import {
  getDetails,
  // getTagGroup,
  // getTagList,
  getGroupTree,
} from "@/api/labelManagement/index";
export default {
  name: "LabelManageMentDetail",
  components: {
    barVue,
  },
  data() {
    return {
      treeData: [],
      labelList: [],
      defaultCheckedKeys: [],
      defaultExpandedKeys: [],
      labelDetail: {
        cdpTagValues: [
          {
            configJson: "",
            createDate: "",
            createId: 0,
            createUser: "",
            event: {
              defaultFieldConfig: "",
              defaultResultRule: 0,
              eventName: "",
              id: 0,
              isDelete: 0,
              positionsTable: "",
            },
            id: 0,
            isDelete: 0,
            remark: "",
            rules: {
              attribute: "",
              execScript: "",
              id: 0,
              isDelete: 0,
              scope: 0,
            },
            sort: 0,
            tagId: 0,
            taskJson: "",
            updateDate: "",
            updateId: 0,
            updateUser: "",
            valueName: "",
          },
        ],
        coverNum: 0,
        createWay: 0,
        dateLayout: [{}],
        executeWay: 0,
        id: 0,
        status: 0,
        tagGroupId: 0,
        tagName: "",
        tagType: 0,
        timingProperty: "",
        timingWay: 0,
        valueLayout: [{}],
      },
      defaultProps: {
        label: "name",
      },
      crosswiseBarOptions: {
        title: {
          // text: "Weather Statistics",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        // legend: {
        //   data: ["City Alpha", "City Beta", "City Gamma"],
        // },
        grid: {
          left: 100,
        },
        // toolbox: {
        //   show: true,
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        xAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },
        yAxis: {
          type: "category",
          data: [],
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
          // axisLabel: {
          //   interval: 0,
          //   rotate: 45,
          // },
          // inverse: true,
          // axisLabel: {
          //   formatter: function (value) { //自定义右侧数据
          //     return "{" + value + "| }\n{value|" + value + "}";
          //   },
          //   margin: 20,
          //   rich: {
          //     value: {
          //       lineHeight: 30,
          //       align: "center",
          //     },
          //   },
          // },
        },
        series: [],
      },
      stackBarOptions: {
        legend: {
          //图例组件
          left: "right",
          top: "top",
          data: [],
        },
        grid: {
          //直角坐标系绘制网格
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [],
      },
      // createWayStr: {
      //   0: "自定义模型标签",
      //   1: "手动标签",
      //   2: "信息采集标签",
      //   3: "病症标签",
      // },
      rColumns: [
        { props: "", label: "R值" },
        { props: "", label: "R<xx" },
        { props: "", label: "xx<=R<=xx" },
      ],
      fColumns: [
        { props: "", label: "M值" },
        { props: "", label: "M<xx" },
        { props: "", label: "xx<=M<=xx" },
      ],
      mColumns: [
        { props: "", label: "F值" },
        { props: "", label: "F<xx" },
        { props: "", label: "xx<=F<=xx" },
      ],
    };
  },

  created() {
    if (this.$route.query.id) {
      sessionStorage.setItem("labelId", this.$route.query.id);
    }
    this.defaultCheckedKeys = [Number(sessionStorage.getItem("labelId")) + "t"];
    this.getDetail(sessionStorage.getItem("labelId"));
    this.getTagGroup();
  },

  mounted() {},

  methods: {
    //获取标签详情
    getDetail(id) {
      this.stackBarOptions.series = [];
      getDetails({
        id: id,
      })
        .then((res) => {
          if (res.code == 200) {
            this.defaultExpandedKeys = [res.data.tagGroupId, Number(id)];
            this.labelDetail = res.data;
            this.$set(
              this.crosswiseBarOptions.yAxis,
              "data",
              res.data.valueYLayout
            );
            this.$set(this.crosswiseBarOptions.series, 0, {
              name: "人数",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: res.data.valueXLayout,
            });
            this.$set(this.stackBarOptions.xAxis, "data", res.data.dateYLayout);
            this.$set(
              this.stackBarOptions.series,
              "data",
              res.data.dateYLayout
            );
            this.$set(
              this.stackBarOptions.legend,
              "data",
              res.data.valueYLayout
            );
            res.data.dateXLayout.forEach((item, index) => {
              this.stackBarOptions.series.push({
                name: res.data.valueYLayout[index],
                type: "bar",
                stack: "total",
                label: {
                  show: true,
                },
                emphasis: {
                  focus: "series",
                },
                data: item,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取标签分组
    getTagGroup() {
      getGroupTree({ associatedData: "true", associatedData: true }).then(
        (res) => {
          if (res.code == 200) {
            this.treeData = [];
            if (res.data) {
              this.treeData = res.data;
              this.filterPeople(this.treeData);
              // for (let i = 0; i < this.treeData.length; i++) {
              //   this.treeData[i].children = this.treeData[i].children.concat(
              //     this.treeData[i].tags
              //   );
              //   this.filterPeople(this.treeData[i]);
              //   for (let y = 0; y < this.treeData[i].children.length; y++) {
              //     if (this.treeData[i].children[y].tagGroupId) {
              //       this.treeData[i].children[y].name =
              //         this.treeData[i].children[y].name + "(标签)";
              //     }
              //   }
              // }
            }
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    filterPeople(data) {
      data.forEach((item) => {
        if (item.children && item.children.length) {
          return this.filterPeople(item.children);
        } else {
          item.children = [];
          if (item.tags && item.tags.length) {
            item.tags.forEach((item) => {
              item.id = item.id + "t";
            });
            console.log(item.tags);
          }
          item.children = item.children.concat(item.tags);
          item.children.forEach((items) => {
            if (items.tagGroupId) {
              items.name = items.name + "(标签)";
            }
          });
        }
      });
    },
    handleNodeClick(data, node, Dom) {
      if (data.tagGroupId) {
        this.getDetail(data.id.slice(0, data.id.length - 1));
      }
    },
    backRouter() {
      this.$router.back(-1);
    },
    // replaceTags(arr,str){
    //   for(let i = 0;i < arr.length; i++){
    //     arr[i].children = arr[i].children.concat(arr[i].tags)
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background-color: #0ccd6c;
    margin-right: 6px;
  }

  .waring {
    background-color: #fe0c0c;
  }
}

h6 {
  margin: 0;
  padding: 0;
}

#main {
  background: #f2f2f2;

  .backRouter {
    display: flex;
    align-items: center;
    cursor: pointer;

    .el-icon-arrow-left {
      color: #73a0fa;
    }

    span {
      color: #73a0fa;
      font-size: 12px;
      margin-right: 8px;
    }
  }

  ::v-deep .el-aside {
    margin-bottom: 0;
    border-radius: 5px;
    background: #ffffff;
  }

  ::v-deep .el-header {
    margin-left: 10px;
    height: auto !important;
    border-radius: 5px;
    background: #ffffff;

    .groupTags {
    }
  }

  ::v-deep .el-main {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: #ffffff;
  }

  ::v-deep .el-footer {
    margin-left: 10px;
    margin-bottom: 40px;
    height: auto !important;
    border-radius: 5px;
    background: #ffffff;

    .echartBox {
      .crosswiseBar,
      .stackBar {
        .barVueBox {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
  .form_box {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }

  ::v-deep .is-checked {
    background: #ededed;
  }
}
</style>
