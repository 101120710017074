<!-- 用户解绑 -->
<template>
  <div class="main" id="main">
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-width="100px"
    >
      <el-form-item label="申请类型" prop="applyType">
        <el-radio-group v-model="formData.applyType">
          <el-radio label="解绑手机号"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="申请内容" prop="content">
        <div>{{ formData.content }}</div>
      </el-form-item>
      <el-form-item label="选择项目" prop="projectId">
        <el-select
          v-model="formData.projectId"
          placeholder="请选择项目"
          style="width: 200px"
          @change="getApplyUser"
          filterable
          clearable
        >
          <el-option
            v-for="item in dicts.study_project_id"
            :label="item.label"
            :value="item.label"
            :key="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="相关证据" prop="evidencelist">
        <!-- <el-upload
          class="avatar-uploader"
          :limit="9"
          :action="$store.getters.uploadImgUrl"
          :data="{ busiName: 5 }"
          :show-file-list="false"
          :auto-upload="true"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-change="fileOnChange"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div slot="tip">
            请上传相关证据以通过申请，支持JPG\PNG格式图片，大小5M以内
          </div>
        </el-upload> -->
        <el-upload
          multiple
          :action="$store.getters.uploadImgUrl"
          :data="{ busiName: 5 }"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-change="fileOnChange"
          :file-list="evidencelist"
        >
          <i class="el-icon-plus"></i>
          <div slot="tip">
            请上传相关证据以通过申请，支持JPG\PNG格式图片，大小5M以内
          </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="申请原因" prop="applyReason">
        <el-input
          v-model="formData.applyReason"
          type="textarea"
          placeholder="请输入内容"
          maxlength="500"
          :rows="5"
          show-word-limit
          style="max-width: 1200px"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="流程" required>
        <div class="flow_box">
          <div
            v-for="(itemList, index) in flowPathObj"
            :key="index"
            class="flowPath"
            :class="index"
          >
            <span>{{ flowKeyMap[index] }}</span>
            <ul>
              <li v-for="(items, index) in itemList" :key="index">
                <span class="flow_head">{{
                  items.conditionInfo ? items.conditionInfo[0] : ""
                }}</span>
                <span>{{ items.conditionInfo }}</span>
              </li>
            </ul>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="" @click="cancel">取消</el-button>
        <el-button type="primary" @click="sendData">提交申请</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  unBindPhone,
  reSubmit,
  getApplyUser,
  getApplyById,
} from "@/api/user/index";
import { queryDicDetailType } from "@/api/upload/upload";
export default {
  name: "Unbind",
  components: {},
  data() {
    return {
      publicUrl: this.$store.state.public.publicImgUrl,
      dicts: {},
      dialogImageUrl: undefined, // 图片预览 地址
      dialogVisible: undefined, // 图片预览 弹框是否可见
      formData: {
        // userId: null, // 待解绑的用户id
        applyType: "解绑手机号", // 申请类型
        content: undefined, // 申请内容
        projectId: undefined, // 选择项目
        evidencelist: undefined, // 相关证据
        applyReason: undefined, // 申请原因
      },
      evidencelist: [], // 初始回显数据
      formRules: {
        applyType: [
          { required: true, message: "请选择申请类型", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入申请内容", trigger: "blur" },
        ],
        projectId: [
          { required: true, message: "请选择项目", trigger: "change" },
        ],
        evidencelist: [
          { required: true, message: "请上传相关证据", trigger: "blur" },
        ],
        applyReason: [
          { required: true, message: "请输入申请原因", trigger: "blur" },
        ],
      },
      // prevData: {
      //   //上一个页面传过来的数据。
      // },
      flowPathObj: undefined,
      flowKeyMap: {
        pl: "组长审批",
        pm: "主管审批",
        digital: "数字化中心审批",
      },
    };
  },

  mounted() {
    // console.log(this.$route.params);
    // if (this.$route.params) {
    //   this.prevData = this.$route.params;
    //   localStorage.setItem("prevData", this.$route.params);
    // } else {
    //   this.prevData = localStorage.getItem("prevData");
    // }
    const { userId = null, id = null } = this.$route.query;
    this.formData.userId = userId;
    this.formData.id = id;
    if (this.$route.query.phone) {
      this.formData.content = `解绑${this.$route.query.phone}手机号`;
    }
    this.queryDicDetailType();
    if (this.formData.id) {
      this.getApplyById();
    }
  },

  methods: {
    // 获取字典表
    queryDicDetailType() {
      const dictName = "study_project_id"; // 申请记录 选择项目 处理状态 字典表
      queryDicDetailType({ dictName }).then((res) => {
        if (res.code === 200) {
          this.dicts = res.data;
        }
      });
    },
    // 获取申请数据
    getApplyById() {
      getApplyById({ id: this.formData.id }).then((res) => {
        if (res.code == 200) {
          this.getApplyUser(res.data.projectId);
          for (let i in this.formData) {
            this.formData[i] = res.data[i];
          }
          this.formData.evidencelist = this.formData.evidencelist
            .split(",")
            .map((item) => {
              return {
                filePath: item,
                url: this.publicUrl + item,
              };
            });
          this.evidencelist = [...this.formData.evidencelist];
          return;
        }
        this.$message.error(res.message);
      });
    },
    //图片上传成功
    handleAvatarSuccess(res) {
      console.log("handleAvatarSuccess=", res);
      if (!this.formData.evidencelist) this.formData.evidencelist = [];
      this.formData.evidencelist.push({
        filePath: res.data.filePath,
        url: res.data.url,
      });
    },
    //图片上传前
    beforeAvatarUpload(file) {
      let testType;
      testType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const testSize = file.size / 1024 / 1024 < 5;

      if (!testType) {
        this.$message.error("上传文件格式错误!");
      }
      if (!testSize) {
        this.$message.error("上传文件大小不能超过 " + 5 + "MB!");
      }
      return testType && testSize;
    },
    fileOnChange(file, fileList) {
      console.log("fileOnChange=", file);
      console.log(fileList);
      if (fileList && fileList.length > 0) {
        this.$refs.form.validateField("evidencelist");
      }
    },
    handleRemove(file, fileList) {
      console.log("handleRemove", file, fileList);
      this.formData.evidencelist = this.formData.evidencelist.filter(
        (item) => item.filePath != file.filePath
      );
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 获取 相应审批人列表
    getApplyUser(val) {
      getApplyUser({ projectId: val }).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          this.flowPathObj = res.data;
          return;
        }
        this.$message.error(res.message);
      });
    },
    //提交申请
    sendData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // console.log(this.formData);
          const { userId = null } = this.$route.query;
          // this.formData.userId = userId;
          const formData = { ...this.formData, userId };
          formData.evidencelist = formData.evidencelist.map(
            (item) => item.filePath
          );
          // if (!formData.id) {
          delete formData.id;
          unBindPhone(formData).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.$closeSelectedTag(this.$route);
              return;
            }
            this.$message.error(res.message);
          });
          // } else { // 重新提交
          //   reSubmit(formData).then(res => {
          //     if (res.code == 200) {
          //       this.$message.success(res.message)
          //       this.$closeSelectedTag(this.$route);
          //       return
          //     }
          //     this.$message.error(res.message)
          //   })
          // }
        }
      });
    },
    //取消申请
    cancel() {
      this.$closeSelectedTag(this.$route);
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  .flow_box {
    margin-top: 20px;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
  }
  .flowPath {
    position: relative;
    top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    clear: both;
    padding-bottom: 15px;
    padding-left: 15px;
    border-left: 1px solid #c9c9c9;
    &.pl {
      order: 1;
    }
    &.pm {
      order: 2;
    }
    &.digital {
      order: 3;
      border: none;
    }
    span {
      line-height: 1;
    }
    ul {
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        float: left;
        margin-left: 10px;
        .flow_head {
          display: block;
          width: 48px;
          height: 48px;
          line-height: 48px;
          color: #fff;
          font-size: 20px;
          font-weight: normal;
          text-align: center;
          background-color: #1890ff;
          border-radius: 50%;
          overflow: hidden;
          user-select: none;
        }
        span {
          line-height: 2;
          font-size: 14px;
          color: #333;
          font-weight: 600;
        }
      }
    }
  }
  .flowPath::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #333;
  }
}
</style>
