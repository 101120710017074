<template>
  <el-dialog
    title="选择课程"
    :visible.sync="isshowCourse"
    width="60%"
    top="8vh"
    :before-close="closedCourseDialong"
    :close-on-click-modal="false"
  >
    <div class="dialogMain">
      <div class="left">
        <el-form :inline="true" class="demo-form-inline">
          <!-- <el-form-item>
            <el-select
              v-model="courseParams.courseStatus"
              clearable
              placeholder="课程对应状态"
            >
              <el-option label="全部" value></el-option>
              <el-option label="未对应" :value="1"></el-option>
              <el-option label="已对应" :value="2"></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item>
            <el-input
              v-model="courseParams[selectVal]"
              clearable
              placeholder="请输入"
            >
              <el-select
                slot="prepend"
                v-model="selectVal"
                placeholder="请选择"
                @change="
                  courseParams.resourceId = '';
                  courseParams.title = '';
                "
                style="width: 150px"
              >
                <el-option label="小鹅通课程Id" value="resourceId"></el-option>
                <el-option label="小鹅通课程名称" value="title"></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-button
            type="primary"
            @click="
              courseParams.page = 1;
              getCurriculumList();
            "
            icon="el-icon-search"
            >查询</el-button
          >
        </el-form>
        <el-s-table
          ref="courseRefs"
          :columns="columnsCourseSel"
          :data="courseList"
          :header-row-class-name="!multiple ? 'noneCheck' : ''"
          row-key="xiaoeId"
          height="500px"
          border
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column prop="curricumJoinNum" label="关联课程"/> -->
        </el-s-table>
        <!-- 分页 -->
        <div class="pagebox">
          <el-pagination
            @size-change="handleSizeChangeCourse"
            @current-change="handleCurrentChangeCourse"
            :current-page="courseParams.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="courseParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCourse"
            background
          ></el-pagination>
        </div>
      </div>
      <div class="right">
        <div>
          <span>已选择</span>
          <el-button
            type="text"
            @click="
              selectData = [];
              courseTags = [];
              $refs.courseRefs.clearSelection();
            "
            >全部取消</el-button
          >
        </div>
        <div class="tagMain">
          <el-tag
            closable
            v-for="item in courseTags"
            :key="item.xiaoeId"
            @close="tagClose(courseTags, item.xiaoeId, 'xiaoeId')"
            >{{ item.title }}</el-tag
          >
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closedCourseDialong">取 消</el-button>
      <el-button type="primary" @click="submitCourse">确认</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getCurriculumList } from "@/api/hxl/public";
let _this = null;
export default {
  props: ["courseVal", "multiple"],
  name: "",
  data() {
    return {
      isshowCourse: false,
      courseParams: {
        page: 1,
        pageSize: 10,
        title: "",
        courseStatus: "",
        resourceId: "",
      },
      selectVal: "resourceId",
      columnsCourseSel: [
        {
          type: "selection",
          "reserve-selection": true,
          selectable: (row, index) => {
            if (!_this.multiple && _this.selectData.length) {
              return false;
            } else {
              return true;
            }
          },
        },
        {
          prop: "xiaoeId",
          label: "课程ID",
        },
        {
          prop: "title",
          "min-width": "240px",
          label: "课程标题",
        },
      ],
      courseList: [], //课程列表
      selectData: [],
      totalCourse: 0,
      not_foundItem: [],
      courseTags: [],
    };
  },
  created() {
    _this = this;
  },
  methods: {
    closedCourseDialong() {
      this.$refs.courseRefs.clearSelection();
      this.courseTags = [];
      this.isshowCourse = false;
      this.courseParams = {
        page: 1,
        pageSize: 10,
        title: "",
        courseStatus: "",
        resourceId: "",
      };
    },
    //获取课程列表
    getCurriculumList() {
      getCurriculumList(this.courseParams).then((res) => {
        if (res.code == 200) {
          this.courseList = res.data.data ? res.data.data : [];
          this.$nextTick(() => {
            // Rewrite the code to avoid errors and ensure proper rendering
            // console.log(this.courseVal);
            // if (!this.courseVal.length || !this.selectData.length) {
            //   this.selectData = [];
            //   this.courseTags = [];
            //   this.$refs.courseRefs.clearSelection();
            // }
            this.courseVal.forEach((item) => {
              let foundItem = this.courseList.find((items) => {
                return (item.xiaoeId ? item.xiaoeId : item) == items.xiaoeId;
              });
              if (foundItem) {
                this.$refs.courseRefs.toggleRowSelection(foundItem, true);
              }
            });
            this.not_foundItem = this.courseVal.filter((item) => {
              return !this.courseList.some((listItem) => {
                return (item.xiaoeId ? item.xiaoeId : item) == listItem.xiaoeId;
              });
            });
            if (this.courseVal.length > this.courseTags.length) {
              this.getResidueList();
            }
          });
          this.totalCourse = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取剩余未选中课程数据
    getResidueList() {
      this.not_foundItem.forEach((item) => {
        getCurriculumList({
          page: 1,
          pageSize: 10,
          xiaoeId: item,
          courseStatus: "",
        }).then((res) => {
          this.courseTags.push(res.data.data[0]);
        });
      });
    },
    //新增
    handleSelectionChange(val) {
      this.selectData = val;
      if (this.selectData.length > this.courseTags.length) {
        this.selectData.forEach((item) => {
          this.courseTags.push(item);
        });
      } else {
        this.courseTags = this.courseTags.filter((tag) => {
          return this.selectData.some((item) => {
            return JSON.stringify(item) === JSON.stringify(tag);
          });
        });
      }
      this.courseTags = this.removeDuplicates(this.courseTags, "xiaoeId");
    },
    handleSizeChangeCourse(val) {
      this.courseParams.pageSize = val;
      this.courseParams.page = 1;
      this.getCurriculumList();
    },
    handleCurrentChangeCourse(val) {
      this.courseParams.page = val;
      this.getCurriculumList();
    },
    submitCourse() {
      this.courseIds = [];
      for (let i = 0; i < this.selectData.length; i++) {
        let params = {};
        params.id = this.selectData[i].id;
        params.xiaoeId = this.selectData[i].xiaoeId;
        this.courseIds.push(params);
      }
      this.$emit("courseData", this.courseIds);
      this.closedCourseDialong();
    },
    removeDuplicates(array, field) {
      return array.filter((item, index, self) => {
        return index === self.findIndex((t) => t[field] === item[field]);
      });
    },
    tagClose(arr, id, key = "id") {
      let index = arr.findIndex((item) => item[key] === id);
      if (index !== -1) {
        arr.splice(index, 1);
        if (this.selectData[index]) {
          this.$refs.courseRefs.toggleRowSelection(this.selectData[index]);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogMain {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .right {
    margin-top: 18px;
    width: 28%;
    .tagMain {
      padding-left: 5px;
      height: 500px;
      overflow-y: scroll;
      border: 1px solid #dfe6ec;
      .el-tag {
        margin-right: 5px;
      }
    }
  }
  ::v-deep .el-table .noneCheck th.el-table__cell > .cell {
    .el-checkbox {
      display: none;
    }
  }
}
</style>
