var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("div", { staticClass: "tab_bar" }, [_vm._v("基础信息")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleFormRef",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.formDataRules,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "base_info" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签名称", prop: "tagName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "请输入标签名称,30字符以内",
                      maxlength: "30",
                    },
                    model: {
                      value: _vm.ruleForm.tagName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "tagName", $$v)
                      },
                      expression: "ruleForm.tagName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签分组", prop: "tagGroupId" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.groupList,
                      props: { emitPath: false, label: "name", value: "id" },
                      "collapse-tags": "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.ruleForm.tagGroupId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "tagGroupId", $$v)
                      },
                      expression: "ruleForm.tagGroupId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tab_bar" }, [_vm._v("使用规则")]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "统计规则" } }, [
            _c("div", { staticClass: "tagContent" }, [
              _c("div", { staticClass: "tagBox" }, [
                _c("div", { staticClass: "aggregationsBox" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dynamicRule.dynamicConditions.length > 1,
                          expression:
                            "dynamicRule.dynamicConditions.length > 1",
                        },
                      ],
                      staticClass: "divider",
                    },
                    [_c("span", [_vm._v("且")])]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ruleContent" }, [
                    _c("div", { staticClass: "aggregationsMain" }, [
                      _c(
                        "div",
                        { staticClass: "leftBox" },
                        [
                          _c("el-date-picker", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.dynamicRule.aggregations[0].inputs,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dynamicRule.aggregations[0],
                                  "inputs",
                                  $$v
                                )
                              },
                              expression: "dynamicRule.aggregations[0].inputs",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("统计 ")]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "100px",
                                "margin-right": "5px",
                              },
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.aggregations1Change },
                              model: {
                                value: _vm.selectAggregations1,
                                callback: function ($$v) {
                                  _vm.selectAggregations1 = $$v
                                },
                                expression: "selectAggregations1",
                              },
                            },
                            _vm._l(_vm.aggregationsRuleConfig, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.key },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "rightBox" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.addFather },
                            },
                            [_vm._v("新增条件")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "contentRight" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.dynamicRule.dynamicConditions.length > 1,
                              expression:
                                "dynamicRule.dynamicConditions.length > 1",
                            },
                          ],
                          staticClass: "divider",
                        },
                        [
                          _c(
                            "span",
                            { on: { click: _vm.fatherConnectorChange } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.dynamicRule.dynamicConditions[0]
                                    .connector == "AND"
                                    ? "且"
                                    : "或"
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        _vm._l(
                          _vm.dynamicRule.dynamicConditions,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "fatherBox" },
                              [
                                _c("div", { staticClass: "conditionNumBox" }, [
                                  _vm._v("条件" + _vm._s(index + 1)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticStyle: { width: "100%" } }, [
                                  _c("div", { staticClass: "fatherContent" }, [
                                    _c(
                                      "div",
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: {
                                            type: "daterange",
                                            "range-separator": "至",
                                            "start-placeholder": "开始日期",
                                            "end-placeholder": "结束日期",
                                            format: "yyyy-MM-dd",
                                            "value-format": "yyyy-MM-dd",
                                            "picker-options": _vm.pickerOptions,
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.firstRulesInputsChange(
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.firstRules[0].inputs,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.firstRules[0],
                                                "inputs",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.firstRules[0].inputs",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              width: "100px",
                                              "margin-right": "5px",
                                            },
                                            attrs: { placeholder: "请选择" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.firstRules1OperatorChange(
                                                  item,
                                                  item.firstRules[1].operator,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                item.firstRules[1].operator,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item.firstRules[1],
                                                  "operator",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.firstRules[1].operator",
                                            },
                                          },
                                          _vm._l(
                                            _vm.actionOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "210px" },
                                            attrs: { placeholder: "请选择" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.actionTypeChange(
                                                  index,
                                                  _vm.firstRulesInputs[index]
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.firstRulesInputs[index],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.firstRulesInputs,
                                                  index,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "firstRulesInputs[index]",
                                            },
                                          },
                                          _vm._l(
                                            _vm.actionTypeOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "rightBox" },
                                      [
                                        item.firstRules[1].operator !=
                                        "NOT_EQUAL"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addChild(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("新增细分条件")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.dynamicRule.dynamicConditions
                                          .length > 1
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delRules(
                                                      "father",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  item.simpleTagRuleConditionDefinitions &&
                                  item.simpleTagRuleConditionDefinitions.length
                                    ? _c("div", { staticClass: "childBox" }, [
                                        _c(
                                          "div",
                                          { staticClass: "childHint" },
                                          [_vm._v("且满足")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "divider",
                                            class: {
                                              divider_non:
                                                item.simpleTagRuleConditionDefinitions &&
                                                item
                                                  .simpleTagRuleConditionDefinitions
                                                  .length <= 1,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.childSimpleChange(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item
                                                      .simpleTagRuleConditionDefinitions[0]
                                                      .connector == "AND"
                                                      ? "且"
                                                      : "或"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "100%" } },
                                          _vm._l(
                                            item.simpleTagRuleConditionDefinitions,
                                            function (items, i) {
                                              return _c(
                                                "div",
                                                {
                                                  key: i,
                                                  staticClass: "tagBox",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "childContentRight",
                                                    },
                                                    [
                                                      _c("tagManageVue", {
                                                        key: _vm.tagManageUpload,
                                                        attrs: {
                                                          rulesConfig:
                                                            _vm.rulesConfig[
                                                              index
                                                            ],
                                                          tagManageData: {
                                                            data: items,
                                                            childIndex: i,
                                                            fatherIndex: index,
                                                            type: "child",
                                                            curriculumCodeList:
                                                              _vm.curriculumCodeList,
                                                            goodsList:
                                                              _vm.goodsList,
                                                            curriculumProjectList:
                                                              _vm.projectList,
                                                            dictData:
                                                              _vm.dictData,
                                                          },
                                                          father: false,
                                                          changeDataFuns:
                                                            _vm.changeData,
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "rightBox",
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delRules(
                                                                      "child",
                                                                      index,
                                                                      i
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("删除")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.firstRules[1].operator != "NOT_EQUAL"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "fatherContent",
                                          staticStyle: {
                                            "justify-content": "flex-start",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.aggDataKeyChange(
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  item
                                                    .aggregationTagRuleConditionDefinitions[0]
                                                    .dataKey,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item
                                                      .aggregationTagRuleConditionDefinitions[0],
                                                    "dataKey",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                            item.aggregationTagRuleConditionDefinitions[0]\n                              .dataKey\n                          ",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dataKeyOptions[index],
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.key,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100px" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              model: {
                                                value:
                                                  item
                                                    .aggregationTagRuleConditionDefinitions[0]
                                                    .operator,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item
                                                      .aggregationTagRuleConditionDefinitions[0],
                                                    "operator",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                            item.aggregationTagRuleConditionDefinitions[0]\n                              .operator\n                          ",
                                              },
                                            },
                                            _vm._l(
                                              _vm.operatorOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    item
                                                      .aggregationTagRuleConditionDefinitions[0]
                                                      .inputs[0],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item
                                                        .aggregationTagRuleConditionDefinitions[0]
                                                        .inputs,
                                                      0,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                              item.aggregationTagRuleConditionDefinitions[0]\n                                .inputs[0]\n                            ",
                                                },
                                              }),
                                              _vm._v(" "),
                                              item
                                                .aggregationTagRuleConditionDefinitions[0]
                                                .operator == "BETWEEN"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                    },
                                                    [_vm._v("--")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item
                                                .aggregationTagRuleConditionDefinitions[0]
                                                .operator == "BETWEEN"
                                                ? _c("el-input", {
                                                    staticStyle: {
                                                      width: "200px",
                                                    },
                                                    attrs: {
                                                      placeholder: "请输入",
                                                    },
                                                    model: {
                                                      value:
                                                        item
                                                          .aggregationTagRuleConditionDefinitions[0]
                                                          .inputs[1],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item
                                                            .aggregationTagRuleConditionDefinitions[0]
                                                            .inputs,
                                                          1,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "\n                              item.aggregationTagRuleConditionDefinitions[0]\n                                .inputs[1]\n                            ",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "标签值", prop: "valueLabels" } },
            [
              _c("el-s-table", {
                staticStyle: { width: "901px" },
                attrs: {
                  columns: _vm.columns,
                  data: _vm.ruleForm.valueLabels,
                  border: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "valueName",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          attrs: { placeholder: "请输入标签值" },
                          model: {
                            value: row.valueName,
                            callback: function ($$v) {
                              _vm.$set(row, "valueName", $$v)
                            },
                            expression: "row.valueName",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "intervalType",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "选择区间类型" },
                            on: {
                              change: function ($event) {
                                return _vm.intervalTypeChange(row, $index)
                              },
                              "visible-change": function ($event) {
                                return _vm.visibleChange(row, $index, $event)
                              },
                            },
                            model: {
                              value: row.intervalType,
                              callback: function ($$v) {
                                _vm.$set(row, "intervalType", $$v)
                              },
                              expression: "row.intervalType",
                            },
                          },
                          _vm._l(_vm.intervalTypeList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: {
                                disabled: item.disabled,
                                label: item.label,
                                value: item.value,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "interval",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          staticStyle: { width: "100px" },
                          attrs: {
                            placeholder: "请输入数值",
                            disabled: row.intervalType == "INF_TO_CLOSED_RIGHT",
                          },
                          on: {
                            input: function ($event) {
                              row.left = row.left.replace(_vm.valueReg, "")
                            },
                            blur: function ($event) {
                              return _vm.$refs.ruleFormRef.validateField(
                                "valueLabels"
                              )
                            },
                          },
                          model: {
                            value: row.left,
                            callback: function ($$v) {
                              _vm.$set(row, "left", $$v)
                            },
                            expression: "row.left",
                          },
                        }),
                        _vm._v("\n          ，\n          "),
                        _c("el-input", {
                          staticStyle: { width: "100px" },
                          attrs: {
                            placeholder: "请输入数值",
                            disabled: row.intervalType == "CLOSED_RIGHT_TO_INF",
                          },
                          on: {
                            input: function ($event) {
                              row.right = row.right.replace(_vm.valueReg, "")
                            },
                            blur: function ($event) {
                              return _vm.$refs.ruleFormRef.validateField(
                                "valueLabels"
                              )
                            },
                          },
                          model: {
                            value: row.right,
                            callback: function ($$v) {
                              _vm.$set(row, "right", $$v)
                            },
                            expression: "row.right",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delTableData(row, $index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.addTableData } },
                [_vm._v("新增区间")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom_btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.backRouter } }, [_vm._v("取消")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCreateWay", {
        ref: "selectCreateWayRef",
        on: { selectData: _vm.selectData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }