import request from "@/utils/request";

// 课程证书列表
export function getCourseList(data) {
  return request({
    url: "/curriculum/pc/credential/getCourseList",
    method: "get",
    params: data,
  });
}
// 考试证书列表
export function getExamList(data) {
  return request({
    url: "/curriculum/pc/credential/getExamList",
    method: "get",
    params: data,
  });
}
// 打卡证书列表
export function getPunchList(data) {
  return request({
    url: "/curriculum/pc/credential/getPunchList",
    method: "get",
    params: data,
  });
}

// 诊疗卡证书列表
export function getMedicalCardList(data) {
  return request({
    url: "/curriculum/pc/credential/getMedicalCardList",
    method: "get",
    params: data,
  });
}
/**
 * 获取详情
 * @param {*} data
 * @returns
 */
export function getCredentialInfo(data) {
  return request({
    url: "/curriculum/pc/credential/getCredentialInfo",
    method: "get",
    params: data,
  });
}

/**
 * 新增或编辑证书
 * @param {*} data
 * 证书类型 1考试证书 2打卡证书 3课程证书 0诊疗卡
 * @returns
 */
export function generationCredential(data) {
  return request({
    url: "/curriculum/pc/credential/generationCredential",
    method: "post",
    data,
  });
}

/**
 * 证书发放记录
 * @param {*} data
 * 证书类型 1考试证书 2打卡证书 3课程证书 0诊疗卡
 * @returns
 */
export function getCertificateRecordList(data) {
  return request({
    url: "/curriculum/pc/credential/getCertificateRecordList",
    method: "get",
    params: data,
  });
}
// 诊疗卡发放记录
export function getMedicalCardRecord(data) {
  return request({
    url: "/curriculum/pc/credential/medical/getMedicalCardList",
    method: "get",
    params: data,
  });
}

/**
 * 证书删除
 * @param {*} data
 * 证书类型 1考试证书 2打卡证书 3课程证书 0诊疗卡
 * @returns
 */
export function deleteCredentiaList(data) {
  return request({
    url: "/curriculum/pc/credential/deleteCredentiaList",
    method: "post",
    data,
  });
}
/**
 * 课程证书手动批量发放
 * @param {
 * list
 * } data
 * @returns
 */
export function updateCertificateRecord(data) {
  return request({
    url: "/curriculum/pc/credential/updateCertificateRecord",
    method: "post",
    data,
  });
}
/*手动发放证书*/
export function courseCertificateBatchSend(data) {
  return request({
    url: "/curriculum/pc/credential/courseCertificateBatchSend",
    method: "post",
    data,
  });
}
/*诊疗卡一键补发*/
export function sendMedicalCardByCurrCode(data) {
  return request({
    url: "/curriculum/pc/credential/medical/sendMedicalCardByCurrCode",
    method: "post",
    data,
  });
}
/*诊疗卡 兑换 单个用户 批量兑换*/
export function exchangeMedicalCardById(data) {
  return request({
    url: "/curriculum/pc/credential/medical/exchangeMedicalCardByIds",
    method: "post",
    data,
  });
}
/*诊疗卡 兑换 一键兑换 所有用户*/
export function convertMedicalCardAll(data) {
  return request({
    url: "/curriculum/pc/credential/medical/convertMedicalCardAll",
    method: "post",
    data,
  });
}
/*诊疗卡 删除 一键删除 诊疗卡发放记录*/
export function deleteMedicalCardByCurrCode(data) {
  return request({
    url: "/curriculum/pc/credential/medical/deleteMedicalCardByCurrCode",
    method: "post",
    data,
  });
}
/*诊疗卡 删除 用户诊疗卡发放记录*/
export function deleteMedicalCardByUser(data) {
  return request({
    url: "/curriculum/pc/credential/medical/deleteMedicalCardByUser",
    method: "post",
    data,
  });
}
